import { Box } from "@mui/material";
import { withEllipsis } from "@styles/common-styles";

interface Props {
  /** Text to display */
  text: string;

  /** Whether the text should not be translated by localize */
  shouldNotTranslate?: boolean;
}

/** Single info item of the subscription details */
export function SubscriptionInfoItem({ text, shouldNotTranslate }: Props): JSX.Element {
  return (
    <Box
      component={shouldNotTranslate ? "var" : "div"}
      sx={{
        fontSize: "16px",
        ...withEllipsis,
      }}
    >
      {text}
    </Box>
  );
}