import { FaroTextButton } from "@components/common/faro-text-button";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName, updateOne } from "@store/table/table-slice";
import { useEffect, useState } from "react";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import { useCoreApiClient } from "@api/use-core-api-client";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { useDeleteTeamsBulkDialogContent } from "@components/table/teams/bulk-actions/bulk-delete-teams-helper";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";
import { deleteTeam } from "@store/teams/teams-slice-thunk";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** Show delete teams button and dispatch action where the bulk dialog will act accordingly */
export function BulkDeleteTeams({
  companyId,
}: BaseCompanyIdProps): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);

  const bulkActionName = useAppSelector(bulkActionNameSelector);
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );
  const selectedEntities = useAppSelector(selectedEntitiesSelector("teams"));
  const { canDeleteTeams } = useHasUserValidRoleCompanyLevel();

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  const { bulkDialogContent, successDialogContent } =
    useDeleteTeamsBulkDialogContent();

  // Closes the dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  function onDeleteTeamClick(): void {
    dispatch(setBulkActionName("removeTeam"));

    for (const entity of selectedEntities) {
      if (!canDeleteTeams) {
        dispatch(
          updateOne({
            id: entity.id,
            changes: {
              status: "not-allowed",
              message: `You are not allowed to delete this ${TEAM_DISPLAY_NAME} from workspace`,
            },
          })
        );
      }
    }

    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextButton
        onClick={onDeleteTeamClick}
        tooltipText={`Delete ${TEAM_DISPLAY_NAME} from workspace`}
        sx={{ fontWeight: 600 }}
      >
        Delete
      </FaroTextButton>

      {/* Checking for bulkActionName to make sure the dialog of another bulk action is not opened by mistake */}
      {bulkActionName === "removeTeam" && shouldShowSuccessDialog && (
        <BulkSuccessDialog dialogContent={successDialogContent} />
      )}

      {bulkActionName === "removeTeam" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkDeleteTeam}
          bulkActionCallback={(team: SphereDashboardAPITypes.ITeam) =>
            dispatch(
              deleteTeam({
                coreApiClient,
                companyId,
                teamId: team.id,
              })
            ).unwrap()
          }
          selectedEntities={selectedEntities}
          uniqueIdKey="id"
          tableDataType="teams"
        />
      )}
    </>
  );
}
