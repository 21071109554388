import { useCallback } from "react";
import { ProjectApi } from "@api/project-api/project-api";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import {
  ElsScanFileUploadTaskContext,
  UploadedFile,
  UploadFailedFile,
} from "@custom-types/file-upload-types";
import { SdbProject } from "@custom-types/project-types";
import { useCancelRevision } from "@hooks/data-management/use-cancel-revision";
import { useToast } from "@hooks/use-toast";
import { FailedUploadsToastContent } from "@pages/project-details/project-data-management/import-data/failed-uploads-toast-content";
import { addScansToRevisionAndMergeToMainHelper } from "@pages/project-details/project-data-management/import-data/import-data-utils";

type ReturnFunction = (
  successfulUploads: UploadedFile[],
  failedUploads: UploadFailedFile[],
  projectApiClient: ProjectApi,
  context: ElsScanFileUploadTaskContext
) => Promise<void>;

/**
 * Handles the logic after the upload of ELS scan succeeded:
 * - Adds (creates) successful uploads to the specified cluster of the project revision.
 * - Updates revision to "registered" status and merges it to the main revision.
 * - If it fails it will then set the revision as canceled.
 */
export function useAddScansToRevisionAndMergeToMain(project?: SdbProject | null): ReturnFunction {
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const cancelRevision = useCancelRevision();

  return useCallback(
    async (
      successfulUploads: UploadedFile[],
      failedUploads: UploadFailedFile[],
      projectApiClient: ProjectApi,
      context: ElsScanFileUploadTaskContext
    ): Promise<void> => {
      try {
        await addScansToRevisionAndMergeToMainHelper(successfulUploads, projectApiClient, context);

        // Show a warning toast with the list of files that failed to upload
        if (failedUploads.length) {
          showToast({
            message: "Data import was partially successful",
            type: "warning",
            shouldAutoHide: false,
            description: (
              <FailedUploadsToastContent
                failedUploads={failedUploads}
                projectName={project?.name}
              />
            ),
          });
        }
      } catch (error) {
        handleErrorWithToast({
          id: `addScansToRevisionAndMergeToMain-${Date.now().toString()}`,
          title: "Failed to add imported data to project. Please try to import data again.",
          error,
        });

        // Attempt to set the revision as canceled
        await cancelRevision(projectApiClient, context.registrationRevisionId);
      }
    },
    [cancelRevision, handleErrorWithToast, project?.name, showToast]
  );
}
