/**
 * Gets the initials of a team name to be shown in an avatar
 * E.g. "Sphere Developers" -> "SD"
 *
 * @param teamName The name of a team
 * @returns The initials to show inside an avatar.
 */
export function getTeamAvatarInitials(teamName?: string | null): string {
  if (!teamName) {
    return "";
  }
  const teamNameWords = teamName.split(" ");

  // If the team name has two or more words, return the first letter of the first two words.
  if (teamNameWords.length >= 2) {
    return `${teamNameWords[0][0]}${teamNameWords[1][0]}`.toUpperCase();
  }

  // If the team name has one word, return the first two letters of the word
  return teamName.slice(0, 2).toUpperCase();
}
