import { useAppParams } from "@router/router-helper";
import { runtimeConfig } from "@src/runtime-config";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { Logger } from "@stellar/web-core";
import {
  sdbCompaniesSelector,
  selectedSdbCompanySelector,
} from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import {
  HolobuilderCookieManager,
  AMPLITUDE_COOKIE,
} from "@utils/cookie-manager/cookie-manager-utils";
import { LocalStorageUtils } from "@utils/local-storage-utils";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { getPathnameFromUrl, getQueryParamsFromSearch } from "@utils/url-utils";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { currentUserSelector } from "@store/user/user-selector";
import { useSphereXGApi } from "@hooks/use-sphere-xg-api";
import { useLocalize } from "@faro-lotv/foreign-observers";

/**
 * No matter the dependencies it seems that the useEffect to call the init route
 * is called twice, so it stores similar to the isApiInitialized state, whether
 * initialized was already called.
 */
let isApiInitializedLocally = false;

/**
 * Hook to initialize the user in the dashboard 2.0 API
 */
export function useTrackPageLoad(): void {
  const [isApiInitialized, setIsApiInitialized] = useState<boolean>(false);
  const routeLocation = useLocation();
  const { trackAsyncEvent } = useTrackEvent();
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);
  const sdbCompanies = useAppSelector(sdbCompaniesSelector);
  const appParams = useAppParams();
  const currentUser = useAppSelector(currentUserSelector);
  const { postInit } = useSphereXGApi();
  const { currentLanguage } = useLocalize();

  // Call the routes to initialize the user in the dashboard 2.0 API
  // This route will take care of tracking the page load in Amplitude for all users.
  useEffect(() => {
    async function trackPageLoaded(
      currentUser: SphereDashboardAPITypes.ICompanyMemberBase
    ): Promise<void> {
      const urlPathname = getPathnameFromUrl(routeLocation.pathname, appParams);
      const urlQuery = getQueryParamsFromSearch(routeLocation.search);

      const deviceSizes = {
        screenWidth: window.screen.width,
        windowWidth: window.innerWidth,
        screenHeight: window.screen.height,
        windowHeight: window.innerHeight,
        windowOrientation: window.screen.orientation?.type,
      };

      try {
        const appInitTime =
          LocalStorageUtils.getStringItem("app-init-time") ?? undefined;
        const response = await postInit({
          version: runtimeConfig.appVersion,
          application: "Sphere XG Dashboard",
          userId: currentUser.identity,
          userEmail: currentUser.email,
          appInitTime,
          isConsentGiven:
            HolobuilderCookieManager?.isCookieAccepted(AMPLITUDE_COOKIE) ??
            false,
          language: currentLanguage.code,
          workspaces: sdbCompanies.length,
          workspaceRole: selectedSdbCompany?.role.company ?? "Unknown",
          workspaceTags: selectedSdbCompany?.tags ?? [],
          urlPathname,
          urlQuery,
          ...deviceSizes,
        });
        if (response.success) {
          LocalStorageUtils.setStringItem(
            "app-init-time",
            Date.now().toString()
          );
        }
      } catch (error) {
        // Failing to call the init route should not block the user from using the app
        // Use Sentry to log the error
        Logger.logError("Error calling init route", { error: error as Error });
      } finally {
        await trackAsyncEvent({
          name: WorkspaceEvents.pageLoaded,
          props: {
            ...deviceSizes,
            language: currentLanguage.code,
          },
        });
      }
    }

    if (
      selectedSdbCompany &&
      sdbCompanies.length > 0 &&
      currentUser &&
      !isApiInitialized &&
      !isApiInitializedLocally &&
      routeLocation?.pathname &&
      appParams
    ) {
      isApiInitializedLocally = true;
      // Call this upfront to avoid multiple calls while the promise is being resolved
      setIsApiInitialized(true);
      trackPageLoaded(currentUser);
    }
  }, [
    appParams,
    currentLanguage.code,
    currentUser,
    isApiInitialized,
    postInit,
    routeLocation.pathname,
    routeLocation.search,
    sdbCompanies.length,
    selectedSdbCompany,
    trackAsyncEvent,
  ]);
}
