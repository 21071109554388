import { Stack, SvgIcon, Typography } from "@mui/material";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { addTransparency } from "@utils/ui-utils";
import { DEFAULT_TEXT_FONT_SIZE } from "@styles/common-styles";
import HideIcon from "@assets/icons/new/hide_24px.svg?react";
import ShowIcon from "@assets/icons/new/show_24px.svg?react";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { openDetailPanel, closeDetailPanel } from "@store/teams/teams-slice";
import { isDetailOpenedSelector } from "@store/teams/teams-selector";

/**
 * The TeamDetailsButton renders a button allowing users to toggle the visibility of a detail panel.
 */
export function TeamDetailsButton(): JSX.Element {
  const dispatch = useAppDispatch();

  const isDetailOpened = useAppSelector(isDetailOpenedSelector);

  return (
    <Stack
      direction="row"
      onClick={() =>
        dispatch(isDetailOpened ? closeDetailPanel() : openDetailPanel())
      }
      sx={{
        cursor: "pointer",
        p: "6px 10px",
        color: isDetailOpened ? sphereColors.blue500 : sphereColors.gray800,
        "&:hover": {
          backgroundColor: addTransparency({
            color: sphereColors.gray500,
            alpha: EDecimalToHex.twentySix,
          }),
          color: sphereColors.blue500,
        },
      }}
    >
      <SvgIcon
        inheritViewBox
        component={isDetailOpened ? HideIcon : ShowIcon}
        sx={{ width: "18px", height: "18px" }}
      />
      <Typography
        sx={{
          fontSize: DEFAULT_TEXT_FONT_SIZE,
          fontWeight: 700,
          ml: "8px",
        }}
      >
        Details
      </Typography>
    </Stack>
  );
}
