import { RouteParams } from "@router/route-params";
import { useAppParams } from "@router/router-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { useLocation } from "react-router-dom";
import { Analytics, EventProps } from "@faro-lotv/foreign-observers";
import { isAnalyticsModuleInitializedSelector } from "@store/app/app-selector";
import { delay } from "@utils/time-utils";

/** Params for tracking an event */
export interface LogEventParams {
  /** Name of the event to track */
  name: string;

  /** Additional properties for the event */
  props?: EventProps;
}

/** All the ids available in the app */
const allEntityIds: Array<keyof RouteParams> = [
  "companyId",
  "projectId",
  "memberId",
  "groupId",
];

/** Time to wait for the analytics module to initialize, if not happened before trying to track an event */
const initWaitingTimeInMS = 1000;

export interface UseTrackEvent {
  /**
   * Function that tracks an event in the background, returning immediately.
   * In most cases, this should be preferred over `trackAsyncEvent`.
   */
  trackEvent({ name, props }: LogEventParams): void;

  /**
   * Function that tracks an event, returning a Promise that waits for the result.
   * Waiting for the result is currently broken, but fixing it could lead to unexpected behavior.
   */
  trackAsyncEvent({ name, props }: LogEventParams): Promise<void>;
}

/** Default properties for the event */
interface DefaultProps {
  /** Role of the user in the workspace */
  workspaceRole?: string;

  /** Role of the user in the project */
  projectRole?: string;
}

/** Track events with additional default properties that might be changed over the course */
export function useTrackEvent(): UseTrackEvent {
  const { pathname } = useLocation();
  const params = useAppParams();
  const currentUser = useAppSelector(currentUserSelector);
  const selectedProject = useAppSelector(selectedProjectSelector);
  const { projectId } = useAppParams();
  const isAnalyticsModuleInitialized = useAppSelector(
    isAnalyticsModuleInitializedSelector
  );

  /**
   * Gets the props for the event
   */
  function getProps(props?: EventProps): EventProps {
    const defaultProps: DefaultProps = {};
    if (currentUser && currentUser.role) {
      defaultProps.workspaceRole = currentUser.role.toString();
    }

    if (
      projectId &&
      selectedProject &&
      "role" in selectedProject &&
      selectedProject.role
    ) {
      defaultProps.projectRole = selectedProject.role.toString();
    }

    const allProps: EventProps = {
      ...props,
      ...defaultProps,
      path: createPathForTracking(pathname, params),
    };

    return allProps;
  }

  function trackEvent({ name, props }: LogEventParams): void {
    // If the Analytics module is not initialized, we wait for a bit and then send the event
    if (isAnalyticsModuleInitialized) {
      Analytics.track(name, getProps(props));
    } else {
      setTimeout(async () => {
        Analytics.track(name, getProps(props));
      }, initWaitingTimeInMS);
    }
  }

  async function trackAsyncEvent({
    name,
    props,
  }: LogEventParams): Promise<void> {
    // If the Analytics module is not initialized, we wait for a bit and then send the event
    if (!isAnalyticsModuleInitialized) {
      await delay(initWaitingTimeInMS);
    }
    // Bug - does not await the result. Correct would be the following, but it could have unexpected effects:
    // - It seems not documented when/if the Promise can be rejected.
    // - Sending the event could take longer than expected. Is it important enough to wait for it?
    // await Analytics.track(name, getProps(props))?.promise;
    await Analytics.track(name, getProps(props));
  }

  return { trackEvent, trackAsyncEvent };
}

/**
 * Creates the path parameter for tracking by replacing the actual entityIds with its name
 * For example the ID of company changes to "companyId" in the pathname
 * encodeURIComponent is used to replaces the encoded dynamicId with staticId in the pathname.
 * If memberId is foo@bar in the pathname it becomes foo%40bar and need to be encoded to be replaced with memberId
 */
export function createPathForTracking(
  originalPathname: string,
  params: Readonly<Partial<RouteParams>>
): string {
  try {
    let replacedPath = originalPathname;
    for (const [paramName, paramValue] of Object.entries(params)) {
      if (allEntityIds.includes(paramName as keyof RouteParams)) {
        // Replaces the encoded dynamicId with staticId in the pathname.
        // This is used as some characters are encoded in the pathname, e.g. foo@bar -> foo%40bar
        replacedPath = replacedPath.replace(
          encodeURIComponent(paramValue),
          paramName
        );
      }
    }
    return replacedPath;
  } catch (error) {
    // If there is an error in the function, log the error and return an empty string
    // Therefore the app doesn't crash and no sensitive data like project id is sent to the analytic service
    // eslint-disable-next-line no-console
    console.error("Error in createPathForTracking", error);
    return "";
  }
}
