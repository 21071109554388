import { DateTimeUtils } from "@stellar/web-core";

/**
 * Returns true if the end date is newer than the compare date.
 *
 * @param date The date to compare.
 * @param compareDate The date to compare to.
 */
export function isDateNewer(date: string, compareDate: string): boolean {
  return new Date(date) > new Date(compareDate);
}

/**
 * Returns true if the end date is newer than today.
 *
 * @param endDate The end date to compare to today.
 */
export function shouldShowNewTag(endDate?: string): boolean {
  if (!endDate) {
    return false;
  }
  const today = new Date().toISOString();
  return !isDateNewer(today, endDate);
}

/** Returns true iff the passed date is older than the current date */
export function isDateOverdue(date: string): boolean {
  return new Date() > new Date(date);
}

/**
 * Returns the age of a date in days.
 *
 * @param date The date to calculate the age from.
 * @returns The age of the date in days.
 */
export function getAge(date: number): number {
  return Math.abs(
    DateTimeUtils.diffFromTodayInDays({
      date,
      shouldIncludeToday: true,
    })
  );
}

/**
 * Returns the age of a date in a human-readable format.
 *
 * @param age The age of the date in days.
 * @returns The age of the date in a human-readable format.
 */
export function getDisplayAge(age: number): string {
  const DAYS_IN_WEEK = 7;
  const DAYS_IN_MONTH = 30;
  const DAYS_IN_YEAR = 365;
  const WEEKS_IN_MONTH = 4;
  const MONTHS_IN_YEAR = 12;

  if (age <= 1) {
    return "Same day";
  }

  if (age <= DAYS_IN_MONTH) {
    let weeks = Math.ceil(age / DAYS_IN_WEEK);
    // Since there could be 4.2 weeks in a month, we round down to the nearest week.
    weeks = weeks > WEEKS_IN_MONTH ? WEEKS_IN_MONTH : weeks;
    return `${weeks} ${weeks === 1 ? "week" : "weeks"} old`;
  }

  if (age <= DAYS_IN_YEAR) {
    let months = Math.ceil(age / DAYS_IN_MONTH);
    // Since there could be 12.2 months in a year, we round down to the nearest month.
    months = months > MONTHS_IN_YEAR ? MONTHS_IN_YEAR : months;
    return `${months} ${months === 1 ? "month" : "months"} old`;
  }

  const years = Math.ceil(age / DAYS_IN_YEAR);
  return `${years} ${years === 1 ? "year" : "years"} old`;
}
