import { APITypes } from "@stellar/api-logic";
import { isValidEmail } from "@utils/member-utils";
import { AutoCompleteMessage } from "@components/common/faro-text-field/faro-text-field-message";
import { AutoCompleteMemberOption } from "@components/common/members-autocomplete/members-autocomplete-types";
import { MemberTypes } from "@custom-types/member-types";

/** Maximum number of members to invited that supported by backend */
export const MAX_INVITED_MEMBERS = 10;

export function shouldDisableSendInvite(
  selectedMembers: APITypes.UserIdentity[],
  isMemberInputValid: boolean,
  maxInvitedMembers = MAX_INVITED_MEMBERS
): boolean {
  return (
    (!selectedMembers.length && !isMemberInputValid) ||
    selectedMembers.length > maxInvitedMembers
  );
}

export function validateMembers(
  members: APITypes.UserIdentity[],
  teamDisplayName: string,
  maxInvitedMembers = MAX_INVITED_MEMBERS,
  membersIds?: string[]
): {
  message: AutoCompleteMessage | undefined;
  isValid: boolean;
} {
  const isMembersContainsEmail = members.some(isValidEmail);

  if (members.length > maxInvitedMembers) {
    return {
      message: {
        type: "error",
        helperText: `Please reduce your selection to a maximum of ${maxInvitedMembers} members per invite request.`,
      },
      isValid: false,
    };
  }

  if (isMembersContainsEmail) {
    return {
      message: {
        type: "info",
        helperText: `Invitation to this ${teamDisplayName} will be sent to the new email addresses`,
      },
      isValid: false,
    };
  }

  const hasInvalidMembers = members.some(
    (member) => membersIds && !membersIds.includes(member.toLowerCase())
  );

  if (hasInvalidMembers) {
    return {
      message: {
        type: "info",
        helperText:
          "Inviting members by email will also invite them to the workspace",
      },
      isValid: false,
    };
  }

  return {
    message: undefined,
    isValid: false,
  };
}

export function validateInputChange(
  memberOptions: AutoCompleteMemberOption[],
  teamOptions: MemberTypes[],
  value: string,
  teamDisplayName: string
): {
  message: AutoCompleteMessage | undefined;
  isValid: boolean;
} {
  const isValueExistOnMemberList = memberOptions.some(({ label }) =>
    label.includes(value)
  );

  // Removing message on clearing the search text
  if (!value) {
    return {
      message: undefined,
      isValid: false,
    };
  }

  // Showing error message for new and invalid email
  if (!isValueExistOnMemberList && !isValidEmail(value)) {
    return {
      message: {
        type: "error",
        helperText: "Enter a valid email",
      },
      isValid: false,
    };
  }

  // Showing info message if member already exist in the team
  if (isAlreadyMemberInCurrentTeam(value, teamOptions)) {
    return {
      message: {
        type: "info",
        helperText: `A member with the same email already exists in the ${teamDisplayName}`,
      },
      isValid: false,
    };
  }

  // New condition: Clearing message and setting valid status for valid emails
  if (isValidEmail(value)) {
    return {
      message: undefined,
      isValid: true,
    };
  }

  // Default fallback
  return {
    message: undefined,
    isValid: false,
  };
}

/** Return true if member already exist with same email in the team */
function isAlreadyMemberInCurrentTeam(
  email: string,
  teamOptions: MemberTypes[]
): boolean {
  return teamOptions.some(
    (member) => member.email === email.toLowerCase() || member.identity === email.toLowerCase()
  );
}

/** Return true if the new email is valid and it doesn't exist in the team */
export function isNewEmailValid(
  email: string,
  teamOptions: MemberTypes[]
): boolean {
  return (
    isValidEmail(email) && !isAlreadyMemberInCurrentTeam(email, teamOptions)
  );
}
