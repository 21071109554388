import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { ForbiddenPage } from "@pages/forbidden-page";
import { isAlphaTestingEnabledSelector } from "@store/app/app-selector";
import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { useAppParams } from "@router/router-helper";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import {
  fetchingStatusTeamsSelector,
  getTeamByIdSelector,
} from "@store/teams/teams-selector";
import { TeamTabs } from "@router/route-params";
import { NotFoundPage } from "@pages/not-found-page";
import { RequiredRoleCompanyLevelName } from "@utils/access-control/company/company-access-control-types";
import { TeamMembers } from "@pages/members/teams/team-members";
import { useEffect } from "react";
import { useCoreApiClient } from "@api/use-core-api-client";
import { fetchTeamDetails } from "@store/teams/teams-slice-thunk";
import { FetchingStatus } from "@store/store-types";
import { TeamDetailsButton } from "@pages/members/teams/team-details-button";

/** Renders the team overview page */
export function TeamOverview(): JSX.Element {
  const { companyId, teamId, teamTabs } = useAppParams();
  const { canViewTeams, canEditTeams } = useHasUserValidRoleCompanyLevel();
  const selectedTeam = useAppSelector(getTeamByIdSelector(teamId));
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const isFetchingStatus = useAppSelector(fetchingStatusTeamsSelector);

  /**
   * Fetches the required data to display the team details
   */
  useEffect(() => {
    if (companyId && teamId) {
      dispatch(fetchTeamDetails({ coreApiClient, companyId, teamId }));
    }
  }, [companyId, coreApiClient, dispatch, teamId]);

  if (!isAlphaTestingEnabled || !canViewTeams) {
    return <ForbiddenPage />;
  }

  if (!teamTabs || !Object.values(TeamTabs).includes(teamTabs)) {
    return <NotFoundPage />;
  }

  return (
    <TabsWithRoutes
      selectedTab={teamTabs}
      trackingEventPageName={OpenTabEvents.openTeamTab}
      requiredAttribute={selectedTeam}
      tabs={[
        {
          label: "Members",
          route: TeamTabs.members,
          content: (team) => <TeamMembers team={team} />,
          loadingContent: (
            <TeamMembers
              isLoading={isFetchingStatus === FetchingStatus.pending}
            />
          ),
          requiredRoleCompanyLevel: RequiredRoleCompanyLevelName.canViewTeams,
          actionButtons: canEditTeams ? <TeamDetailsButton /> : undefined,
        },
      ]}
    />
  );
}
