import { ListItem } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { FetchingItem } from "@store/table/table-slice-helper";
import { ProjectListContent } from "@components/common/faro-table/bulk-dialog/content-list/project-list-content";
import { ListStatusIcon } from "@components/common/faro-table/bulk-dialog/content-list/list-status-icon";
import { MemberListContent } from "@components/common/faro-table/bulk-dialog/content-list/member-list-content";
import React from "react";
import { TableDataType } from "@components/common/faro-table/faro-table-types";
import { withEllipsisTwoLines } from "@styles/common-styles";
import { TeamListContent } from "@components/common/faro-table/bulk-dialog/content-list/team-list-content";
import { NO_TRANSLATE_CLASS } from "@faro-lotv/flat-ui";

interface Props {
  /** The type of entity shown in list item */
  tableDataType: TableDataType;

  /** The item itself */
  item: FetchingItem;
}

/** List item in bulk action dialog  */
export function BulkActionListItem({
  tableDataType,
  item,
}: Props): JSX.Element {
  let content: React.ReactNode;
  switch (tableDataType) {
    case "projects":
      content = <ProjectListContent item={item} />;
      break;
    case "members":
      content = <MemberListContent item={item} />;
      break;

    case "teams":
      content = <TeamListContent item={item} />;
      break;

    default:
      break;
  }

  return (
    <ListItem
      sx={{
        px: "0px",
        fontSize: "14px",
        backgroundColor:
          item.status === "not-allowed" ? sphereColors.red100 : "none",
        "& .MuiListItemText-root": {
          ml: "12px",
          ...withEllipsisTwoLines,
          maxWidth: "350px",
        },
      }}
      className={NO_TRANSLATE_CLASS}
      secondaryAction={<ListStatusIcon item={item} />}
    >
      {content}
    </ListItem>
  );
}
