import {
  ProjectBulkButtonComponents,
  ProjectTableBulkName,
  ProjectTableSubject,
} from "@components/common/faro-table/faro-table-types";
import { useAppSelector } from "@store/store-helper";
import { selectedEntitiesSelector } from "@store/table/table-selector";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { ExportProjectsAsCSV } from "@components/table/projects/bulk-actions/export-projects-as-csv";
import {
  BaseProjectsProps,
  ProjectArchivingState,
} from "@custom-types/project-types";
import { selectedSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { BulkDeleteProjects } from "@components/table/projects/bulk-actions/bulk-delete-projects";
import { BulkChangeProjectStatus } from "@components/table/projects/bulk-actions/bulk-change-project-status";
import { BulkChangeProjectManagement } from "@components/table/projects/bulk-actions/bulk-change-project-management";
import { RequiredRoleProjectLevelName } from "@utils/access-control/project/project-access-control-types";
import { currentUserSelector } from "@store/user/user-selector";
import { useMemo } from "react";
import { isAllowedToChangeManagement } from "@pages/project-details/project-overview/change-management/change-management-utils";
import { selectedGroupSelector } from "@store/groups/groups-selector";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { BulkChangeProjectAccessLevel } from "@components/table/projects/bulk-actions/bulk-change-project-access-level";

interface Props extends BaseCompanyIdProps, BaseProjectsProps {
  /** The archiving status of the project */
  projectStatus: ProjectArchivingState;

  /** Recognizes on which subject the table is being used */
  subjectType: ProjectTableSubject;
}

/** List all the bulk action components for project table */
export function ProjectsPageBulkActions({
  companyId,
  projects,
  projectStatus,
  subjectType,
}: Props): ProjectBulkButtonComponents {
  const selectedEntities = useAppSelector(selectedEntitiesSelector("projects"));
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const isAnyRowSelected = selectedEntities.length !== 0;
  const currentUser = useAppSelector(currentUserSelector);
  const { hasUserPermissionProjectLevel } = useHasUserValidRoleProjectLevel();

  const hasUserValidRoleToChangeStatusOfAnyProject = selectedEntities.some(
    (project) =>
      hasUserPermissionProjectLevel({
        roleName:
          projectStatus === ProjectArchivingState.active
            ? RequiredRoleProjectLevelName.canArchiveProjects
            : RequiredRoleProjectLevelName.canUnarchiveProjects,
        selectedProject: project,
      })
  );

  const hasUserValidRoleToDeleteAnyProject = selectedEntities.some((project) =>
    hasUserPermissionProjectLevel({
      roleName: RequiredRoleProjectLevelName.canDeleteProject,
      selectedProject: project,
    })
  );

  const hasUserValidRoleToChangeAccessOfAnyProject = selectedEntities.some(
    (project) =>
      hasUserPermissionProjectLevel({
        roleName: RequiredRoleProjectLevelName.canChangeProjectAccessLevel,
        selectedProject: project,
      })
  );

  /** The name of the subject in which the table is shown for */
  const entityName = useMemo(() => {
    if (subjectType === "group" && selectedGroup) {
      return selectedGroup.name;
    }
    if (subjectType === "workspace" && selectedSdbCompany) {
      return selectedSdbCompany.name;
    }
    return null;
  }, [selectedGroup, selectedSdbCompany, subjectType]);

  const allowedButtonsBasedOnRowSelection: ProjectTableBulkName[] =
    useMemo(() => {
      const allowedButtons: ProjectTableBulkName[] = ["exportCsv"];

      if (
        projectStatus === ProjectArchivingState.archived &&
        isAnyRowSelected &&
        hasUserValidRoleToDeleteAnyProject
      ) {
        allowedButtons.push("removeProject");
      }

      if (isAnyRowSelected && hasUserValidRoleToChangeStatusOfAnyProject) {
        allowedButtons.push("changeProjectStatus");
      }

      if (
        isAnyRowSelected &&
        isAllowedToChangeManagement(
          projectStatus === ProjectArchivingState.archived,
          currentUser
        )
      ) {
        allowedButtons.push("changeProjectManagement");
      }

      if (
        projectStatus === ProjectArchivingState.active &&
        isAnyRowSelected &&
        hasUserValidRoleToChangeAccessOfAnyProject
      ) {
        allowedButtons.push("changeAccessLevel");
      }

      return allowedButtons;
    }, [
      currentUser,
      hasUserValidRoleToChangeStatusOfAnyProject,
      hasUserValidRoleToDeleteAnyProject,
      hasUserValidRoleToChangeAccessOfAnyProject,
      isAnyRowSelected,
      projectStatus,
    ]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  return {
    changeProjectManagement: shouldShowBulkButtons(
      "changeProjectManagement"
    ) ? (
      <BulkChangeProjectManagement companyId={companyId} />
    ) : null,

    changeAccessLevel: shouldShowBulkButtons("changeAccessLevel") ? (
      <BulkChangeProjectAccessLevel />
    ) : null,

    changeProjectStatus: shouldShowBulkButtons("changeProjectStatus") ? (
      <BulkChangeProjectStatus projectStatus={projectStatus} />
    ) : null,

    removeProject: shouldShowBulkButtons("removeProject") ? (
      <BulkDeleteProjects companyId={companyId} />
    ) : null,

    exportCsv:
      shouldShowBulkButtons("exportCsv") && entityName ? (
        <ExportProjectsAsCSV
          selectedProjects={isAnyRowSelected ? selectedEntities : projects}
          subjectType={subjectType}
          entityName={entityName}
          projectStatus={projectStatus}
          areEntitiesSelected={isAnyRowSelected}
        />
      ) : null,
  };
}
