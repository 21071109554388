import { Box, Stack, Step, StepLabel } from "@mui/material";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { DataManagementStepIcon } from "@pages/project-details/project-data-management/data-management-step-icon";
import { StepState } from "@pages/project-details/project-data-management/data-management-types";
import { OpenViewerButton } from "@pages/project-details/project-data-management/open-viewer-button";
import { FaroPopoverAction } from "@components/common/faro-popover-action";
import { publishOrReRegister } from "@pages/project-details/project-data-management/register-data-utils";
import { useAppSelector, useAppDispatch } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useState } from "react";
import { assert } from "@faro-lotv/foundation";

interface Props extends BaseProjectIdProps {
  publishStepState: StepState;
  registrationId: string | undefined;
}

export function PublishStep({
  publishStepState,
  projectId,
  registrationId,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const projectApiClient = useProjectApiClient({ projectId });
  const progressApiClient = useProgressApiClient({ projectId: projectId.toString() });
  const currentUser = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const { showToast } = useToast();
  const { handleErrorWithToast, handleErrorSilently } = useErrorContext();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Stack direction="column" spacing={0}>
      <Step
        active={publishStepState === "active"}
        completed={publishStepState === "done"}
      >
        <FaroPopoverAction
          title="Publishing Failed"
          description="An error occurred while publishing your project dataset. Please try publishing again."
          actionTitle="Retry Publishing"
          action={async () => {
            assert(currentUser?.id, "User should be logged in");
            assert(registrationId, "registrationId should be present");
            await publishOrReRegister({
              projectId,
              userId: currentUser?.id,
              registrationId,
              progressApiClient,
              projectApiClient,
              trackEvent,
              setIsLoading,
              showToast,
              dispatch,
              handleErrorSilently,
              handleErrorWithToast,
            });
          }}
          isLoading={isLoading}
          isEnabled={publishStepState === "error"}
        >
          <StepLabel StepIconComponent={DataManagementStepIcon} error={publishStepState === "error"}>
            Publish
          </StepLabel>
        </FaroPopoverAction>
      </Step>
      { (publishStepState === "done") &&
        <Box sx={{
          width: "fit-content",
          marginLeft: "18px",
        }}>
          <OpenViewerButton projectId={projectId} />
        </Box>
      }
    </Stack>
  );
}
