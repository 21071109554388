import { useEffect, useState } from "react";
import { useAuthContext } from "@context-providers/auth/auth-context";
import { useCoreApiClient } from "@api/use-core-api-client";
import { SecuredRoute, SecuredRouteProps } from "@router/secured-route";
import { CoreAPIUtils } from "@stellar/api-logic";
import { StatusCodes } from "http-status-codes";

type AuthenticatedRouteProps = SecuredRouteProps;

/**
 * Component to add to routes that require authentication, if the user is authenticated displays
 * the content and if not requests a login.
 * It can optionally add a required company role prop to make it also a secured route based
 * on the user company role.
 * The required company role defines the minimum company role that the user can have,
 * and if it doesn't match that criteria it redirects to a forbidden page.
 */
export function AuthenticatedRoute({
  children,
  requiredRoleCompanyLevel,
  requiredRoleProjectLevel,
}: AuthenticatedRouteProps): JSX.Element | null {
  const { showSessionExpiredDialog } = useAuthContext();
  const coreApiClient = useCoreApiClient();

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  /**
   * Checks if the user is authenticated before rendering the children component.
   * Issues a request to the isLoggedIn backend endpoint. If it succeeds it allows to continue.
   * If the request fails the CoreAPI backend returns an error code 403 "FORBIDDEN" when the
   * user it not authenticated. In this case shows the session expired dialog to the user and don't allow to continue
   */
  useEffect(() => {
    async function checkAuth(): Promise<void> {
      try {
        await coreApiClient.V1.SDB.isLoggedIn();
        setIsAuthenticated(true);
      } catch (error) {
        if (
          CoreAPIUtils.isResponseError(error) &&
          error.status === StatusCodes.FORBIDDEN
        ) {
          showSessionExpiredDialog();
          setIsAuthenticated(false);
        }
      }
    }

    checkAuth();
  }, [coreApiClient, showSessionExpiredDialog]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  const returnJsx = isAuthenticated ? <>{children}</> : null;

  if (requiredRoleCompanyLevel || requiredRoleProjectLevel) {
    return (
      <SecuredRoute
        requiredRoleCompanyLevel={requiredRoleCompanyLevel}
        requiredRoleProjectLevel={requiredRoleProjectLevel}
      >
        {returnJsx}
      </SecuredRoute>
    );
  }

  return returnJsx;
}
