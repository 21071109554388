import {
  MemberTableBulkName,
  TeamMemberBulkButtonComponents,
} from "@components/common/faro-table/faro-table-types";
import { useAppSelector } from "@store/store-helper";
import { selectedEntitiesSelector } from "@store/table/table-selector";
import { useShouldShowBulkAction } from "@hooks/table/use-should-show-bulk-action";
import { useMemo } from "react";
import { BulkMemberCsvExport } from "@components/table/members/bulk-actions/bulk-member-csv-export";
import { SdbTeamMemberBase } from "@custom-types/teams-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { BulkRemoveMember } from "@components/table/members/bulk-actions/bulk-remove-member";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";

interface Props extends BaseCompanyIdProps {
  /** Selected team details */
  team?: SphereDashboardAPITypes.ITeam;

  /** List of members of the team to process in bulk actions */
  teamMembers: SdbTeamMemberBase[];
}

/** List all the bulk action components for team members table */
export function TeamMembersPageBulkActions({
  companyId,
  team,
  teamMembers,
}: Props): TeamMemberBulkButtonComponents {
  const selectedEntities = useAppSelector(selectedEntitiesSelector("members"));
  const isAnyRowSelected = selectedEntities.length !== 0;
  const { canDeleteMembersFromTeam } = useHasUserValidRoleCompanyLevel();

  const allowedButtonsBasedOnRowSelection: MemberTableBulkName[] =
    useMemo(() => {
      const allowedButtons: MemberTableBulkName[] = ["exportCsv"];

      if (canDeleteMembersFromTeam && isAnyRowSelected) {
        allowedButtons.push("removeMember");
      }

      return allowedButtons;
    }, [canDeleteMembersFromTeam, isAnyRowSelected]);

  const { shouldShowBulkButtons } = useShouldShowBulkAction({
    allowedButtonsBasedOnRowSelection,
  });

  // Early return if there is no team available
  if (!team) {
    return {
      exportCsv: null,
      removeMember: null,
    };
  }

  return {
    removeMember: shouldShowBulkButtons("removeMember") ? (
      <BulkRemoveMember
        subjectType="team"
        companyId={companyId}
        teamId={team.id}
      />
    ) : null,
    exportCsv: shouldShowBulkButtons("exportCsv") ? (
      <BulkMemberCsvExport
        selectedEntities={isAnyRowSelected ? selectedEntities : teamMembers}
        subjectType="team"
        entityName={team.name}
        areEntitiesSelected={isAnyRowSelected}
      />
    ) : null,
  };
}
