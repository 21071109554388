import { SphereCircularProgress } from "@components/common/sphere-circular-progress";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Box } from "@mui/material";
import { useAppSelector } from "@store/store-helper";
import { sidebarSelector } from "@store/ui/ui-selector";
import { sphereColors } from "@styles/common-colors";
import { withEllipsis } from "@styles/common-styles";
import { nounPluralize } from "@utils/data-display";
import { useMemo } from "react";

/** Default trial duration in days */
const DEFAULT_TRIAL_DURATION = 21;

interface Props {
  /** Days that remain until the trial expires */
  remainingDays: number;

  /** Trial duration in days. Default value is 21 days */
  trialDuration?: number;
}

/** Circular progress indicator that shows the remaining days of a trial subscription */
export function TrialCountdownIndicator({
  remainingDays,
  trialDuration,
}: Props): JSX.Element {
  const { isFullSize } = useAppSelector(sidebarSelector);

  const text = useMemo(() => {
    return `${nounPluralize({
      counter: remainingDays,
      word: "day",
    })} left`;
  }, [remainingDays]);

  return (
    <Box
      data-testid="trial-countdown-indicator"
      component="div"
      sx={{
        paddingY: "8px",
        paddingLeft: isFullSize ? "18px" : "2px",
        paddingRight: "2px",
        width: "100%",
        ...withEllipsis,
      }}
    >
      <SphereTooltip title="Upgrade now to keep access to all your projects.">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: isFullSize ? "normal" : "center",
          }}
        >
          <SphereCircularProgress
            value={remainingDays}
            maxValue={trialDuration ?? DEFAULT_TRIAL_DURATION}
            unit=""
            size={34}
            thickness={6}
          />
          {isFullSize && (
            <Box
              data-testid="trial-countdown-indicator-details"
              sx={{
                marginLeft: "6px",
                ...withEllipsis,
              }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: 400,
                  color: sphereColors.gray800,
                  ...withEllipsis,
                }}
              >
                Trial period
              </Box>
              <Box
                sx={{
                  fontSize: "10px",
                  fontWeight: 400,
                  color: sphereColors.gray600,
                  ...withEllipsis,
                }}
              >
                {text}
              </Box>
            </Box>
          )}
        </Box>
      </SphereTooltip>
    </Box>
  );
}
