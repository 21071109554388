import { useCallback } from "react";
import { ProjectApi } from "@api/project-api/project-api";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { GUID } from "@faro-lotv/foundation";
import { RegistrationState } from "@faro-lotv/service-wires";

type ReturnFunction = (
  projectApiClient: ProjectApi,
  registrationRevisionId: GUID,
) => Promise<void>;

/** Sets revision as canceled. */
export function useCancelRevision(): ReturnFunction {
  const { handleErrorSilently } = useErrorContext();

  return useCallback(
    async (
      projectApiClient: ProjectApi,
      registrationRevisionId: GUID
    ): Promise<void> => {
      try {
        await projectApiClient.updateRegistrationRevision({
          registrationRevisionId,
          state: RegistrationState.canceled,
        });
      } catch (error) {
        // If it fails to set the revision as canceled only log the error but
        // don't show it to the user since this is not a critical step.
        handleErrorSilently({
          id: `cancelRevision-${Date.now().toString()}`,
          title: "Failed to set revision as canceled.",
          error,
        });
      }
    },
    [handleErrorSilently]
  );
}
