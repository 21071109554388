import { LanguageSelectorEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  isSupportedLanguageObject,
  LanguageSelectOption,
} from "@faro-lotv/flat-ui";
import { useLocalize } from "@faro-lotv/foreign-observers";
import { useAppSelector } from "@store/store-helper";
import { isAlphaTestingEnabledSelector } from "@store/app/app-selector";

/**
 * A simple Language menu used in the user-menu.
 * When opened the user can chose between the available languages
 * and select to which one the Dashboard should be translated to.
 */
export function LanguageSelector(): JSX.Element {
  const { availableLanguages, setLanguage, currentLanguage } = useLocalize();
  const { trackEvent } = useTrackEvent();
  const isAlphaTestingEnabled = useAppSelector(isAlphaTestingEnabledSelector);

  // TODO: Remove this, when  French translation is officially released: https://faro01.atlassian.net/browse/ST-2530
  const languages = availableLanguages
    .filter(({ code }) => isAlphaTestingEnabled || code !== "fr")
    .filter(isSupportedLanguageObject);

  function onChange(languageCode: string): void {
    const languageObject = availableLanguages.find(
      ({ code }) => code === languageCode
    );

    if (languageObject) {
      trackEvent({
        name: LanguageSelectorEvents.changeLanguage,
        props: { language: languageObject.code },
      });
      setLanguage(languageObject.code);
    }
  }

  return (
    <LanguageSelectOption
      selectedLanguage={currentLanguage.code}
      languages={languages}
      onLanguageChange={onChange}
    />
  );
}
