import { CaptureTreeEntity } from "@faro-lotv/service-wires";

/** Possible device types used to generate the an UploadedData entity of type scan */
export enum DeviceType {
  blink = "Blink",
  focus = "Focus",
  orbis = "Orbis",
  unknown = "Unknown",
}

/** Uploaded ELS scan */
export interface UploadedElsScan extends CaptureTreeEntity {
  /** Path to the cluster where the entity is located */
  clusterPath: string;

  /** Whether the scan is still processing */
  isProcessing: boolean;

  /** Whether the scan has errors in relevant processing tasks */
  hasTaskErrors: boolean;
}

/** All possible uploaded data types */
export type UploadedData = UploadedElsScan;
