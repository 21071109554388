import { Button, ButtonProps, SvgIcon } from "@mui/material";
import { MouseEvent, useMemo } from "react";
import { FaroButtonSpinner } from "@components/common/button/faro-button-spinner";
import CalendarIcon from "@assets/icons/calendar.svg?react";
import CloseIcon from "@assets/icons/new/close_24px.svg?react";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { FaroDatePicker } from "@components/common/faro-text-button-date-picker/faro-date-picker";
import { addTransparency, getSvgColoredIconCss } from "@utils/ui-utils";
import { useDateTime } from "@hooks/use-date-time";

interface Props {
  /** Whether the button is disabled or not */
  isDisabled: boolean;

  /** Whether the date is loading, fetching etc. */
  isLoading: boolean;

  /** Whether a clear button should be shown at the end of date button or not */
  isClearable?: boolean;

  /** The anchor element that the date picker is attached to */
  anchorEl: HTMLElement | null;

  /** Handler when the date is changed */
  onDateConfirmed: (updatedDate: Date | null) => void;

  /** Optional sx properties to override the button style. */
  buttonSx?: ButtonProps["sx"];

  /** The current value/date */
  value: Date | null;

  /** Handler when date picker will close */
  closeDatePicker: () => void;

  /**
   * Handler to update the anchor element
   *
   * @param event - The mouse event triggered by clicking the button.
   * @returns void
   */
  updateAnchorEl: (event: MouseEvent<HTMLButtonElement>) => void;
}

/** Used to control the visibility on hover */
const CLEAR_ICON_CLASS_NAME = "clear_icon";

/** Shows a text button with date label and allows user to select a date by clicking on the button */
export function FaroTextButtonDatePicker({
  isDisabled,
  isLoading,
  isClearable = false,
  anchorEl,
  onDateConfirmed,
  closeDatePicker,
  updateAnchorEl,
  buttonSx,
  value,
}: Props): JSX.Element {
  const { formatDate } = useDateTime();

  const formattedDate = useMemo(() => {
    if (value === null) {
      return undefined;
    }
    return formatDate(value);
  }, [formatDate, value]);

  let endIcon: React.ReactNode;
  if (isLoading) {
    endIcon = <FaroButtonSpinner />;
  } else if (value && isClearable) {
    endIcon = (
      <SvgIcon
        component={CloseIcon}
        onClick={(event) => {
          event.stopPropagation();
          onDateConfirmed(null);
        }}
        className={CLEAR_ICON_CLASS_NAME}
        sx={{
          ...getSvgColoredIconCss(sphereColors.gray400),
          visibility: "hidden",
          padding: "4px",
          height: "24px",
          width: "24px",
          "&:hover": {
            ...getSvgColoredIconCss(sphereColors.blue500),
            backgroundColor: addTransparency({
              color: sphereColors.gray800,
              alpha: EDecimalToHex.forty,
            }),
            borderRadius: "50%",
          },
        }}
      />
    );
  }

  return (
    <>
      <Button
        disableRipple
        endIcon={endIcon}
        variant="text"
        disabled={isDisabled}
        onClick={(event) => {
          event.stopPropagation();

          updateAnchorEl(event);
        }}
        sx={{
          display: "flex",
          fontSize: "12px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "100%",
          px: "8px",
          cursor: "pointer",
          gap: "10px",
          borderBottom: `1px solid ${
            anchorEl ? sphereColors.blue400 : "transparent"
          }`,
          borderRadius: 0,
          background: anchorEl ? "white" : "",
          textTransform: "none",
          "&:hover": {
            background: anchorEl ? "white" : "transparent",
            [`& .${CLEAR_ICON_CLASS_NAME}`]: {
              visibility: "visible",
            },
            borderBottom:
              !anchorEl && !isDisabled
                ? `1px solid ${sphereColors.gray800}`
                : "",
          },
          ...buttonSx,
        }}
      >
        {formattedDate ? <var>{formattedDate}</var> : <CalendarIcon />}
      </Button>

      {anchorEl && (
        <FaroDatePicker
          anchorEl={anchorEl}
          closeDatePicker={closeDatePicker}
          initialDate={value}
          onDateConfirmed={(updatedDate) =>
            onDateConfirmed(updatedDate ? updatedDate.toJSDate() : null)
          }
        />
      )}
    </>
  );
}
