export enum ChangeThumbnailTab {
  /** Tab from where an image can be selected from the existing image gallery  */
  gallery = "Gallery",

  /** Tab from where an image can be uploaded from computer  */
  computer = "From computer",
}

/** Represents the type of subject for which the thumbnail is being changed */
export enum ThumbnailSubjectType {
  project = "Project",
  group = "Group",
}
