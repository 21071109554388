import { FaroChangeThumbnailDialog } from "@components/common/dialog/faro-change-thumbnail-dialog";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { updateGroupDetails } from "@store/groups/groups-slice";
import { sphereColors } from "@styles/common-colors";
import { ThumbnailSubjectType } from "@utils/thumbnail-utils";
import { ChangeGroupDetailsEvents } from "@utils/track-event/track-event-list";
import ImageEdit from "@assets/icons/new/image-edit.svg?react";
import { useState } from "react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { selectedGroupSelector } from "@store/groups/groups-selector";

export function ChangeGroupThumbnailButton(): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const selectedGroup = useAppSelector(selectedGroupSelector);
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();

  return (
    <>
      <SphereTooltip title="Change thumbnail">
        <FaroIconButton
          component={ImageEdit}
          buttonSize="24"
          iconSize="24"
          color={sphereColors.gray800}
          onClick={() => setIsDialogOpen(true)}
        />
      </SphereTooltip>

      {isDialogOpen && selectedGroup && (
        <FaroChangeThumbnailDialog
          setIsDialogOpen={setIsDialogOpen}
          subjectId={selectedGroup?.id}
          subjectType={ThumbnailSubjectType.group}
          existingThumbnailUrl={selectedGroup.thumbnailUrl}
          onUpdateThumbnail={async (thumbnailUrl: string) => {
            await dispatch(
              updateGroupDetails({
                coreApiClient,
                groupId: selectedGroup.id,
                payload: { imageUrl: thumbnailUrl },
              })
            );
          }}
          eventName={ChangeGroupDetailsEvents.changeGroupThumbnail}
        />
      )}
    </>
  );
}
