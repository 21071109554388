import { MainRoutes } from "@router/route-params";
import { BaseProjectProps } from "@custom-types/project-types";
import { useAppSelector } from "@store/store-helper";
import { isBetaTestingEnabledOrNotProdSelector } from "@store/app/app-selector";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { isProjectArchiveSelector } from "@store/projects/projects-selector";
import { FaroButtonContained } from "@components/common/faro-button-contained";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { PreservingNavLink } from "@components/common/preserve-navigation/preserving-nav-link";

interface Props extends BaseProjectProps {
  /** Flag to show a loading state */
  isLoading?: boolean;
}

/**
 *  Button to open staging area of a project for data management
 */
export function DataManagementButton({
  project,
  isLoading,
}: Props): JSX.Element | null {
  const { trackEvent } = useTrackEvent();

  const isBetaTestingEnabled = useAppSelector(
    isBetaTestingEnabledOrNotProdSelector
  );
  const { canViewDataManagement } = useHasUserValidRoleProjectLevel({
    selectedProject: project,
  });
  const isProjectArchived = useAppSelector(
    isProjectArchiveSelector(project.id)
  );

  /**
   * Returning null if:
   * - The user is not able to see the button
   * - Project is archived
   */
  if (
    isLoading ||
    !canViewDataManagement ||
    !isBetaTestingEnabled ||
    isProjectArchived
  ) {
    return null;
  }

  return (
    <PreservingNavLink to={`../${MainRoutes.dataManagement}`}>
      <FaroButtonContained
        onClick={() => {
          trackEvent({
            name: ProjectEvents.openDataManagement,
            props: {
              origin: "projectDetails",
            },
          });
        }}
      >
        Manage Data
      </FaroButtonContained>
    </PreservingNavLink>
  );
}
