import {
  BackgroundTaskCategory,
  CloudRegistrationTask,
  SdbBackgroundTask,
} from "@custom-types/sdb-background-tasks-types";

/**
 * @returns whether an SdbBackgroundTask entity is of type CloudRegistrationTask
 *
 * @param task SdbBackgroundTask entity
 */
export function isCloudRegistrationTask(
  task: SdbBackgroundTask
): task is CloudRegistrationTask {
  return (
    task.taskType === "CloudRegistration" &&
    task.taskCategory === BackgroundTaskCategory.pointCloud
  );
}
