import { useAppSelector } from "@store/store-helper";
import { isProjectProcessingSelector } from "@store/projects/projects-selector";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { BaseCompanyIdProps } from "@custom-types/sdb-company-types";
import { BaseProjectProps } from "@custom-types/project-types";
import { ProjectCardTop } from "@components/common/project-card/project-card-top";
import { CardMiddle } from "@components/common/project-card/project-card-middle";
import { ProjectCardBottom } from "@components/common/project-card/project-card-bottom";
import { FaroCard } from "@components/common/faro-card/faro-card";

interface Props extends BaseCompanyIdProps, BaseProjectProps {
  /** Callback when the card is clicked */
  onClick?: () => void;
}

/**
 * A card that summarizes the project info
 */
export function ProjectCard({
  companyId,
  project,
  onClick,
}: Props): JSX.Element {
  const { navigateToProjectDetail } = useAppNavigation();

  const isProjectProcessing = useAppSelector(
    isProjectProcessingSelector(project.id)
  );

  return (
    <FaroCard
      isLoading={isProjectProcessing}
      onClick={() => {
        onClick?.();

        navigateToProjectDetail({
          companyId: companyId,
          projectId: project.id,
        });
      }}
    >
      {/* Top section of the card: Shows the project thumbnail image */}
      <ProjectCardTop project={project} />

      {/* Middle section of the card: Shows the project details like name and last update */}
      <CardMiddle project={project} />

      {/* Bottom section of the card: Shows the access level and the project actions */}
      <ProjectCardBottom project={project} />
    </FaroCard>
  );
}
