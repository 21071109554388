// Here we do not need it as a React component because will be used as src for an image
import defaultThumbnail from "/src/assets/group-thumbnail_default.svg";
import { BaseGroupProps } from "@custom-types/group-types";
import { DemoTag } from "@components/common/demo-tag";
import { isGroupDemo } from "@utils/group-utils";
import { FaroCardImage } from "@components/common/faro-card/faro-card-image";

/**
 * Top section of a group card that show the group thumbnail image.
 */
export function GroupCardTop({ group }: BaseGroupProps): JSX.Element {
  return (
    <>
      <FaroCardImage
        src={group.thumbnailUrl ? group.thumbnailUrl : defaultThumbnail}
      />
      {/* If the group is a demo group shows a demo tag on the top left side */}
      {isGroupDemo(group) && (
        <DemoTag
          sx={{
            position: "absolute",
            top: "15px",
            left: "15px",
          }}
        />
      )}
    </>
  );
}
