import { getProjectApiClient } from "@api/project-api/project-api-utils";
import { DEFAULT_POSE_PARAM } from "@src/constants/capture-tree-constants";
import { getRootEntity } from "@utils/capture-tree-utils";
import {
  getElsClusterName,
  getElsClusterEntity,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { APITypes } from "@stellar/api-logic";
import {
  CaptureApiClient,
  CaptureTreeEntityRevision,
  CreateClusterEntitiesParams,
  CreateRootEntityParams,
} from "@faro-lotv/service-wires";
import { ClusterEntity, RootEntity } from "@custom-types/capture-tree-types";

export interface CreateRevision {
  /** ID of the created registration revision */
  registrationRevisionId: string;

  /** ID of the created revision cluster entity */
  revisionClusterEntityId: string;

  /** Optional param to define client name that creates the revision */
  createdByClient?: CaptureApiClient;
}

/**
 * Creates a capture tree revision that is ready to contain ELS scans uploaded by the user.
 * If the main revision did not have a root and a "ELS" cluster yet it also creates those entities.
 *
 * TODO: Simplify logic once the ProjectAPI updates the endpoints to return the created entity:
 * https://faro01.atlassian.net/browse/ST-2118
 *
 * @param projectId ID of the project where the revision will be created
 * @throws error if it fails to create the revision, root or cluster
 * @returns IDs of the created revision and "ELS" cluster
 */
export async function createRevisionForElsScans(
  projectId: APITypes.ProjectId | null
): Promise<CreateRevision> {
  if (!projectId) {
    throw new Error("Project ID is undefined.");
  }

  const projectApiClient = getProjectApiClient({ projectId });
  let revisionEntities: CaptureTreeEntityRevision[] = [];
  let revisionRoot: RootEntity<CaptureTreeEntityRevision> | undefined =
    undefined;
  let revisionCluster: ClusterEntity<CaptureTreeEntityRevision> | undefined =
    undefined;

  // Get capture tree entities for main revision
  const captureTreeEntities =
    await projectApiClient.getCaptureTreeForMainRevision();

  // If main revision root and/or ELS cluster are defined we need their IDs to create the revision
  const mainRevisionRoot = getRootEntity(captureTreeEntities);
  const mainRevisionElsCluster = getElsClusterEntity(captureTreeEntities);
  const mainRevisionRootAndClusterIds: string[] = [];
  if (mainRevisionRoot) {
    mainRevisionRootAndClusterIds.push(mainRevisionRoot.id);
  }
  if (mainRevisionElsCluster) {
    mainRevisionRootAndClusterIds.push(mainRevisionElsCluster.id);
  }

  // Create revision.
  const registrationRevision =
    await projectApiClient.createRegistrationRevision(
      mainRevisionRootAndClusterIds,
      [],
      CaptureApiClient.dashboard
    );
  const registrationRevisionId = registrationRevision.id;

  // Get entities of created revision
  revisionEntities =
    await projectApiClient.getCaptureTreeForRegistrationRevision(
      registrationRevisionId
    );

  // Get root entity
  revisionRoot = getRootEntity(revisionEntities);

  // If the root entity exists we need to patch it to set its status as modified
  // If it does not exists we need to create it
  if (revisionRoot) {
    await projectApiClient.createOrUpdateRootEntityForRegistrationRevision({
      registrationRevisionId,
      requestBody: {
        id: revisionRoot.id,
        // TODO: The request body type needs to be updated to accept params to update the entity
        // https://faro01.atlassian.net/browse/ST-2133
      } as unknown as CreateRootEntityParams["requestBody"],
    });
  } else {
    await projectApiClient.createOrUpdateRootEntityForRegistrationRevision({
      registrationRevisionId,
      requestBody: {
        pose: DEFAULT_POSE_PARAM,
      },
    });

    // Unfortunately the endpoints to create root, cluster and scan entities don't return the created entity.
    // As workaround we will fetch the entities of the revision again and get the created entity.
    revisionEntities =
      await projectApiClient.getCaptureTreeForRegistrationRevision(
        registrationRevisionId
      );
    // get entities again and check that the created root exists. If not exit the process and throw an error
    revisionRoot = getRootEntity(revisionEntities);
    if (!revisionRoot) {
      throw new Error(
        "Revision root entity was successfully created, but it was later not found in the revision entities."
      );
    }
  }

  // Get cluster entity
  revisionCluster = getElsClusterEntity(revisionEntities);

  // If the cluster entity exists we need to patch it to set its status as modified
  // If it does not exists we need to create it
  if (revisionCluster) {
    await projectApiClient.createOrUpdateClusterEntitiesForRegistrationRevision({
      registrationRevisionId,
      requestBody: [
        {
          id: revisionCluster.id,
        },
        // TODO: The request body type needs to be updated to accept params to update the entity
        // https://faro01.atlassian.net/browse/ST-2133
      ] as unknown as CreateClusterEntitiesParams["requestBody"],
    });
  } else {
    await projectApiClient.createOrUpdateClusterEntitiesForRegistrationRevision({
      registrationRevisionId,
      requestBody: [
        {
          parentId: revisionRoot.id,
          name: getElsClusterName(),
          pose: DEFAULT_POSE_PARAM,
        },
      ],
    });

    // Unfortunately the endpoints to create root, cluster and scan entities don't return the created entity.
    // As workaround we will fetch the entities of the revision again and get the created entity.
    revisionEntities =
      await projectApiClient.getCaptureTreeForRegistrationRevision(
        registrationRevisionId
      );
    // get entities again and check that the created cluster exists. If not exit the process and throw an error
    revisionCluster = getElsClusterEntity(revisionEntities);
    if (!revisionCluster) {
      throw new Error(
        "Revision cluster entity was successfully created, but it was later not found in the revision entities."
      );
    }
  }

  return {
    registrationRevisionId,
    revisionClusterEntityId: revisionCluster.id,
  };
}
