import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { TeamsHeaders } from "@components/table/teams/teams-table-types";
import { FormatDate } from "@hooks/use-date-time";
import { TeamName } from "@components/table/teams/team-name";
import { DateTime } from "luxon";
import { TeamsMembersColumn } from "@components/table/teams/teams-members-column";
import { TeamsTableAction } from "@components/table/teams/teams-table-action";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { mapTeamMembersToSdbMembers } from "@components/table/teams/teams-table-utils";

interface Props {
  /** The function to format date. Needs to be passed since getMembersColumns is not a hook */
  formatDate: FormatDate;
}

/** Return all the possible columns for Teams table */
export function getTeamsColumns({
  formatDate,
}: Props): Record<TeamsHeaders, GridColDef> {
  return {
    [TeamsHeaders.name]: {
      field: TeamsHeaders.name,
      flex: 1,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: SphereDashboardAPITypes.ITeam }>
      ) => {
        return <TeamName team={params.row.entity} />;
      },
    },
    [TeamsHeaders.users]: {
      field: TeamsHeaders.users,
      flex: 1,
      minWidth: 220,
      renderCell: (
        params: GridRenderCellParams<{ entity: SphereDashboardAPITypes.ITeam }>
      ) => {
        return (
          <TeamsMembersColumn
            members={mapTeamMembersToSdbMembers(
              params.row.entity.sampleMembers
            )}
            membersCount={params.row.entity.memberCount}
          />
        );
      },
    },

    [TeamsHeaders.creationDate]: {
      field: TeamsHeaders.creationDate,
      flex: 0.75,
      minWidth: 180,
      renderCell: (
        params: GridRenderCellParams<{ entity: SphereDashboardAPITypes.ITeam }>
      ) => {
        return (
          <var>
            {formatDate(params.row.entity.createdAt, DateTime.DATETIME_MED)}
          </var>
        );
      },
    },

    [TeamsHeaders.options]: {
      field: TeamsHeaders.options,
      type: "actions",
      align: "right",
      flex: 0.5,
      minWidth: 180,
      renderCell: (
        params: GridRenderCellParams<{ entity: SphereDashboardAPITypes.ITeam }>
      ) => {
        return <TeamsTableAction team={params.row.entity} />;
      },
    },
  };
}
