import { SphereTooltip } from "@components/common/sphere-tooltip";
import { tooltipPopperProps } from "@components/user-more-info-menu/user-avatar-with-menu";
import { UserMoreInfoMenu } from "@components/user-more-info-menu/user-more-info-menu";
import { BaseMemberProps, BaseMembersProps } from "@custom-types/member-types";
import { NO_TRANSLATE_CLASS } from "@faro-lotv/flat-ui";
import { Avatar, AvatarProps, SxProps, Theme } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { getUserInitials, getUserTooltipDisplayName } from "@utils/user-utils";

interface Props extends Partial<BaseMemberProps>, Partial<BaseMembersProps> {
  /** Total number of members */
  membersCount?: number;

  /** Props for the avatar */
  avatarSx?: SxProps<Theme>;
}

/**
 * React component for an avatar to be shown inside the team members.
 * It can show a single user or a list of users.
 */
export function TeamMemberAvatar(props: Props): JSX.Element {
  /**
   * Gets the font size to be used bby the avatar based on the amount of characters.
   *
   * @param txt Text to be displayed in the avatar.
   * @returns Font size in pixels.
   */
  function getFontSize(txt: string): string {
    // It was manually calculated that with the normal font size the avatar can fit up to 3 chars.
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (txt.length <= 3) {
      return "14px";
    }
    return "11px";
  }

  /**
   * Gets the props to use in the avatar to have a consistent look and feel.
   *
   * @param txt The text to be shown in the avatar, useful to calculate the font size.
   *
   * @returns Props for the avatar.
   */
  function getAvatarProps(txt: string): Partial<AvatarProps> {
    return {
      sx: {
        backgroundColor: sphereColors.gray400,
        color: sphereColors.pureWhite,
        width: "40px",
        height: "40px",
        fontSize: getFontSize(txt),
        ...props.avatarSx,
      },
    };
  }

  let text: string = "";
  let title: string | JSX.Element = "";
  let src: string | undefined;

  // If the property members was provided, then the component will show the remaining users.
  if (props.members) {
    const remainingCount = props.membersCount
      ? props.membersCount
      : props.members.length;

    text = `+${remainingCount}`;

    title = props.membersCount ? (
      ""
    ) : (
      <div>
        {props.members.map((member) => getUserTooltipDisplayName({ member }))}
      </div>
    );
    // Otherwise a single user will be displayed.
  } else if (props.member) {
    title = getUserTooltipDisplayName({ member: props.member });
    text = getUserInitials(props.member);
    // If undefined, this property will be ignored.
    src = props.member.thumbnailUrl;
  }

  return (
    <SphereTooltip
      title={
        // Only show the menu if we are showing a single member.
        // otherwise we are showing a list with all members and emails.
        "member" in props ? <UserMoreInfoMenu member={props.member} /> : title
      }
      tooltipProps={{
        // Use the same popper props as the user avatar with menu only if we are showing a single member.
        // otherwise the tooltip is showing a list with all members and emails.
        PopperProps: "member" in props ? tooltipPopperProps : undefined,
      }}
      shouldSkipWrapper={true}
    >
      <Avatar
        alt={text}
        src={src}
        {...getAvatarProps(text)}
        className={NO_TRANSLATE_CLASS}
      >
        {text}
      </Avatar>
    </SphereTooltip>
  );
}
