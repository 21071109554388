import { Box, LinearProgress, Stack, Step, StepLabel } from "@mui/material";
import { DataManagementStepIcon } from "@pages/project-details/project-data-management/data-management-step-icon";
import { DataManagementProgressBar } from "@pages/project-details/project-data-management/data-management-stepper/data-management-progress-bar";
import { StepState, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { RegisterDataButton } from "@pages/project-details/project-data-management/register-data-button";
import { useAppSelector, useAppDispatch } from "@store/store-helper";
import { isDevModeEnabledSelector } from "@store/app/app-selector";
import { FaroPopoverAction } from "@components/common/faro-popover-action";
import { BaseProjectIdProps, BaseUserIdProps } from "@custom-types/sdb-company-types";
import { useToast } from "@hooks/use-toast";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useState } from "react";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { startRegistration } from "@pages/project-details/project-data-management/register-data-utils";

interface Props extends BaseProjectIdProps, BaseUserIdProps {
  processStepState: StepState;
  state: WorkflowState;
  progress: number;
}

export function ProcessStep({
  processStepState,
  state,
  projectId,
  userId,
  progress,
}: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const isDevModeEnabled = useAppSelector(isDevModeEnabledSelector);
  const projectApiClient = useProjectApiClient({ projectId });
  const progressApiClient = useProgressApiClient({ projectId: projectId.toString() });
  const { showToast } = useToast();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleErrorWithToast, handleErrorSilently } = useErrorContext();
  const dispatch = useAppDispatch();

  return (
    <Stack direction="column" spacing={0} sx={{ width: "100%" }}>
      <Stack direction="row" spacing={0} sx={{ alignItems: "center" }}>
        <Step
          active={processStepState === "active"}
          completed={processStepState === "done"}
        >
          <FaroPopoverAction
            title="Processing failed"
            // eslint-disable-next-line max-len
            description="Some scans could not be processed. These scans won't be published to the project dataset. You can continue with the successfully processed scans."
            actionTitle="Continue with Successful Scans"
            action={async () => {
              await startRegistration({
                projectId,
                userId,
                progressApiClient,
                projectApiClient,
                trackEvent,
                setIsLoading,
                showToast,
                dispatch,
                handleErrorSilently,
                handleErrorWithToast,
              });
            }}
            isLoading={isLoading}
            isEnabled={processStepState === "error"}
          >
            <StepLabel StepIconComponent={DataManagementStepIcon} error={processStepState === "error"}>
              Process
            </StepLabel>
          </FaroPopoverAction>
        </Step>
        {/* TODO: Use only DataManagementProgressBar once https://faro01.atlassian.net/browse/TF-1699 is done. */}
        {
          (progress !== -1) ?
          <DataManagementProgressBar progress={progress} /> :
          <LinearProgress
            variant="indeterminate"
            color="primary"
            sx={{
              "&.MuiLinearProgress-root": {
                flex: "1 1 auto",
                marginLeft: "10px",
                marginRight: "10px",
              },
            }}
          />
        }
      </Stack>
      { (state === "processed" || isDevModeEnabled) &&
        <Box sx={{
          width: "fit-content",
          marginLeft: "28px",
        }}>
          <RegisterDataButton size="small" isDevMode={state !== "processed"} />
        </Box>
      }
    </Stack>
  );
}
