import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { Grid, SvgIcon, Typography } from "@mui/material";
import {
  IntegrationDialogProps,
  IntegrationSections,
} from "@pages/integrations/integrations-types";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";

/** Shows dialog for integration in progress */
export function IntegrationsInProgressDialog({
  selectedIntegrationId,
  dialogCancelCallback,
}: IntegrationDialogProps): JSX.Element {
  return (
    <FaroDialog
      open={true}
      onConfirm={dialogCancelCallback}
      confirmText="Cancel authorization"
      title=""
      isSuccessMessage
    >
      <Grid
        maxWidth="100%"
        width="600px"
        alignItems="center"
        container
        flexDirection="column"
        justifyContent="center"
        sx={{
          width: "612px",
          height: "556px",
        }}
      >
        <SvgIcon
          inheritViewBox={true}
          component={IntegrationSections[selectedIntegrationId].icon}
          sx={{ marginBottom: "30px", width: "95px", height: "95px" }}
        />
        <Typography
          sx={{
            fontSize: "20px",
            marginBottom: "30px",
          }}
        >
          {IntegrationSections[selectedIntegrationId].displayName} authorization
        </Typography>
        <Typography
          sx={{
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            marginTop: "0px",
            marginBottom: "30px",
          }}
        >
          Please complete the authorization in the window that just opened.
        </Typography>
      </Grid>
    </FaroDialog>
  );
}
