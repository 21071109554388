import { useCallback } from "react";
import { ReadLsDataV2Response } from "@api/stagingarea-api/stagingarea-api-types";
import {
  ElsScanFileUploadTaskContext,
  UploadElementType,
  UploadMultipleFilesParams,
} from "@custom-types/file-upload-types";
import { SdbProject } from "@custom-types/project-types";
import { assert } from "@faro-lotv/foundation";
import { useOnUploadComplete } from "@hooks/data-management/use-on-upload-complete";
import { useFileUpload } from "@hooks/use-file-upload";
import {
  ALLOWED_EXTENSIONS_GLS,
  lsDataV2InfoForTracking,
  MAX_FILE_SIZE_IN_MB,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

type ReturnFunction = (
  registrationRevisionId: string,
  revisionClusterEntityId: string,
  files: File[],
  lsDataV2: ReadLsDataV2Response | null,
  scansAlreadyUploaded: Set<File>,
) => Promise<void>;

/** Initiates the upload of multiple *.gls files. */
export function useInitiateMultipleFileUpload(
  project?: SdbProject | null
): ReturnFunction {
  const { uploadMultipleFiles, validateAndAddFailedTask } = useFileUpload();
  const { trackEvent } = useTrackEvent();
  const onUploadComplete = useOnUploadComplete(project);

  return useCallback(
    /**
     * Initiates the upload of multiple *.gls files.
     * @param registrationRevisionId ID of our Capture Tree revision.
     * @param revisionClusterEntityId ID of our Capture Tree cluster.
     * @param files The selected *.gls files to upload.
     * @param lsDataV2 The parsed scan metadata, if available.
     * @param scansAlreadyUploaded Subset of files to skip, because they were already uploaded.
     * @returns Resolved promise when all files were uploaded or cancelled.
     */
    async (
      registrationRevisionId: string,
      revisionClusterEntityId: string,
      files: File[],
      lsDataV2: ReadLsDataV2Response | null,
      scansAlreadyUploaded: Set<File>
    ): Promise<void> => {
      assert(project, "Expected project to be provided.");

      const context: ElsScanFileUploadTaskContext = {
        uploadElementType: UploadElementType.elsScan,
        projectId: project.id,
        registrationRevisionId,
        revisionClusterEntityId,
        lsDataV2,
      };

      // For the upload, we only consider files with the .gls extension.
      // So we shouldn't add errors for any other files.
      const uploadableFiles = files
        // Silently ignore already uploaded scans.
        .filter((file) => !scansAlreadyUploaded.has(file))
        // These conditions were already checked, except for the file size, but 20 GB is far enough for any *.fls|*.gls file.
        .filter((file) =>
          validateAndAddFailedTask({
            file,
            allowedExtensions: ALLOWED_EXTENSIONS_GLS,
            maxFileSize: MAX_FILE_SIZE_IN_MB,
            context,
          })
      );

      trackEvent({
        name: DataManagementEvents.startUpload,
        props: {
          filesGLS: files.length,
          filesGLSToUpload: uploadableFiles.length,
          filesGLSAlreadyUploaded: scansAlreadyUploaded.size,
          ...lsDataV2InfoForTracking(lsDataV2),
        },
      });

      // Return if there is no uploadable file
      if (!uploadableFiles.length) {
        return;
      }

      const uploadParams: UploadMultipleFilesParams = {
        files: uploadableFiles,
        onUploadStart: () => undefined,
        onUploadProgress: () => undefined,
        onUploadComplete,
        context,
      };

      await uploadMultipleFiles(uploadParams);
    },
    [
      onUploadComplete,
      project,
      uploadMultipleFiles,
      validateAndAddFailedTask,
      trackEvent,
    ]
  );
}
