import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { BaseMarkupProps, Markup } from "@custom-types/project-markups-types";
import { createEditStatusMutation } from "@pages/project-details/project-markups/status/markup-status-utils";
import { getErrorDisplayMarkup } from "@context-providers/error-boundary/error-boundary-utils";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  AnnotationStatus,
  IElement,
  IElementAttachment,
  IElementType,
  IElementTypeHint,
  IElementUserDirectoryMarkupField,
} from "@faro-lotv/ielement-types";
import { generateGUID } from "@faro-lotv/foundation";
import {
  createMutationAddMarkupField,
  createMutationUserDirectoryMarkupField,
  createMutationDeleteElement,
  Mutation,
  MutationDeleteElement,
  MutationUserDirectoryMarkupField,
  MutationAddMarkupField,
  MutationAddAttachment,
} from "@faro-lotv/service-wires";
import {
  NewAttachment,
  createAttachmentMutation,
} from "@pages/project-details/project-markups/markup-attachment-utils";
import { useAppDispatch } from "@store/store-helper";
import { removeIElement } from "@faro-lotv/project-source";
import { UploadedFile } from "@custom-types/file-upload-types";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AnnotationEvents } from "@utils/track-event/track-event-list";

interface UpdateMarkupStatus extends BaseMarkupProps {
  /** The new status to apply to the markup */
  newStatus: AnnotationStatus | undefined;

  /** The template id of the status */
  statusTemplateId: string;
}

export interface AddAttachmentToMarkup extends BaseMarkupProps {
  /** List of file details that are uploaded */
  uploadedFiles: UploadedFile[];

  /** The attachment group  */
  attachmentGroup?: IElement;
}

interface UseProjectMarkupUpdate {
  /**
   * Updates the status of a markup.
   *
   * @param updateDetails Object containing details for updating the markup status.
   * @param updateDetails.markup The markup whose status needs to be updated.
   * @param updateDetails.newStatus The new status to apply to the markup.
   * @param updateDetails.statusTemplateId The template id of the status.
   */
  updateMarkupStatus: ({
    markup,
    newStatus,
    statusTemplateId,
  }: UpdateMarkupStatus) => Promise<Mutation[] | undefined>;

  /**
   * Add or update a new member as an assignee for the markup.
   *
   * @param markup The markup to which the new member is being added.
   * @param newMember Details of the new member being added.
   * @param assigneeTemplateId The template id for the assignee field.
   */
  updateNewMember: (
    markup: Markup,
    newMember: SphereDashboardAPITypes.IProjectMemberBase,
    assigneeTemplateId: string
  ) => Promise<
    | MutationUserDirectoryMarkupField
    | MutationAddMarkupField<IElementUserDirectoryMarkupField>
    | undefined
  >;

  /**
   * Removes a member assigned to an annotation markup.
   * This function handles the removal of a member assigned to an annotation markup.
   *
   * @param assigneeId The ID of the assignee to be removed.
   */
  removeMember: (assigneeId: string) => Promise<void>;

  /** Add new attachment to the markup */
  addAttachmentsToMarkup: (
    params: AddAttachmentToMarkup
  ) => Promise<MutationAddAttachment[] | undefined>;

  /** Delete attachment to the markup */
  deleteAttachmentToMarkup: (
    attachment: IElementAttachment
  ) => Promise<MutationDeleteElement | undefined>;
}

/** Custom hook that takes care of fetching all markup elements and other associated elements */
export function useProjectMarkupUpdate({
  projectId,
}: BaseProjectIdProps): UseProjectMarkupUpdate {
  const dispatch = useAppDispatch();
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();

  /**
   * Updates the status of a markup
   */
  async function updateMarkupStatus({
    markup,
    newStatus,
    statusTemplateId,
  }: UpdateMarkupStatus): Promise<Mutation[] | undefined> {
    try {
      // If the new status is "unclassified", for mutation the value should undefined to create delete mutation
      if (newStatus === AnnotationStatus.Unclassified) {
        newStatus = undefined;
      }

      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: { property: "status", isValueEmpty: !newStatus },
      });

      const mutations = createEditStatusMutation(
        markup,
        newStatus,
        markup.status?.id,
        statusTemplateId
      );

      await projectApiClient.applyMutations(mutations);

      return mutations;
    } catch (error) {
      handleErrorWithToast({
        id: `updateAnnotationStatus-${Date.now().toString()}`,
        title: "Could not change annotation status. Please try again",
        error: getErrorDisplayMarkup(error),
      });
    }
  }

  /**
   * Handles the removal of a member assigned to an annotation markup.
   *
   * @param assigneeId The ID of the assignee to be removed.
   * @returns A Promise that resolves when the member is successfully removed.
   */
  async function removeMember(assigneeId: string): Promise<void> {
    try {
      const removeMember = createMutationDeleteElement(assigneeId);

      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: { property: "assignee", isValueEmpty: true },
      });

      // Delete the assignee element using projectApiClient
      await projectApiClient.applyMutations([removeMember]);
    } catch (error) {
      handleErrorWithToast({
        id: `deleteAnnotationAssignee-${Date.now().toString()}`,
        title: "Could not delete the annotation assignee. Please try again",
        error: getErrorDisplayMarkup(error),
      });
    }
  }

  /**
   * Adds a new member as an assignee for the markup.
   */
  async function updateNewMember(
    markup: Markup,
    newMember: SphereDashboardAPITypes.IProjectMemberBase,
    assigneeTemplateId: string
  ): Promise<
    | MutationUserDirectoryMarkupField
    | MutationAddMarkupField<IElementUserDirectoryMarkupField>
    | undefined
  > {
    try {
      let mutation:
        | MutationUserDirectoryMarkupField
        | MutationAddMarkupField<IElementUserDirectoryMarkupField>;

      trackEvent({
        name: AnnotationEvents.editAnnotation,
        props: { property: "assignee", isValueEmpty: false },
      });

      // Edit previous value if it exists
      if (typeof markup.assignee !== "undefined") {
        mutation = createMutationUserDirectoryMarkupField(markup.assignee.id, [
          newMember.identity,
        ]);

        await projectApiClient.applyMutations([mutation]);
      } else {
        mutation =
          createMutationAddMarkupField<IElementUserDirectoryMarkupField>(
            markup.id,
            {
              id: generateGUID(),
              childrenIds: null,
              name: "Assignee",
              parentId: markup.id,
              rootId: markup.rootId,
              templateId: assigneeTemplateId,
              type: IElementType.userDirectoryMarkupField,
              typeHint: IElementTypeHint.markupAssigneeId,
              values: [newMember.identity],
            }
          );

        // Create a new node if it did not exists
        await projectApiClient.applyMutations([mutation]);
      }

      return mutation;
    } catch (error) {
      handleErrorWithToast({
        id: `updateAnnotationAssignee-${Date.now().toString()}`,
        title: "Could not update the annotation assignee. Please try again",
        error: getErrorDisplayMarkup(error),
      });
    }
  }

  /**  Adds attachments to a markup */
  async function addAttachmentsToMarkup({
    uploadedFiles,
    markup,
    attachmentGroup,
  }: AddAttachmentToMarkup): Promise<MutationAddAttachment[] | undefined> {
    try {
      const mutations = uploadedFiles.map((uploadedFile) => {
        const newAttachment: NewAttachment = {
          id: generateGUID(),
          fileName: uploadedFile.fileName,
          downloadUrl: uploadedFile.downloadUrl,
          md5: uploadedFile.md5,
          fileSize: uploadedFile.fileSize,
          date: new Date().toISOString(),
          urlOrFile: uploadedFile.downloadUrl,
        };

        return createAttachmentMutation(
          markup.rootId,
          markup.id,
          newAttachment,
          attachmentGroup?.id
        );
      });

      await projectApiClient.applyMutations(mutations);

      return mutations;
    } catch (error) {
      handleErrorWithToast({
        id: `addAttachmentsToMarkup-${Date.now().toString()}`,
        title: "Could not add attachment(s). Please try again",
        error: getErrorDisplayMarkup(error),
      });
    }
  }

  /**  Delete attachment to a markup */
  async function deleteAttachmentToMarkup(
    attachment: IElementAttachment
  ): Promise<MutationDeleteElement | undefined> {
    try {
      const deleteAttachment = createMutationDeleteElement(attachment.id);

      await projectApiClient.applyMutations([deleteAttachment]);

      await dispatch(removeIElement(attachment.id));

      return deleteAttachment;
    } catch (error) {
      handleErrorWithToast({
        id: `deleteAttachmentImage-${Date.now().toString()}`,
        title: "Could not delete the attachment. Please try again",
        error: error,
      });
    }
  }

  return {
    updateMarkupStatus,
    updateNewMember,
    removeMember,
    addAttachmentsToMarkup,
    deleteAttachmentToMarkup,
  };
}
