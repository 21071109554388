import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { BackgroundTasksTableHeaders } from "@pages/project-details/project-bg-tasks/project-background-tasks-table-utils";
import { SdbBackgroundTask } from "@custom-types/sdb-background-tasks-types";
import { DASH } from "@utils/ui-utils";
import { BackgroundTaskStatus } from "@pages/project-details/project-bg-tasks/background-task-status";
import { BackgroundTaskIcon } from "@pages/project-details/project-bg-tasks/background-task-icon";
import { BackgroundTaskFileName } from "@pages/project-details/project-bg-tasks/background-task-file-name";
import { BackgroundTaskProgress } from "@pages/project-details/project-bg-tasks/background-task-progress";
import { BackgroundTaskActions } from "@pages/project-details/project-bg-tasks/background-task-actions";
import { FormatDate } from "@hooks/use-date-time";
import { DateTime } from "luxon";

/** Return all the possible columns for Project Background Tasks table */
export function getProjectBackgroundTasksColumns(
  formatDate: FormatDate
): Record<BackgroundTasksTableHeaders, GridColDef> {
  return {
    [BackgroundTasksTableHeaders.icon]: {
      field: BackgroundTasksTableHeaders.icon,
      width: 50,
      renderCell: (
        params: GridRenderCellParams<{ entity: SdbBackgroundTask }>
      ) => {
        return <BackgroundTaskIcon task={params.row.entity} />;
      },
    },

    [BackgroundTasksTableHeaders.fileName]: {
      field: BackgroundTasksTableHeaders.fileName,
      minWidth: 180,
      flex: 2,
      renderCell: (
        params: GridRenderCellParams<{ entity: SdbBackgroundTask }>
      ) => {
        return <BackgroundTaskFileName task={params.row.entity} />;
      },
    },

    [BackgroundTasksTableHeaders.status]: {
      field: BackgroundTasksTableHeaders.status,
      minWidth: 150,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<{ entity: SdbBackgroundTask }>
      ) => {
        return <BackgroundTaskStatus task={params.row.entity} />;
      },
    },

    [BackgroundTasksTableHeaders.progress]: {
      field: BackgroundTasksTableHeaders.progress,
      minWidth: 130,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<{ entity: SdbBackgroundTask }>
      ) => {
        return (
          <BackgroundTaskProgress taskProgress={params.row.entity.progress} />
        );
      },
    },

    [BackgroundTasksTableHeaders.created]: {
      field: BackgroundTasksTableHeaders.created,
      minWidth: 130,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<{ entity: SdbBackgroundTask }>
      ) => {
        return (
          <var>
            {formatDate(params.row.entity.createdAt, DateTime.DATETIME_MED)}
          </var>
        );
      },
    },

    [BackgroundTasksTableHeaders.updated]: {
      field: BackgroundTasksTableHeaders.updated,
      minWidth: 130,
      flex: 1,
      renderCell: (
        params: GridRenderCellParams<{ entity: SdbBackgroundTask }>
      ) => {
        return params.row.entity.updatedAt ? (
          <var>
            {formatDate(params.row.entity.updatedAt, DateTime.DATETIME_MED)}
          </var>
        ) : (
          DASH
        );
      },
    },

    [BackgroundTasksTableHeaders.options]: {
      field: BackgroundTasksTableHeaders.options,
      type: "actions",
      align: "right",
      width: 50,
      renderCell: (
        params: GridRenderCellParams<{ entity: SdbBackgroundTask }>
      ) => {
        return <BackgroundTaskActions task={params.row.entity} />;
      },
    },
  };
}

/**
 * The columns required for the Project Background Tasks table
 */
export const PROJECT_BACKGROUND_TASKS_REQUIRED_COLUMNS = [
  BackgroundTasksTableHeaders.icon,
  BackgroundTasksTableHeaders.fileName,
  BackgroundTasksTableHeaders.status,
  BackgroundTasksTableHeaders.progress,
  BackgroundTasksTableHeaders.created,
  BackgroundTasksTableHeaders.updated,
  BackgroundTasksTableHeaders.options,
];
