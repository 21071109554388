import { ValueLabelItem } from "@custom-types/types";
import { useEffect, useState } from "react";

/** Time frame to show analytics in months */
export type TimeFrameItem = ValueLabelItem<number>;

/** Time frames to show analytics with value in months and display label */
export const TIME_FRAMES: TimeFrameItem[] = [
  { value: 3, label: "Last 3 months" },
  { value: 6, label: "Last 6 months" },
  { value: 12, label: "Last 12 months" },
  { value: 24, label: "Last 2 years" },
  { value: 36, label: "Last 3 years" },
];

/** Available time frames to show analytics in months */
export const AVAILABLE_TIME_FRAMES = TIME_FRAMES.map(
  (timeFrame) => timeFrame.value
);

/**
 * Debounces a value with the specified delay.
 *
 * @param value The value to be debounced.
 * @param delay The delay in milliseconds.
 * @param debouncingStart Optional callback that will be triggered immediately before the debouncing starts.
 * @param debouncingEnd Optional callback that will be triggered immediately after debouncing ends.
 * @returns The debounced value.
 */
export function useDebounce(
  value: string,
  delay: number,
  debouncingStart?: () => void,
  debouncingEnd?: () => void
): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  /**
   * This debouncing hook is used to delay the execution of a function until a certain amount of time has passed
   * without any further invocations. This should be used to optimize performance by reducing the number of
   * function calls, particularly in scenarios where frequent updates or user interactions trigger the function.
   */
  useEffect(() => {
    debouncingStart && debouncingStart();
    const timer = setTimeout(() => {
      // Triggers the update to the value, this point is reached only if the timeout
      // wasn't stopped in the cleanup function.
      setDebouncedValue(value);
      debouncingEnd && debouncingEnd();
    }, delay);

    // This returned function is called cleanup. cleanup is run before the effect is reached which cleans the timeout
    // if there's another change to the value, preventing triggering the update.
    return () => {
      clearTimeout(timer);
    };
    // Do not include debouncingStart and debouncingEnd as dependencies, if they are included or if the dependencies
    // array is removed, the useEffect it is called indefinitely.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, delay]);

  return debouncedValue;
}

/**
 * Returns a delay as a Promise that can be awaited. This helps to wait for a certain amount of time in the code
 *
 * @param ms The delay in milliseconds
 * @returns A Promise that resolves after the specified delay
 */
export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
