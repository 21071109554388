import { AccessLevelDisplayNames } from "@custom-types/project-types";
import { Box } from "@mui/material";
import { AccessLevelLDescription } from "@components/project/project-access-level-utils";
import { LabelWithHelp } from "@components/common/label-with-help";
import { ProjectHeaders } from "@components/table/projects/projects-table-utils";
import { sphereColors } from "@styles/common-colors";

/** Tooltip for access level component */
export function ProjectAccessLevelTooltip(): JSX.Element {
  return (
    <LabelWithHelp
      title={ProjectHeaders.accessLevel}
      help={
        <Box component="div">
          <Box component="div" mb="20px">
            There are two types of access levels:
          </Box>
          <Box component="div" mb="20px">
            {`${AccessLevelDisplayNames.private} - ${AccessLevelLDescription.private}`}
          </Box>
          <Box component="div">
            {`${AccessLevelDisplayNames.unlisted} - ${AccessLevelLDescription.unlisted}`}
          </Box>
        </Box>
      }
      sx={{
        color: sphereColors.gray500,
        fontWeight: "normal",
        width: "fit-content",
        fontSize: "11px",
      }}
    />
  );
}
