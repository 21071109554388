import { Link } from "@mui/material";
import { REQUEST_A_QUOTE_URL } from "@src/constants/subscription-constants";
import { sphereColors } from "@styles/common-colors";
import { OPEN_OTHERS_TARGET_ATTRIBUTE } from "@utils/project-utils";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCallback } from "react";

interface Props {
  /** Subscription name */
  name: string;
}

/** Subtitle of the subscription settings with contact info of the sales team */
export function SubscriptionInfoSubtitle({ name }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();

  const onRequestAQuote = useCallback((
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    event.preventDefault();
    event.stopPropagation();
    trackEvent({
      name: WorkspaceEvents.requestAQuote,
    });
    window.open(
      REQUEST_A_QUOTE_URL,
      OPEN_OTHERS_TARGET_ATTRIBUTE
    );
  }, [trackEvent]);

  return (
    <>
      {`You are subscribed to the ${name} plan. `}
      <Link
        href={REQUEST_A_QUOTE_URL}
        onClick={onRequestAQuote}
        underline="none"
        sx={{
          fontSize: "12px",
          fontWeight: 600,
          color: sphereColors.gray800,
          textDecoration: "underline",
        }}
      >
        Upgrade plan or request a quote
      </Link>
    </>
  );
}
