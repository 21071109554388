import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { SphereDropzone } from "@components/common/sphere-dropzone/sphere-dropzone";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { Alert } from "@faro-lotv/flat-ui";
import { Grid, Stack } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { getFilesWithDuplicateNames, sortFiles } from "@utils/file-utils";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import UploadSvg from "@assets/icons/new/upload_50px.svg?react";
import { UploadElementType } from "@custom-types/file-upload-types";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useAppSelector } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { ScanDataFile } from "@pages/project-details/project-data-management/import-data/scan-data-file";
import { ImportDataButton } from "@pages/project-details/project-data-management/import-data/import-data-button";
import { createRevisionForElsScans } from "@pages/project-details/project-data-management/import-data/create-revision-for-els-scans";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import {
  ALLOWED_EXTENSIONS_ALL,
  AutoStartUpload,
  filesInfoForTracking,
  getConfirmDisabled,
  getScansAlreadyUploaded,
  isGLS,
  isValidGlsFile,
  MAX_FILE_SIZE_IN_MB,
} from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { LsDataV2Package, ReadLsDataV2Response } from "@api/stagingarea-api/stagingarea-api-types";
import { getLsDataV2Package, getScanByFilename } from "@api/stagingarea-api/stagingarea-api";
import { useStagingAreaApiClient } from "@api/stagingarea-api/use-stagingarea-api-client";
import { useOnUploadComplete } from "@hooks/data-management/use-on-upload-complete";
import { useInitiateMultipleFileUpload } from "@hooks/data-management/use-initiate-multiple-file-upload";

interface Props extends BaseProjectIdProps {
  uploadedIdsMap: { [key: string]: boolean };
  /** Flag whether the upload dialog is open. */
  isUploadDialogOpen: boolean;
  /** Setter for showing or hiding the upload dialog. */
  setIsUploadDialogOpen(isUploadDialogOpen: boolean): void;
  /** Flag to show the ImportDataButton. */
  isUploadBtnVisible: boolean;
  /** Flag to disable the ImportDataButton. */
  isUploadBtnDisabled: boolean;
  /** The tooltip of the ImportDataButton if isUploadBtnDisabled is set to true. */
  uploadBtnDisabledTooltip?: string;
}

/** Renders a button and the dialog to import ELS scan data. */
export function ImportData({
  projectId,
  uploadedIdsMap,
  isUploadDialogOpen,
  setIsUploadDialogOpen,
  isUploadBtnVisible,
  isUploadBtnDisabled,
  uploadBtnDisabledTooltip,
}: Props): JSX.Element {
  const project = useAppSelector(selectedProjectSelector);
  const { handleErrorWithToast } = useErrorContext();
  const stagingAreaApi = useStagingAreaApiClient({ projectId });
  const { trackEvent } = useTrackEvent();

  /** The selected *.gls files to upload. */
  const [files, setFiles] = useState<File[]>([]);
  /** The selected scan metadata files, with validity information. */
  const [lsDataV2Files, setLsDataV2Files] = useState<LsDataV2Package | null>(null);
  /** The parsed scan metadata, if available. */
  const [lsDataV2, setLsDataV2] = useState<ReadLsDataV2Response | null>(null);
  /** True while creating the revision for the upload. */
  const [isCreatingRevision, setIsCreatingRevision] = useState<boolean>(false);

  /** Set to true to run useEffect() -> onConfirm() once after reading valid LsDataV2 metadata. */
  const autoStartUpload = useRef<AutoStartUpload | null>(null);

  const filesDuplicate = useMemo(() => {
    return getFilesWithDuplicateNames(files);
  }, [files]);

  const scansAlreadyUploaded = useMemo(() => {
    return getScansAlreadyUploaded(files, lsDataV2, uploadedIdsMap);
  }, [files, lsDataV2, uploadedIdsMap]);

  const haveAllScansMetadata = useMemo(() => {
    return !!lsDataV2 && files.every((file) => getScanByFilename(file.name, lsDataV2));
  }, [files, lsDataV2]);

  const isConfirmDisabled = useMemo(() => {
    return getConfirmDisabled(files, filesDuplicate, lsDataV2Files, lsDataV2, scansAlreadyUploaded);
  }, [files, filesDuplicate, lsDataV2Files, lsDataV2, scansAlreadyUploaded]);

  const onUploadComplete = useOnUploadComplete(project);
  const initiateMultipleFileUpload = useInitiateMultipleFileUpload(project);

  const closeDialog = useCallback(() => {
    setIsUploadDialogOpen(false);

    autoStartUpload.current = null;
    setFiles([]);
    setLsDataV2Files(null);
    setLsDataV2(null);
  }, [setIsUploadDialogOpen]);

  const onConfirm = useCallback(async (): Promise<void> => {
    autoStartUpload.current = null;
    setIsCreatingRevision(true);

    try {
      const { registrationRevisionId, revisionClusterEntityId } = await createRevisionForElsScans(projectId);
      initiateMultipleFileUpload(registrationRevisionId, revisionClusterEntityId, files, lsDataV2, scansAlreadyUploaded);
      closeDialog();
    } catch (error) {
      handleErrorWithToast({
        id: `createRevisionForElsScans-${Date.now().toString()}`,
        title: "Failed to prepare a revision to import data. Please try again.",
        error,
      });
    }

    setIsCreatingRevision(false);
  }, [projectId, initiateMultipleFileUpload, files, lsDataV2, scansAlreadyUploaded, closeDialog, handleErrorWithToast]);

  /** Auto-confirm the upload if the folder is valid, and each *.gls file is referenced in the LsDataV2 metadata. */
  useEffect(() => {
    if (isUploadDialogOpen && !isConfirmDisabled && haveAllScansMetadata && autoStartUpload.current === AutoStartUpload.start) {
      if (scansAlreadyUploaded.size > 0) {
        // If some selected scans were already uploaded before, better let the user double-check.
        autoStartUpload.current = null;
      } else {
        onConfirm();
      }
    }
  }, [
    files, lsDataV2, haveAllScansMetadata, scansAlreadyUploaded, isConfirmDisabled,
    isUploadDialogOpen, onConfirm, autoStartUpload,
  ]);

  /**
   * Try to read the LsDataV2 package, and enable the "Confirm" button if successful.
   * If the data of the selected folder appears fully consistent, `autoStartUpload` will automatically
   * start the upload.
   */
  function readLsDataV2Background(lsDataFiles: LsDataV2Package): void {
    autoStartUpload.current = AutoStartUpload.waiting;

    stagingAreaApi.postReadLsDataV2(lsDataFiles.files).then((lsData) => {
      setLsDataV2(lsData);
      // Only try to auto-confirm the upload if no files were added or removed in the meantime.
      if (autoStartUpload.current === AutoStartUpload.waiting) {
        autoStartUpload.current = AutoStartUpload.start;
      }
    }).catch((error) => {
      autoStartUpload.current = null;
      setLsDataV2Files({ ...lsDataFiles, isValid: false });
      setLsDataV2(null);
      handleErrorWithToast({
        id: `readLsDataV2-${Date.now().toString()}`,
        title: "Failed to read scan metadata. Pre-registration and scan names won't be used.",
        error,
      });
    });
  }

  /** Set the files for upload, replacing any previous selected ones. We allow to upload a single ELS folder at once. */
  function onSelectFiles(
    selectedFiles: FileList | File[],
    _: () => void
  ): void {
    autoStartUpload.current = null;
    selectedFiles = [...selectedFiles];
    const selectedGlsFiles = selectedFiles.filter((file) => isGLS(file.name));
    setFiles(sortFiles(selectedGlsFiles));

    const selectedLsDataV2Files = getLsDataV2Package(selectedFiles);
    setLsDataV2Files(selectedLsDataV2Files);
    setLsDataV2(null);
    if (selectedLsDataV2Files?.isValid) {
      readLsDataV2Background(selectedLsDataV2Files);
    }

    trackEvent({
      name: DataManagementEvents.selectFiles,
      props: filesInfoForTracking(selectedFiles),
    });
  }

  function removeFile(file: File): void {
    autoStartUpload.current = null;
    const allFiles = [...files];
    const index = allFiles.indexOf(file);
    if (index >= 0) {
      allFiles.splice(index, 1);
    }
    // When the last scan is removed, also remove the "Scan Metadata" UI element.
    if (!allFiles.length) {
      setLsDataV2Files(null);
      setLsDataV2(null);
    }
    setFiles(sortFiles(allFiles));
  }

  function removeMetaData(): void {
    autoStartUpload.current = null;
    setLsDataV2Files(null);
    setLsDataV2(null);
  }

  return (
    <>
      {isUploadBtnVisible && (
        <ImportDataButton
          isDisabled={isUploadBtnDisabled}
          disabledTooltip={uploadBtnDisabledTooltip}
          onClick={() => setIsUploadDialogOpen(true)}
        />
      )}
      <FaroDialog
        title="Upload data"
        confirmText="Confirm"
        open={isUploadDialogOpen}
        onConfirm={onConfirm}
        isConfirmDisabled={isConfirmDisabled}
        isConfirmLoading={isCreatingRevision || (!!lsDataV2Files?.isValid && !lsDataV2)}
        onClose={closeDialog}
      >
        <Grid maxWidth="100%" width="70vw">
          <Alert
            title='Please upload the folder with raw Blink data (.gls) which contains a file called "index-v2".'
            variant="info"
            sx={{
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
              backgroundColor: sphereColors.blue100,
            }}
          />
          {(files.length > 0 && (!lsDataV2Files || (!!lsDataV2 && !haveAllScansMetadata))) &&
            <Alert
              title="Please upload a full Blink data folder to ensure the best registration results."
              variant="warning"
              sx={{
                marginBottom: SPACE_ELEMENTS_OF_MODAL,
                backgroundColor: sphereColors.yellow200,
              }}
            />
          }
          <Stack>
            <SphereDropzone
              instruction="Drag & drop"
              maxFileSize={MAX_FILE_SIZE_IN_MB}
              shouldShowSupportedFormats={false}
              shouldShowSizeLimit={false}
              shouldAllowMultiUpload={true}
              shouldAllowFolderUpload={true}
              shouldAllowFiles={false}
              avatar={
                <SphereAvatar
                  icon={<UploadSvg />}
                  size="x-large"
                  shouldHideWhiteRim
                  iconColor={sphereColors.gray600}
                  backgroundColor={sphereColors.gray100}
                />
              }
              allowedExtensions={ALLOWED_EXTENSIONS_ALL}
              isLoading={false}
              setIsLoading={() => undefined}
              onUploadComplete={onUploadComplete}
              context={{
                uploadElementType: UploadElementType.elsScan,
                projectId: projectId ?? "",
                registrationRevisionId: "",
                revisionClusterEntityId: "",
                lsDataV2,
              }}
              onSelectFiles={onSelectFiles}
            />
          </Stack>

          {(!!lsDataV2 || files.length > 0) && (
            <Stack
              sx={{
                my: SPACE_ELEMENTS_OF_MODAL,
                maxHeight: "200px",
                overflow: "auto",
              }}
            >
              {!!lsDataV2Files &&
                <ScanDataFile
                  fileTitle="Scan Metadata"
                  fileName="index-v2"
                  fileSize={lsDataV2Files.size}
                  onDelete={removeMetaData}
                  isValid={lsDataV2Files.isValid}
                />
              }
              {files.map((file, index) => (
                <ScanDataFile
                  key={index}
                  fileName={getScanByFilename(file.name, lsDataV2)?.name || file.name}
                  fileSize={file.size}
                  isExistingScan={scansAlreadyUploaded.has(file)}
                  onDelete={() => removeFile(file)}
                  isValid={isValidGlsFile(file, lsDataV2) && !filesDuplicate.has(file)}
                />
              ))}
            </Stack>
          )}
        </Grid>
      </FaroDialog>
    </>
  );
}
