import { useAppSelector } from "@store/store-helper";
import { selectedProjectSelector } from "@store/projects/projects-selector";
import { SdbProject } from "@custom-types/project-types";
import { RequiredPermissionProjectLevelName } from "@utils/permission-control/project-permission-control-types";
import { hasUserValidPermissionProjectLevel } from "@utils/permission-control/project-permission-control";
import { useCallback, useMemo } from "react";

interface Props {
  /** The internal name used in the UI to determine certain permission */
  permissionName: RequiredPermissionProjectLevelName;

  /** The project to get the user's permission level. */
  project?: SdbProject | null;
}

export type ReturnProps = {
  [key in RequiredPermissionProjectLevelName]: boolean;
} & {
  /** Wrapper to check if user has a permission to do given action (by permission name) or not */
  hasUserPermissionProjectLevel: ({
    permissionName,
    project,
  }: Props) => boolean;
};

/** Checks whether a user has permission for an action on project level or not */
export function useHasUserValidPermissionProjectLevel({
  project = null,
}: Pick<Props, "project"> = {}): ReturnProps {
  const selectedProjectInStore = useAppSelector(selectedProjectSelector);

  /** If user provided a project, then use that. Otherwise, use the selectedProject */
  const selectedProject = useMemo(
    () => project || selectedProjectInStore,
    [project, selectedProjectInStore]
  );

  const canChangeRole = useMemo(
    () =>
      hasUserValidPermissionProjectLevel({
        permissionName: RequiredPermissionProjectLevelName.canChangeRole,
        selectedProject,
      }),
    [selectedProject]
  );

  const hasUserPermissionProjectLevel = useCallback(
    ({ permissionName, project = null }: Props) =>
      hasUserValidPermissionProjectLevel({
        permissionName,
        selectedProject: project,
      }),
    []
  );

  return {
    canChangeRole,
    hasUserPermissionProjectLevel,
  };
}
