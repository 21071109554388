import { getPreservedQueryParams } from "@router/router-helper";
import { useLocation } from "react-router-dom";

interface Props {
  /** The pathname or route to navigate to */
  pathname: string;

  /** The search string (query parameters) that comes after the '?' in the URL */
  search: string;
}
/**
 *  This hook is used to preserve the search query parameters when navigating to a new page.
 * @param to  The path to navigate to.
 * @returns  An object containing the pathname and search string.
 */
export function usePreservedSearch(path: string): Props {
  const location = useLocation();
  const preservedSearch = getPreservedQueryParams(location.search);
  const cleanedPath = path.split("?")[0];

  return {
    pathname: cleanedPath,
    search: preservedSearch,
  };
}
