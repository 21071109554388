import { FaroTable } from "@components/common/faro-table/faro-table";
import { useDataManagementContext } from "@context-providers/data-management/data-management-context";
import { useDateTime } from "@hooks/use-date-time";
import { getDataManagementTableColumns } from "@pages/project-details/project-data-management/data-management-table-cols";
import { TableHeaders, TableItem } from "@pages/project-details/project-data-management/data-management-types";
import { useAppSelector } from "@store/store-helper";
import { fetchingMembersFlagsSelector } from "@store/members/members-selector";
import { fetchingProjectsFlagsSelector } from "@store/projects/projects-selector";

/** The columns to display in the table */
const COLUMNS: TableHeaders[] = [
  TableHeaders.name,
  TableHeaders.createdBy,
  TableHeaders.createdAt,
  TableHeaders.deviceType,
  TableHeaders.status,
];

interface Props {
  /** List of DataManagementTableItem entities */
  tableItems: TableItem[];

  /** Whether the uploaded data is being fetched for the first time */
  isFetchingForTheFirstTime: boolean;
}

/** Renders the table in the Staging Area that displays the scan items. */
export function DataManagementTable({
  tableItems,
  isFetchingForTheFirstTime,
}: Props): JSX.Element {
  const { companyMembers, projectMembers } = useDataManagementContext();

  const { isFetchingCompanyMembers } = useAppSelector(
    fetchingMembersFlagsSelector
  );
  const { isFetchingProjects: isFetchingProjectMembers } = useAppSelector(
    fetchingProjectsFlagsSelector
  );

  const { formatDate } = useDateTime();

  const isLoading = isFetchingForTheFirstTime || isFetchingCompanyMembers || isFetchingProjectMembers;

  return (
    <FaroTable
      entities={tableItems}
      uniqueIdKey={"id"}
      isLoading={isLoading}
      columns={COLUMNS.map(
        (column) =>
          getDataManagementTableColumns({
            companyMembers,
            projectMembers,
            formatDate,
          })[column]
      )}
      rowHeight={54}
      shouldHideCheckbox={true}
    />
  );
}
