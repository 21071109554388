import { Box } from "@mui/material";
import { MarkupSidePanelInfoItem } from "@pages/project-details/project-markups/sidepanel";
import { MarkupDueDate } from "@pages/project-details/project-markups/due-date/markup-due-date";
import { MarkupStatusDropdown } from "@pages/project-details/project-markups/status/markup-status-dropdown";
import { MarkupAssignee } from "@pages/project-details/project-markups/markup-assignee";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { MarkupIntegration } from "@pages/project-details/project-markups/markup-integration";
import { MarkupDescription } from "@pages/project-details/project-markups/sidepanel/markup-description";
import { MarkupSidePanelTags } from "@pages/project-details/project-markups/sidepanel/markup-sidepanel-tags";

/**
 * This component returns each item that will be inserted in the side panel
 */
export function MarkupsSidePanelInfo({ markup }: BaseMarkupProps): JSX.Element {
  return (
    <>
      <Box
        sx={{
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        <MarkupSidePanelInfoItem
          title={"Sheet"}
          value={<var>{markup?.areaSection?.name || "-"}</var>}
        />
        <MarkupSidePanelInfoItem
          title="Scene"
          value={<var>{markup?.section?.name || "-"}</var>}
        />
        <MarkupSidePanelInfoItem
          title="Assignee"
          value={<MarkupAssignee markup={markup} isSidePanelOpen={true} />}
        />
        <MarkupSidePanelInfoItem
          title="Status"
          value={
            <MarkupStatusDropdown markup={markup} isSidePanelOpen={true} />
          }
        />
        <MarkupSidePanelInfoItem
          title="Due Date"
          value={<MarkupDueDate markup={markup} isSidePanelOpen={true} />}
        />
        <MarkupSidePanelInfoItem
          title="Sync with"
          value={<MarkupIntegration externalMarkup={markup?.externalMarkup} />}
        />
      </Box>

      {markup && <MarkupSidePanelTags labels={markup.labels} />}

      {markup && <MarkupDescription markup={markup} />}
    </>
  );
}
