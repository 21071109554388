import { FileUploadTask } from "@custom-types/file-upload-types";
import { BackgroundTaskState } from "@faro-lotv/service-wires";

/**
 * Max length for file paths to make uploads work consistently, according to our tests in TF-1682:
 * https://dev.azure.com/faro-connect/Stellar/_git/Dashboard/pullrequest/28651
 */
export const MAX_FILE_NAME_LENGTH = 209;

/** @returns True if the task is in-progress, or waiting to start. */
export function isTaskInProgress(task: FileUploadTask): boolean {
  return [
    BackgroundTaskState.created,
    BackgroundTaskState.scheduled,
    BackgroundTaskState.started,
  ].includes(task.status);
}

/** @returns True if the task is completed or aborted (but not failed). */
export function isTaskCompleted(task: FileUploadTask): boolean {
  return [BackgroundTaskState.succeeded, BackgroundTaskState.aborted].includes(
    task.status
  );
}

/** @returns True if all file paths (if available) or file names have length <= maxFileNameLength. */
export function haveFileNamesValidLength(
  files: File[] | FileList,
  maxFileNameLength: number = MAX_FILE_NAME_LENGTH
): boolean {
  for (const file of files) {
    // file.webkitRelativePath is by default an empty string, and only set when using a folder selection dialog.
    const fileName = file.webkitRelativePath || file.name;
    if (fileName.length > maxFileNameLength) {
      return false;
    }
  }
  return true;
}
