import { getAutoHideDuration } from "@context-providers/toast/faro-toast-helper";
import { FaroToastProps } from "@context-providers/toast/faro-toast-types";
import { CustomContentProps, SnackbarKey, useSnackbar } from "notistack";
import { useCallback } from "react";

/** Properties needed when showing a toast */
export type ShowToastProps = FaroToastProps & Pick<CustomContentProps, "message">;

interface UseToast {
  /** Function that calls to show a toast */
  showToast({
    description,
    errorCode,
    onClose,
    actionButton,
    type,
    shouldAutoHide,
  }: ShowToastProps): SnackbarKey;
}

/** Returns a Toast function that will create a toast based on the FaroToast component */
export function useToast(): UseToast {
  const { enqueueSnackbar } = useSnackbar();

  const showToast = useCallback(
    ({
      message,
      description,
      errorCode,
      onClose,
      actionButton,
      type,
      shouldAutoHide,
    }: ShowToastProps): SnackbarKey => {
      return enqueueSnackbar({
        variant: "FaroToast",
        message,
        autoHideDuration: getAutoHideDuration(type, shouldAutoHide),
        description,
        errorCode,
        onClose,
        actionButton,
        type,
      });
    },
    [enqueueSnackbar]
  );

  return { showToast };
}
