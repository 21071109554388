import { useCallback, useEffect, useRef } from "react";
import { useProgressApiClient } from "@api/progress-api/use-progress-api-client";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { resetSdbBackgroundTasksState } from "@store/sdb-background-tasks/sdb-background-tasks-slice";
import { backgroundTasksFetchingStatusSelector } from "@store/sdb-background-tasks/sdb-background-tasks-selector";
import { useProjectApiClient } from "@api/project-api/use-project-api-client";
import { updateBackgroundTasks } from "@store/sdb-background-tasks/sdb-background-tasks-thunk";
import { FetchingStatus } from "@store/store-types";
import { useToast } from "@hooks/use-toast";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import {
  DEFAULT_MAX_ATTEMPTS,
  DEFAULT_POLLING_INTERVAL,
} from "@hooks/use-polling";

interface Props {
  /** Id of the current project */
  projectId: string;
}

interface UseBackgroundTasksTracker {
  /** Whether the background tasks are loading for the first time */
  isLoading: boolean;
}

/**
 * Requests background tasks to the progress API at a regular polling interval and updates the store.
 */
export function useBackgroundTasksTracker({
  projectId,
}: Props): UseBackgroundTasksTracker {
  const isFirstFailedAttempt = useRef<boolean>(true);
  const failedCounterRef = useRef<number>(0);
  const pollingIntervalIdRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const progressApiClient = useProgressApiClient({ projectId });
  const dispatch = useAppDispatch();
  const backgroundTasksFetchingStatus = useAppSelector(
    backgroundTasksFetchingStatusSelector
  );
  const projectApiClient = useProjectApiClient({
    projectId,
  });
  const { handleErrorWithToast } = useErrorContext();
  const { showToast } = useToast();

  // Resets the background tasks store on component unmount
  useEffect(() => {
    return () => {
      dispatch(resetSdbBackgroundTasksState());
    };
  }, [dispatch]);

  /** Stops polling */
  const stopPolling = useCallback(() => {
    clearInterval(pollingIntervalIdRef.current);
    pollingIntervalIdRef.current = undefined;
  }, []);

  /**
   * Regularly fetches the background tasks from the progress API and updates the store.
   *
   * If it succeeds the failed counter is reset.
   *
   * If it throws an exception it increases the failed attempts count and:
   * - Logs the error to sentry if is the 1st failed attempt
   * - Stops polling if failed attempts reach the max attempts
   */
  const fetchBackgroundTasks = useCallback(
    async (shouldOverrideBeforePage?: boolean): Promise<void> => {
      try {
        await dispatch(
          updateBackgroundTasks({
            progressApiClient,
            projectApiClient,
            beforePage: null,
            shouldOverrideBeforePage,
          })
        ).unwrap();

        failedCounterRef.current = 0;
      } catch (error) {
        failedCounterRef.current += 1;

        if (isFirstFailedAttempt.current) {
          isFirstFailedAttempt.current = false;
          showToast({
            message: "Failed to fetch cloud activity tasks",
            type: "error",
          });
        }

        if (failedCounterRef.current >= DEFAULT_MAX_ATTEMPTS) {
          stopPolling();
          handleErrorWithToast({
            id: `fetchBackgroundTasks-${Date.now().toString()}`,
            title:
              "Failed to fetch activity tasks after multiple attempts. Please reload the application to try again.",
            error,
          });
        }
      }
    },
    [
      dispatch,
      handleErrorWithToast,
      progressApiClient,
      projectApiClient,
      showToast,
      stopPolling,
    ]
  );

  /**
   * Executes fetchBackgroundTasks the very 1st time on component mount
   * then it polls fetchBackgroundTasks by the given delta time.
   * It also stops the polling on component unmount.
   */
  useEffect(() => {
    if (!pollingIntervalIdRef.current) {
      fetchBackgroundTasks(true);
    }

    // Don't set the beforePage token after polling the background tasks, because we might be on a different page
    // and want to keep the current page token
    pollingIntervalIdRef.current = setInterval(
      () => fetchBackgroundTasks(false),
      DEFAULT_POLLING_INTERVAL
    );

    return () => stopPolling();
  }, [fetchBackgroundTasks, stopPolling]);

  return {
    isLoading:
      backgroundTasksFetchingStatus === FetchingStatus.uninitialized ||
      backgroundTasksFetchingStatus === FetchingStatus.pending,
  };
}
