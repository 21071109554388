import { Box, Typography } from "@mui/material";
import NoIntegrationIcon from "@assets/icons/integrations/no-integration_48px.svg?react";
import { sphereColors } from "@styles/common-colors";

interface Props {
  /** The subject of the integration */
  subject: "workspace" | "project";
}

/** Renders an empty component if there is no integrations found */
export function NoIntegration({ subject }: Props): JSX.Element {
  return (
    <Box
      component="div"
      sx={{
        mt: "25px",
        height: "210px",
        width: "100%",
        backgroundColor: sphereColors.gray50,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: 1,
      }}
    >
      <NoIntegrationIcon />
      <Typography
        sx={{
          color: sphereColors.gray800,
          fontSize: "16px",
          lineHeight: "24px",
          fontWeight: "600",
        }}
      >
        {subject === "workspace"
          ? "No integrations enabled for this workspace"
          : "No integrations connected to this project"}
      </Typography>
    </Box>
  );
}
