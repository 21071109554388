import { FontWeights } from "@faro-lotv/flat-ui";
import { Box } from "@mui/material";

/** Tooltip title text */
const TOOLTIP_TITLE = "Switch back?";

/** Tooltip content text */
const TOOLTIP_CONTENT = `By clicking here you will exit the new Dashboard. 
Before you switch, if you could share why you’re leaving we will appreciate. Your feedback helps us improve!`;

/** Renders the tooltip for the HoloBuilder sidebar item */
export function SidebarHolobuilderTooltip(): JSX.Element {
  return (
    <Box
      sx={{
        padding: "12px",
      }}
    >
      <Box
        sx={{
          mb: "10px",

          fontWeight: FontWeights.Bold,
          letterSpacing: "0.48px",
          textTransform: "uppercase",
        }}
      >
        {TOOLTIP_TITLE}
      </Box>
      <Box
        sx={{
          mb: "10px",
        }}
      >
        {TOOLTIP_CONTENT}
      </Box>
    </Box>
  );
}
