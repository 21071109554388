import { FaroTextField } from "@components/common/faro-text-field/faro-text-field";
import { FormControl, SxProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import { CSSProperties } from "react";
import { useMediaQueryList } from "@hooks/use-media-query";
import { BaseProjectProps } from "@custom-types/project-types";
import { ProjectAccessLevelTooltip } from "@components/project/project-access-level-tooltip";
import { getAccessLevelValue } from "@components/project/project-access-level-utils";
import { ProjectAccessLevelDropdown } from "@components/project/project-access-level-dropdown";
import { FaroTextMenuButton } from "@components/common/faro-text-menu-button";
import { useOnAccessLevelChange } from "@hooks/projects/use-on-access-level-change";

/** Component width */
const ACCESS_LEVEL_COMPONENT_WIDTH: CSSProperties["width"] = "225px";

interface Props extends BaseProjectProps {
  /** Whether the project access level is editable or not */
  isAccessLevelEditable: boolean;

  /** Optional style props to pass to the parent component */
  sx?: SxProps;
}

/**
 * Project access level selection component
 * If the user has permission to change the access level it renders a custom dropdown,
 * otherwise it shows the access level as readonly text.
 */
export function ProjectAccessLevel({
  isAccessLevelEditable,
  project,
  sx,
}: Props): JSX.Element {
  const { isExtraSmall } = useMediaQueryList();
  const { onAccessLevelChange } = useOnAccessLevelChange();

  return (
    <FormControl
      sx={{
        width: "100%",
        ...sx,
      }}
    >
      <ProjectAccessLevelTooltip />
      {isAccessLevelEditable ? (
        <FaroTextMenuButton
          buttonTitle={getAccessLevelValue(project.accessLevel)}
          buttonSx={{
            color: sphereColors.gray800,
            fontSize: DEFAULT_INPUT_FONT_SIZE,
            // For extra-small screens show the component in full width
            width: isExtraSmall ? "100%" : ACCESS_LEVEL_COMPONENT_WIDTH,
            textOverflow: "ellipsis",
            justifyContent: "space-between",

            // Not focused
            "&:not(&:focus)": {
              // Hide the underline and icon by default
              "&:before": {
                border: "none",
              },
              "& .MuiButton-icon": {
                display: "none",
              },

              // Hover style. Show underline and icon
              "&:hover": {
                boxShadow: `0px 1px 0px ${sphereColors.gray400}`,

                "& .MuiButton-icon": {
                  display: "block",
                },
              },
            },

            // Focused
            "&:focus": {
              borderBottom: "2px solid",
              borderBottomColor: sphereColors.blue500,
            },
          }}
        >
          <ProjectAccessLevelDropdown
            projectAccessLevel={project.accessLevel}
            onAccessLevelChange={onAccessLevelChange}
          />
        </FaroTextMenuButton>
      ) : (
        <FaroTextField
          initialValue={getAccessLevelValue(project.accessLevel)}
          sx={{
            width: ACCESS_LEVEL_COMPONENT_WIDTH,
          }}
        />
      )}
    </FormControl>
  );
}
