import { useCallback, useEffect, useMemo } from "react";
import { IReactChildrenOnly } from "@custom-types/types";
import { useLocation, useNavigate } from "react-router-dom";
import { QueryParams } from "@router/route-params";
import { runtimeConfig } from "@src/runtime-config";
import { KeyCombination, keyCombinationsMap } from "@loaders/key-combinations";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { isInternalUser } from "@utils/user-utils";
import { RuntimeUtils } from "@stellar/web-core";

/**
 * Component that listens to key events to trigger certain actions.
 */
export function KeyBindingsLoader({
  children,
}: IReactChildrenOnly): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useAppSelector(currentUserSelector);
  const isProduction = useMemo(
    () => RuntimeUtils.isProductionEnvironment(runtimeConfig.appEnv),
    []
  );

  // Sets a query parameter in the URL, without modification of the existing ones
  const setQueryParams = useCallback(
    (updates: Partial<Record<QueryParams, string>>) => {
      const searchParams = new URLSearchParams(location.search);

      // Update all parameters
      for (const [key, value] of Object.entries(updates)) {
        searchParams.set(key as QueryParams, value);
      }

      // Navigate to updated URL
      navigate({
        search: searchParams.toString(),
      });
    },
    [location.search, navigate]
  );

  /** Checks if a certain key combination was pressed */
  const matchKeyCombination = useCallback(
    (event: KeyboardEvent): KeyCombination | undefined => {
      const key = event.code;
      const combinationKey = event.altKey ? `Alt+${key}` : key;

      const combination = keyCombinationsMap.get(combinationKey);

      // If the combination is only for internal users in prod, and the user is not internal, skip it
      if (
        isProduction &&
        combination?.isOnlyForInternalUsersInProd &&
        !isInternalUser(currentUser?.email ?? "")
      ) {
        return undefined;
      }

      // If the combination should skip prod and we are not in production, skip
      if (combination?.shouldSkipProd && isProduction) {
        return undefined;
      }

      // If the combination has alt key and the event does not have it, skip
      if (combination?.hasAltKey && !event.altKey) {
        return undefined;
      }

      return combination;
    },
    [isProduction, currentUser]
  );

  useEffect(() => {
    function handleKeydown(event: KeyboardEvent): void {
      if (!event.altKey) {
        return;
      }

      const combination = matchKeyCombination(event);

      if (combination) {
        const updates: Partial<Record<QueryParams, string>> = {};
        combination.action((key, value) => {
          updates[key] = value;
        });

        setQueryParams(updates);
      }
    }

    window.addEventListener("keydown", handleKeydown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeydown);
    };
  }, [matchKeyCombination, setQueryParams]);

  // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed for returning JSX.Element
  return <>{children}</>;
}
