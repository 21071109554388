import { Box } from "@mui/material";
import { PointCloudSidePanelInfoItem } from "@pages/project-details/project-data/point-clouds/sidepanel/point-cloud-sidepanel-info-item";
import { PointCloud } from "@custom-types/point-cloud-types";
import { PointCloudDate } from "@pages/project-details/project-data/point-clouds/point-cloud-date";

interface Props {
  /** The point cloud selected for the side panel */
  pointCloud: PointCloud;
}

/**
 * Displays detailed information about a selected point cloud within a side panel.
 *
 * @param {Props} props - The props for the PointCloudSidePanelInfo component.
 * @returns {JSX.Element} A JSX element representing the PointCloudSidePanelInfo component.
 */
export function PointCloudSidePanelInfo({ pointCloud }: Props): JSX.Element {
  return (
    <Box
      component={"div"}
      sx={{
        marginTop: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "12px",
      }}
    >
      <PointCloudSidePanelInfoItem
        title="Creation date"
        value={<PointCloudDate date={pointCloud.createdAt} />}
      />

      {/* TODO:: All the values missed should be done in this ticket: https://faro01.atlassian.net/browse/ST-1790 */}
      <PointCloudSidePanelInfoItem title="Dataset type" value={"-"} />
      <PointCloudSidePanelInfoItem title="Capture device" value={"-"} />
      <PointCloudSidePanelInfoItem title="Size" value={"-"} />

      <Box sx={{ borderBottom: 1, borderColor: "divider", my: "12px" }} />

      <PointCloudSidePanelInfoItem title="Upload date" value={"-"} />
      <PointCloudSidePanelInfoItem title="Upload type" value={"-"} />

      <Box sx={{ borderBottom: 1, borderColor: "divider", my: "12px" }} />

      <PointCloudSidePanelInfoItem title="Preregistration" value={"-"} />
      <PointCloudSidePanelInfoItem
        title="Registration / Alignment"
        value={"-"}
      />
      <PointCloudSidePanelInfoItem title="Additional content" value={"-"} />

      <Box sx={{ borderBottom: 1, borderColor: "divider", my: "12px" }} />

      <PointCloudSidePanelInfoItem title="Publish date" value={"-"} />
      <PointCloudSidePanelInfoItem
        title="Publish on"
        value={<var>{pointCloud.sectionArea?.name}</var>}
      />
      <PointCloudSidePanelInfoItem title="Publish by" value={"-"} />

      {/* Merged point cloud details */}
      {pointCloud.sourcePointCloudsInfo && (
        <>
          <Box sx={{ borderBottom: 1, borderColor: "divider", my: "12px" }} />

          <PointCloudSidePanelInfoItem
            title="Merged from"
            value={
              <>
                {pointCloud.sourcePointCloudsInfo.map((source, index) => {
                  return (
                    <Box
                      key={index}
                      component={"var"}
                      sx={{
                        display: "block",
                      }}
                    >
                      {"- "}
                      {source.name}
                    </Box>
                  );
                })}
              </>
            }
          />
        </>
      )}
    </Box>
  );
}
