import { PageSectionProps } from "@components/page/page-section";
import { BaseSettingsPage } from "@pages/settings/base-settings-page";
import { Subscription } from "@custom-types/subscription-types";
import { useAppSelector } from "@store/store-helper";
import { isFetchingSdbCompanySelector } from "@store/sdb-company/sdb-company-selector";
import { useDateTime } from "@hooks/use-date-time";
import {
  CONSTRAINT_LABEL,
  SUBSCRIPTION_CONSTRAINTS,
  SUBSCRIPTION_NAME,
} from "@src/constants/subscription-constants";
import { SubscriptionConstraint } from "@pages/settings/subscription-settings/subscription-constraint";
import { SubscriptionInfoItem } from "@pages/settings/subscription-settings/subscription-info-item";
import { SubscriptionInfoSubtitle } from "@pages/settings/subscription-settings/subscription-info-subtitle";

interface Props {
  /** Subscription entity */
  subscription: Subscription;
}

/**
 * Shows the subscription details
 */
export function SubscriptionSettings({ subscription }: Props): JSX.Element {
  const isLoading = useAppSelector(isFetchingSdbCompanySelector);
  const { formatDate } = useDateTime();

  const pageSectionsProps: PageSectionProps[] = [
    {
      title: "Current plan",
      subtitle: <SubscriptionInfoSubtitle name={SUBSCRIPTION_NAME[subscription.id]} />,
      items: [
        {
          label: "Subscription plan",
          shouldAddRightPadding: true,
          content: <SubscriptionInfoItem text={SUBSCRIPTION_NAME[subscription.id]} />,
        },
        {
          label: "End date",
          shouldAddRightPadding: true,
          content: <SubscriptionInfoItem text={formatDate(subscription.expiresAt)} shouldNotTranslate />,
        },
      ],
    },
    {
      title: "Usage metrics",
      subtitle:
        // eslint-disable-next-line max-len
        "Your current plan has limits for certain features. This section outlines its usage. You can upgrade your plan at any time to adjust these limits.",
      isHidden: !SUBSCRIPTION_CONSTRAINTS[subscription.id].length,
      items: SUBSCRIPTION_CONSTRAINTS[subscription.id].map((constraint, index) => {
        return {
          label: CONSTRAINT_LABEL[constraint.type],
          shouldAddRightPadding: true,
          content: <SubscriptionConstraint key={index} constraint={constraint} />,
        };
      }),
    },
  ];

  const filteredPageSectionProps = pageSectionsProps.filter(
    (pageSectionProps) => !pageSectionProps.isHidden
  );

  return (
    <BaseSettingsPage
      pageSectionProps={filteredPageSectionProps}
      isLoading={isLoading}
      dataTestId="workspace-settings-subscription"
    />
  );
}
