import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { IntegrationDialogProps } from "@pages/integrations/integrations-types";

/** Shows dialog for disconnecting an integration */
export function IntegrationsDisconnectDialog({
  dialogConfirmCallback,
  dialogCancelCallback,
}: Pick<
  IntegrationDialogProps,
  "dialogConfirmCallback" | "dialogCancelCallback"
>): JSX.Element {
  return (
    <FaroDialog
      open={true}
      onConfirm={dialogConfirmCallback}
      onClose={dialogCancelCallback}
      confirmText="Disable"
      title="Disable integration?"
    >
      Disabling this integration will prevent you from using any of its
      functionality in any of your projects.
    </FaroDialog>
  );
}
