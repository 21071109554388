import { selectedProjectSelector } from "@store/projects/projects-selector";
import { sphereColors } from "@styles/common-colors";
import { MainPageLayout } from "@components/main-page-layout";
import { Grid } from "@mui/material";
import { PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { TitleComponent } from "@components/common/title-component";
import { useAppSelector } from "@store/store-helper";

/** Renders the layout of the project data management page. */
export function DataManagementLayout({
  children,
}: PropsWithChildren): JSX.Element {
  const project = useAppSelector(selectedProjectSelector);

  return (
    <Grid
      container
      sx={{ backgroundColor: sphereColors.gray50 }}
    >
      <MainPageLayout
        shouldShowLogo={false}
        shouldHideOpenButton={true}
        shouldShowUserMenu={true}
        shouldShowSidebar={false}
        shouldShowProgressOverviewMenu={true}
        isShowingStagingArea={true}
        titleComponent={<TitleComponent title={project?.name} />}
        shouldShowTopbarBorder={true}
        contentBackgroundColor={sphereColors.gray50}
      >
        <Grid container sx={{ marginTop: "40px" }}>
          {/* <Outlet> is a placeholder for the active child route defined in the routes.ts */}
          {children ? children : <Outlet />}
        </Grid>
      </MainPageLayout>
    </Grid>
  );
}
