import { Box, Link, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

/** Optional tooltip text to show when the action switch is not checked */
export const TOOLTIP_TEXT_NOT_CHECKED = "Activate for project";

/** Optional tooltip text to show when the action switch is checked */
export const TOOLTIP_TEXT_CHECKED = "Deactivate for project";

interface Props {
  /** Name of the feature */
  featureName: string;

  /** Description of the feature */
  featureDescription?: string;

  /** URl for Learning more about the feature  */
  learnMoreUrl?: string;

  /** True if the divider should show  */
  shouldShowDivider?: boolean;

  /** Whether a switch or a request button */
  actionComponent: JSX.Element;
}

/** Renders feature settings property description, learn more link, action switch */
export function FeatureSettingLayout({
  featureName,
  featureDescription,
  learnMoreUrl,
  actionComponent,
}: Props): JSX.Element | null {
  return (
    <Box
      component="div"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
          {featureName}
        </Typography>
        {featureDescription && (
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "normal",
            }}
          >
            {" - "}
            {featureDescription}
          </Typography>
        )}
        {learnMoreUrl && (
          <Link
            href={learnMoreUrl}
            target="_blank"
            color={sphereColors.blue500}
            underline="always"
            sx={{
              fontSize: "14px",
              fontWeight: "600",
              textDecorationColor: sphereColors.blue500,
            }}
          >
            Learn more
          </Link>
        )}
      </Box>
      <Box component="div" sx={{ display: "flex", width: "50px" }}>
        {actionComponent}
      </Box>
    </Box>
  );
}
