import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { Box, Grid, Skeleton } from "@mui/material";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { MarkupActions } from "@pages/project-details/project-markups/markup-actions";
import { useMemo, useState } from "react";
import { getMarkupCreatorName } from "@pages/project-details/project-markups/markup-details-utils";
import { useMembersUtils } from "@hooks/use-member-utils";
import { addTransparency } from "@utils/ui-utils";
import { NO_TRANSLATE_CLASS } from "@faro-lotv/flat-ui";

/** Width in pixels of the markup details popover */
const COMPONENT_WIDTH = "420px";

/** Aspect ratio of the image */
const IMAGE_ASPECT_RATIO = 1.8;

/** Renders a markup details menu hat shows as popover when hovering over the markup name */
export function MarkupDetails({ markup }: BaseMarkupProps): JSX.Element {
  /** Flag whether the skeleton for the thumbnail image should be shown */
  const [shouldShowImageSkeleton, setShouldShowImageSkeleton] = useState(true);
  const { companyMembers, projectMembers } = useMembersUtils();

  const creatorName = useMemo(() => {
    const creatorId = markup.createdBy;
    return (
      getMarkupCreatorName({ creatorId, companyMembers, projectMembers }) ?? "-"
    );
  }, [companyMembers, markup.createdBy, projectMembers]);

  return (
    <Box
      sx={{
        width: COMPONENT_WIDTH,
        padding: "16px",
        boxShadow: `0px 3px 6px ${addTransparency({
          color: sphereColors.gray500,
          alpha: EDecimalToHex.fiftyOne,
        })}`,
        borderRadius: "2px",
        [`@media (max-width: ${COMPONENT_WIDTH})`]: {
          width: "100vw",
        },
      }}
    >
      {markup.image?.uri && shouldShowImageSkeleton && (
        <Skeleton
          variant="rectangular"
          sx={{
            width: "100%",
            height: "auto",
            aspectRatio: IMAGE_ASPECT_RATIO,
            marginBottom: "8px",
          }}
        />
      )}
      {markup.image?.uri && (
        <Box
          component="img"
          src={markup.image?.uri}
          sx={{
            width: "100%",
            height: "auto",
            aspectRatio: IMAGE_ASPECT_RATIO,
            objectFit: "fill",
            marginBottom: "8px",
            // The display should be set to know, otherwise for a short time the image will be shown
            // in combination with the skeleton
            display: shouldShowImageSkeleton ? "none" : "block",
          }}
          alt="Annotation thumbnail"
          onLoad={() => {
            setShouldShowImageSkeleton(false);
          }}
        />
      )}
      <Box
        component="div"
        className={NO_TRANSLATE_CLASS}
        sx={{
          fontWeight: "bold",
          marginBottom: "12px",
        }}
      >
        {markup.name}
      </Box>
      {markup.descr && (
        <Box
          component="div"
          className={NO_TRANSLATE_CLASS}
          sx={{
            maxHeight: "88px",
            overflow: "auto",
            scrollbarWidth: "thin",
            marginBottom: "36px",
          }}
        >
          {markup.descr}
        </Box>
      )}
      <Box
        sx={{
          color: sphereColors.gray600,
          fontSize: "12px",
        }}
      >
        Created by
      </Box>
      <Grid container alignItems="center">
        <Grid className={NO_TRANSLATE_CLASS} item xs>
          {creatorName}
        </Grid>
        <Grid item>
          <MarkupActions markup={markup} />
        </Grid>
      </Grid>
    </Box>
  );
}
