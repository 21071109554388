import { Navigate, NavigateProps } from "react-router-dom";
import { usePreservedSearch } from "@hooks/use-preserved-search";
import { forwardRef } from "react";

interface PreservingNavigateProps extends NavigateProps {
  /** The path to navigate to */
  to: string;
}

/**
 * A custom Navigate component that preserves query parameters when navigating to a new route.
 * Use forwardRef to forward the ref to Navigate component.
 */
export const PreservingNavigate = forwardRef<
  HTMLDivElement,
  PreservingNavigateProps
>(({ to, ...rest }, ref) => {
  const toWithSearch = usePreservedSearch(to);

  return <Navigate to={toWithSearch} {...rest} />;
});
