/** All the possible headers for teams table. */
export enum TeamsHeaders {
  /** Team name */
  name = "Name",

  /** Team users */
  users = "Users",

  /** Team creation date */
  creationDate = "Creation date",

  /** Any other possible options */
  options = "Options",
}
