import { useGetBulkDialogContent } from "@components/common/faro-table/bulk-dialog/bulk-dialog-helper";
import { BulkDialogs } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";
import {
  bulkActionResultsSelector,
  numberOfUpdatingItemsSelector,
} from "@store/table/table-selector";
import { useAppSelector } from "@store/store-helper";
import { nounPluralize } from "@utils/data-display";
import {
  TEAM_DISPLAY_NAME,
  TEAMS_DISPLAY_NAME,
} from "@src/constants/team-constants";

/** Determines the content of bulk dialog and bulk success dialog for deleting team */
export function useDeleteTeamsBulkDialogContent(): BulkDialogs {
  const numberOfUpdatingItems = useAppSelector(numberOfUpdatingItemsSelector);
  const { numberOfSuccess, numberOfErrors } = useAppSelector(
    bulkActionResultsSelector
  );

  const bulkDialogContent = useGetBulkDialogContent({
    initial: {
      title: `Delete these ${TEAMS_DISPLAY_NAME} from workspace?`,
      description: `When you confirm this action, all the data of these ${TEAMS_DISPLAY_NAME}
       will be lost and cannot be recovered`,
    },

    failed: {
      title: `Couldn't delete ${numberOfErrors} of ${numberOfUpdatingItems} ${TEAMS_DISPLAY_NAME}`,
      description: `These ${TEAMS_DISPLAY_NAME} couldn't be deleted from workspace, check the details and try again.`,
    },
    confirmButtonText: "delete",
    entityType: TEAM_DISPLAY_NAME,
  });

  const successDialogContent = {
    title: `${nounPluralize({
      counter: numberOfSuccess,
      word: TEAM_DISPLAY_NAME,
    })} deleted`,
    description: `All selected ${TEAMS_DISPLAY_NAME} have been permanently deleted`,
  };

  return { bulkDialogContent, successDialogContent };
}
