import { Box } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SHOW_ON_HOVER_CLASS } from "@utils/ui-utils";
import CameraIcon from "@assets/icons/new/camera_24px.svg?react";
import { PropsWithChildren, useState } from "react";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { FaroChangeThumbnailDialog } from "@components/common/dialog/faro-change-thumbnail-dialog";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { ChangeProjectDetailsEvents } from "@utils/track-event/track-event-list";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppDispatch } from "@store/store-helper";
import { ThumbnailSubjectType } from "@utils/thumbnail-utils";

interface Props extends BaseProjectIdProps {
  /** Link to the existing project thumbnail */
  existingThumbnailUrl?: string;

  /** True if the current user is allowed to change the thumbnail image */
  isAllowedToChange: boolean;
}

/** Renders a dialog to change the thumbnail of a project */
export function ChangeThumbnailDialog({
  children,
  projectId,
  existingThumbnailUrl,
  isAllowedToChange,
}: PropsWithChildren<Props>): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  if (!isAllowedToChange) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- Needed to return JSX.Element
    return <>{children}</>;
  }

  return (
    <>
      <Box
        onClick={() => setIsDialogOpen(true)}
        sx={{
          position: "relative",
          cursor: "pointer",
          width: "100%",
          [`&:hover .${SHOW_ON_HOVER_CLASS}`]: {
            // Show the element with specific display property to place it in the middle
            display: "grid",
          },
        }}
      >
        {children}

        <Box
          component="span"
          className={SHOW_ON_HOVER_CLASS}
          sx={{
            background: sphereColors.pureWhite,
            opacity: 0.6,
            position: "absolute",
            left: "calc(50% - 50px)",
            top: "calc(50% - 50px)",
            height: "100px",
            width: "100px",
            borderRadius: "50%",
            display: "none",
            placeContent: "center",
          }}
        >
          <CameraIcon
            style={{
              fill: sphereColors.gray800,
              height: "35px",
              width: "45px",
            }}
          />
        </Box>
      </Box>
      {isDialogOpen && (
        <FaroChangeThumbnailDialog
          setIsDialogOpen={setIsDialogOpen}
          subjectId={projectId}
          subjectType={ThumbnailSubjectType.project}
          existingThumbnailUrl={existingThumbnailUrl}
          onUpdateThumbnail={async (thumbnailUrl: string) => {
            await dispatch(
              updateProjectDetails({
                coreApiClient,
                projectId: projectId,
                payload: { thumbnailUrl },
              })
            );
          }}
          eventName={ChangeProjectDetailsEvents.changeProjectThumbnail}
        />
      )}
    </>
  );
}
