import { useMemo } from "react";
import { useAppSelector } from "@store/store-helper";
import { ProjectLaunchTarget } from "@custom-types/sdb-company-types";
import { BaseMarkupProps } from "@custom-types/project-markups-types";
import { defaultProjectLaunchTargetSelector } from "@store/sdb-company/sdb-company-selector";

interface MarkupInteractionActionsInfo {
  /**
   * The target viewer/editor for opening the markup.
   * It will be either `ProjectLaunchTarget.sphereViewer` or `ProjectLaunchTarget.webEditor`.
   */
  openTarget: ProjectLaunchTarget;

  /**
   * The URL of the markup in the specified viewer/editor.
   * If `openTarget` is `ProjectLaunchTarget.sphereViewer`, this will be `markup.sphereViewerUrl`.
   * If `openTarget` is `ProjectLaunchTarget.webEditor`, this will be `markup.webEditorUrl`.
   */
  markupHref?: string;
}

/**
 * Custom hook to determine interaction actions (target viewer/editor and URL) for a given markup object.
 *
 * @param {Markup} markup - The markup object for which shared actions are being determined.
 */
export function useSharedMarkupActions({
  markup,
}: BaseMarkupProps): MarkupInteractionActionsInfo {
  const projectLaunchSettings = useAppSelector(
    defaultProjectLaunchTargetSelector
  );

  /**
   * It will validate if the markup has information for the webEditor if not it will return sphereViewer
   */
  const openTarget = useMemo(() => {
    // Only set the open target as WebEditor if the WebEditor URL is available
    if (
      projectLaunchSettings.defaultOpenProjectTarget ===
        ProjectLaunchTarget.webEditor &&
      markup?.webEditorUrl
    ) {
      return projectLaunchSettings.defaultOpenProjectTarget;
    } else {
      return ProjectLaunchTarget.sphereViewer;
    }
  }, [projectLaunchSettings.defaultOpenProjectTarget, markup]);

  const markupHref = useMemo(() => {
    return openTarget === ProjectLaunchTarget.sphereViewer
      ? markup.sphereViewerUrl
      : markup.webEditorUrl;
  }, [markup, openTarget]);

  return {
    openTarget,
    markupHref,
  };
}
