import { useCoreApiClient } from "@api/use-core-api-client";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useToast } from "@hooks/use-toast";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { useAppDispatch } from "@store/store-helper";
import { ChangeProjectDetailsEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCallback } from "react";

/** Hook to return handler for changing the access level */
export function useOnAccessLevelChange(): {
  onAccessLevelChange: (
    value: SphereDashboardAPITypes.EAccessLevel
  ) => Promise<void> | void;
} {
  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const { showToast } = useToast();
  const { trackEvent } = useTrackEvent();
  const { handleErrorWithToast } = useErrorContext();

  const onAccessLevelChange = useCallback(
    async (value: SphereDashboardAPITypes.EAccessLevel) => {
      trackEvent({
        name: ChangeProjectDetailsEvents.changeProjectAccessLevel,
        props: { accessLevel: value },
      });

      try {
        await dispatch(
          updateProjectDetails({
            coreApiClient,
            payload: { accessLevel: value },
          })
        );

        showToast({
          message: "Project access level changed.",
          type: "success",
        });
      } catch (error) {
        // Handle error directly here in order to show specific info about the field that failed: access level
        // Otherwise the error will be handled by the error slice, but that will show a generic message without details
        handleErrorWithToast({
          id: `updateProjectDetails-accessLevel-${Date.now().toString()}`,
          title: "Could not change project access level",
          error,
        });
      }
    },
    [coreApiClient, dispatch, handleErrorWithToast, showToast, trackEvent]
  );

  return { onAccessLevelChange };
}
