import { SphereDashboardAPITypes } from "@stellar/api-logic";
import ProcoreIcon from "@assets/icons/integrations/procore-icon_50px.svg?react";
import AutodeskIcon from "@assets/icons/integrations/autodesk-icon_50px.svg?react";
import { FunctionComponent } from "react";

interface IntegrationSectionProps {
  /** The human readable name of an integration */
  displayName: string;

  /** The description of an integration */
  description: string;

  /** The icon of an integration */
  icon: FunctionComponent;
}

/** Different sections of each integration */
export const IntegrationSections: {
  [key in SphereDashboardAPITypes.IntegrationId]: IntegrationSectionProps;
} = {
  [SphereDashboardAPITypes.IntegrationId.autodesk]: {
    displayName: "Autodesk BIM 360",
    description:
      // eslint-disable-next-line max-len
      "Easily access BIM 360 docs to transfer files into your project. You can also create and view BIM 360 issues directly within the project.",
    icon: AutodeskIcon,
  },
  [SphereDashboardAPITypes.IntegrationId.procore]: {
    displayName: "Procore",
    description:
      // eslint-disable-next-line max-len
      "Access Procore documents and drawings to transfer files into your project. Create and view RFIs and observations directly within the platform.",
    icon: ProcoreIcon,
  },
};

/** All the actions that can be performed for integrations */
export type IntegrationActions =
  | "ConnectToWorkspace"
  | "DisconnectFromWorkspace"
  | "ConnectToProject"
  | "DisconnectFromProject";

/** Different types of integration dialogs */
export type IntegrationDialogTypes = "inProgress" | "disconnect" | "success";

/** Callback for the integration dialog  */
interface IntegrationDialogCallbackProps {
  /** Callback when the dialog is confirmed */
  dialogConfirmCallback?: () => void | Promise<void>;

  /** Callback when the dialog is canceled */
  dialogCancelCallback?: () => void;
}

/** Callbacks for the integration dialogs  */
export interface IntegrationDialogCallbacks {
  /** Callback when confirm button clicked on success dialog */
  onSuccessDialogConfirm(): void;

  /** Callback when cancel button clicked on in progress dialog */
  onInProgressDialogCancel(): void;

  /** Callback when confirm button clicked on disconnect dialog */
  onDisconnectDialogConfirm(): void;

  /** Callback when cancel button clicked on disconnect dialog */
  onDisconnectDialogCancel(): void;
}

/** Props for the integration dialogs */
export interface IntegrationDialogProps extends IntegrationDialogCallbackProps {
  /** Selected integration ID */
  selectedIntegrationId: SphereDashboardAPITypes.IntegrationId;
}
