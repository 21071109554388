import { Banner, FaroThemeOptions } from "@faro-lotv/flat-ui";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { REQUEST_A_QUOTE_URL, SUBSCRIPTION_NAME } from "@src/constants/subscription-constants";
import { DateTimeUtils } from "@stellar/web-core";
import { subscriptionSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { nounPluralize } from "@utils/data-display";
import { OPEN_OTHERS_TARGET_ATTRIBUTE } from "@utils/project-utils";
import { WorkspaceEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useCallback, useMemo, useState } from "react";

const theme = createTheme(FaroThemeOptions);

/** Default remaining days to start showing the banner */
const DEFAULT_DAYS = 3;

interface Props {
  /** Remaining days to start showing the banner */
  days?: number;
}

/** Banner to show when a trial subscription is about to expire */
export function TrialExpirationBanner({
  days = DEFAULT_DAYS,
}: Props): JSX.Element | null {
  const subscription = useAppSelector(subscriptionSelector);
  const { trackEvent } = useTrackEvent();

  const [isDismissed, setIsDismissed] = useState<boolean>(false);

  const remainingDays = useMemo(() => {
    const expirationDate = subscription?.isTrial ? subscription.expiresAt : undefined;
    if (!expirationDate) {
      return days + 1;
    }
    return DateTimeUtils.diffFromTodayInDays({
      date: expirationDate,
      shouldIncludeToday: false,
    });
  }, [days, subscription]);

  const description = useMemo(() => {
    const name = subscription ? SUBSCRIPTION_NAME[subscription.id] : "trial";
    return remainingDays > 0
      ? `Your ${name} subscription ends in ${nounPluralize({
          counter: remainingDays,
          word: "day",
        })}.`
      : `Your ${name} subscription has ended, please contact sales.`;
  }, [remainingDays, subscription]);

  const shouldShowBanner = useMemo(() => {
    return remainingDays <= days && subscription?.isTrial && !isDismissed;
  }, [days, isDismissed, remainingDays, subscription]);

  const onRequestAQuote = useCallback((): void => {
    trackEvent({
      name: WorkspaceEvents.requestAQuote,
    });
    setIsDismissed(true);
    window.open(
      REQUEST_A_QUOTE_URL,
      OPEN_OTHERS_TARGET_ATTRIBUTE
    );
  }, [trackEvent]);

  if (!shouldShowBanner) {
    return null;
  }

  return (
    <Box data-testid="trial-expiration-banner" component="div">
      <ThemeProvider theme={theme}>
        <Banner
          variant="warning"
          action={{
            label: "Request a quote",
            action: onRequestAQuote,
          }}
          onClose={() => setIsDismissed(true)}
        >
          {description}
        </Banner>
      </ThemeProvider>
    </Box>
  );
}
