import { MainRoutes, SettingsTabs } from "@router/route-params";
import { DateTimeUtils } from "@stellar/web-core";
import { subscriptionSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { TrialCountdownIndicator } from "@components/sidebar/trial-countdown/trial-countdown-indicator";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";
import { ListItemButton } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

/** Shows the remaining days of a trial subscription */
export function TrialCountdown(): JSX.Element | null {
  const subscription = useAppSelector(subscriptionSelector);
  const { canViewSubscriptionDetails } = useHasUserValidRoleCompanyLevel();

  const remainingDays = useMemo(() => {
    if (!subscription || !subscription.isTrial) {
      return;
    }
    const expirationDate = subscription.expiresAt;
    const diffFromTodayInDays = DateTimeUtils.diffFromTodayInDays({
      date: expirationDate,
      shouldIncludeToday: false,
    });
    return diffFromTodayInDays > 0 ? diffFromTodayInDays : 0;
  }, [subscription]);

  if (remainingDays === undefined) {
    return null;
  }

  return canViewSubscriptionDetails ? (
    <NavLink
      to={`${MainRoutes.settings}/${SettingsTabs.subscription}`}
      style={{
        textDecoration: "none",
      }}
    >
      <ListItemButton
        sx={{
          padding: 0,
          "&:hover": {
            backgroundColor: sphereColors.gray200,
            color: sphereColors.blue500,
            fill: sphereColors.blue500,
          },
        }}
      >
        <TrialCountdownIndicator remainingDays={remainingDays} />
      </ListItemButton>
    </NavLink>
  ) : (
    <TrialCountdownIndicator remainingDays={remainingDays} />
  );
}
