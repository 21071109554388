import { BaseProjectProps } from "@custom-types/project-types";
import { defaultProjectThumbnail, isProjectDemo } from "@utils/project-utils";
import { DemoTag } from "@components/common/demo-tag";
import { FaroCardImage } from "@components/common/faro-card/faro-card-image";

/**
 * The top section of a card that shows the project thumbnail.
 */
export function ProjectCardTop({ project }: BaseProjectProps): JSX.Element {
  return (
    <>
      <FaroCardImage
        src={project.thumbnailUrl ?? defaultProjectThumbnail}
      />
      {/* If the project is a demo project shows a demo tag on the top left side */}
      {isProjectDemo(project) && (
        <DemoTag
          sx={{
            position: "absolute",
            top: "15px",
            left: "15px",
          }}
        />
      )}
    </>
  );
}
