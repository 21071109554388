import { SphereTooltipIcon } from "@components/common/sphere-tooltip-icon";
import { Constraint } from "@custom-types/subscription-types";
import { Box } from "@mui/material";
import { CONSTRAINT_NOUN } from "@src/constants/subscription-constants";
import { AdminAPITypes } from "@stellar/api-logic";
import { withEllipsis } from "@styles/common-styles";
import InfoIcon from "@assets/icons/new/info-circle-fill.svg?react";

interface Props {
  /** Subscription constraint */
  constraint: Constraint;
}

/** Details of a subscription constraint */
export function SubscriptionConstraint({ constraint }: Props): JSX.Element {
  const constraintNoun = constraint.max === 1
    ? CONSTRAINT_NOUN[constraint.type].singular
    : CONSTRAINT_NOUN[constraint.type].plural;

  return (
    <Box
      component="div"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: 600,
      }}
    >
      <Box
        component="div"
        sx={{
          flexGrow: 1,
          paddingRight: "10px",
          ...withEllipsis,
        }}
      >
        {`${constraint.max} ${constraintNoun} limit`}
      </Box>
      {constraint.type === AdminAPITypes.EConstraintType.userRole && (
        <SphereTooltipIcon
          icon={InfoIcon}
          text="An active member is a user that can make changes to a project"
        />
      )}
    </Box>
  );
}