import { Grid, Tab, Tabs } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { PropsWithChildren, useState } from "react";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import {
  TAB_WITH_NO_UNDERLINE,
  DEFAULT_TAB_UNDERLINE,
} from "@styles/common-styles";
import { useToast } from "@hooks/use-toast";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useFileUpload } from "@hooks/use-file-upload";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import {
  FileUploadTaskContext,
  UploadElementType,
} from "@custom-types/file-upload-types";
import { APITypes } from "@stellar/api-logic";
import { SphereDropzone } from "@src/components/common/sphere-dropzone/sphere-dropzone";
import { isSingleFileUploadResponse } from "@custom-types/file-upload-type-guards";
import { FaroThumbnailSelector } from "@components/faro-thumbnail-selector";
import {
  ChangeThumbnailTab,
  ThumbnailSubjectType,
} from "@utils/thumbnail-utils";

/** Represents a subject id, which can be either a project Id or group Id*/
type SubjectId = APITypes.ProjectId | APITypes.GroupId;

interface Props {
  setIsDialogOpen: (value: boolean) => void;

  /** ID of the item that thumbnail want to change  */
  subjectId: SubjectId;

  /** Type of the item that thumbnail want to change  */
  subjectType: ThumbnailSubjectType;

  /** Link to the existing project thumbnail */
  existingThumbnailUrl?: string;

  /**
   * Callback function invoked to update the thumbnail URL of the subject.
   *
   * @param thumbnailUrl - The new URL of the thumbnail to be set for the subject.
   * @returns A promise that resolves when the thumbnail update operation is successfully
   */
  onUpdateThumbnail: (thumbnailUrl: string) => Promise<void>;

  /** The name of the event to track when the thumbnail is changed. */
  eventName: string;
}

interface UpdateThumbnailProps {
  /** URL of the new thumbnail image */
  thumbnailUrl: string;

  /** Source of the thumbnail change: 'From Computer' or 'From Gallery' */
  location: string;
}

/** Renders a dialog to change the thumbnail */
export function FaroChangeThumbnailDialog({
  setIsDialogOpen,
  subjectId,
  subjectType,
  existingThumbnailUrl,
  onUpdateThumbnail,
  eventName,
}: PropsWithChildren<Props>): JSX.Element {
  const { showToast } = useToast();
  const { handleErrorWithToast } = useErrorContext();
  const { uploadSingleFile } = useFileUpload();
  const { trackEvent } = useTrackEvent();

  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(true);
  const [selectedThumbnail, setSelectedThumbnail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const [selectedTab, setSelectedTab] = useState<ChangeThumbnailTab>(
    ChangeThumbnailTab.computer
  );

  /** The context for the file upload depending on subject type */
  const uploadContextMap: Record<ThumbnailSubjectType, FileUploadTaskContext> =
    {
      [ThumbnailSubjectType.project]: {
        uploadElementType: UploadElementType.project,
        projectId: subjectId,
      },
      [ThumbnailSubjectType.group]: {
        uploadElementType: UploadElementType.group,
        groupId: subjectId,
      },
    };

  /** Triggers when an image is selected from gallery tab */
  function onSelectImageFromGallery(imageUrl: string): void {
    setIsConfirmDisabled(false);
    setSelectedThumbnail(imageUrl);
  }

  /**
   * Update thumbnail from gallery or by uploading from computer
   *
   * @param thumbnailUrl The url of the new image
   * @param location From where the change is triggered
   */
  async function updateThumbnail({
    thumbnailUrl,
    location,
  }: UpdateThumbnailProps): Promise<void> {
    setIsLoading(true);
    trackEvent({
      name: eventName,
      props: { location },
    });

    try {
      await onUpdateThumbnail(thumbnailUrl);
      showToast({
        message: "Thumbnail image changed.",
        type: "success",
      });
    } catch (error) {
      // Handle error directly here in order to show specific info about the field that failed: thumbnailUrl
      // Otherwise the error will be handled by the error slice, but that will show a generic message without details
      handleErrorWithToast({
        id: `updateThumbnail-${Date.now().toString()}`,
        title: "Could not change thumbnail image",
        error,
      });
    } finally {
      setIsLoading(false);
      setIsConfirmDisabled(true);
      setIsDialogOpen(false);
      setIsUploading(false);
    }
  }

  /** Uploads a file which is selected from gallery */
  async function uploadSelectedThumbnail(): Promise<void> {
    // Load the file as File object
    const fileResponse = await fetch(selectedThumbnail).then((res) =>
      res.arrayBuffer()
    );
    const fileName = selectedThumbnail.split("/").pop() ?? "thumbnail.svg";
    const file = new File([fileResponse], fileName, { type: "image/svg+xml" });

    // Upload the gallery file
    await uploadSingleFile({
      file: file,
      onUploadStart: () => setIsLoading(true),
      onUploadProgress: () => {
        // Not showing progress here
      },
      onUploadComplete: (uploadedResponse) =>
        updateThumbnail({
          thumbnailUrl: uploadedResponse,
          location: "From Gallery",
        }),

      context: uploadContextMap[subjectType],
    });
  }

  return (
    <FaroDialog
      title="Change Thumbnail"
      open={true}
      confirmText={isLoading ? "Applying Image" : "Apply Image"}
      onConfirm={uploadSelectedThumbnail}
      isConfirmDisabled={isConfirmDisabled}
      onClose={() => setIsDialogOpen(false)}
      isConfirmLoading={isLoading}
      shouldHideActions={selectedTab === ChangeThumbnailTab.computer}
    >
      <Grid
        maxWidth="100%"
        width="70vw"
        height="auto"
        // This margin is applied to resolve jumping effect of modal
        // As From computer tab has no action buttons at the bottom
        marginBottom={
          selectedTab === ChangeThumbnailTab.computer ? "30px" : "0px"
        }
      >
        <Tabs
          value={selectedTab}
          scrollButtons
          TabIndicatorProps={{
            sx: {
              backgroundColor: sphereColors.blue500,
              height: "3px",
            },
          }}
          sx={{
            boxShadow: {
              xs: TAB_WITH_NO_UNDERLINE,
              md: DEFAULT_TAB_UNDERLINE,
            },
          }}
        >
          {Object.values(ChangeThumbnailTab).map((tab) => (
            <Tab
              disableRipple
              key={tab}
              label={tab}
              value={tab}
              onClick={() => setSelectedTab(tab)}
              sx={{
                padding: 0,
                fontSize: "14px",
                letterSpacing: "-0.2px",
                marginRight: "20px",
                textTransform: "none",
                minWidth: "30px",
                fontWeight: selectedTab === tab ? "bold" : "lighter",
                "&.MuiTab-root": {
                  color:
                    selectedTab === tab
                      ? sphereColors.blue500
                      : sphereColors.gray800,
                },
                "&:hover": {
                  color: sphereColors.blue500,
                  borderBottom: `1px solid ${sphereColors.blue500}`,
                  // Add a top padding to avoid the tab content from jumping
                  paddingTop: "1px",
                },
              }}
            />
          ))}
        </Tabs>
        {selectedTab === ChangeThumbnailTab.gallery ? (
          <FaroThumbnailSelector
            subjectType={subjectType}
            selectedThumbnail={selectedThumbnail}
            onSelectImage={onSelectImageFromGallery}
          />
        ) : (
          <SphereDropzone
            existingImageUrl={existingThumbnailUrl}
            isLoading={isUploading}
            setIsLoading={setIsUploading}
            onUploadComplete={(uploadedResponse) => {
              if (isSingleFileUploadResponse(uploadedResponse)) {
                updateThumbnail({
                  thumbnailUrl: uploadedResponse,
                  location: "From Computer",
                });
              }
            }}
            context={uploadContextMap[subjectType]}
          />
        )}
      </Grid>
    </FaroDialog>
  );
}
