import { BaseMemberProps, MemberTypes } from "@custom-types/member-types";
import { parseTextToEmails } from "@utils/member-utils";
import { getPrettyName, isMemberActive } from "@utils/user-utils";
import {
  AutoCompleteMemberOption,
  AutoCompleteTeamOption,
  NonStringOption,
  AutocompleteOptionType,
  SelectedOption,
} from "@components/common/members-autocomplete/members-autocomplete-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { getTeamAvatarInitials } from "@utils/team-utils";
import {
  isOptionMember,
  isOptionNew,
  isOptionTeam,
} from "@custom-types/type-guards";
import { nounPluralize } from "@utils/data-display";

/**
 * Gets the label for each member to be provided in the options array.
 * The label is never displayed in the UI but it is used as an Id to filter the options.
 */
export function getMemberLabel({ member }: BaseMemberProps): string {
  return isMemberActive(member)
    ? `${getPrettyName(member)}#${member.email}`
    : member.email;
}

/** Returns either the label for the option, or the option itself */
export function getOptionLabel(option: SelectedOption): string {
  return isOptionNew(option) ? option : option.label;
}

/** Returns the title to be used which is normally the name for existing users and email for new users */
export function getOptionTitle(option: SelectedOption): string {
  return isOptionNew(option) ? option : option.title;
}

/** Returns the subtitle to be used which is normally the email for existing and active users */
export function getOptionSubtitle(option: SelectedOption): string {
  return isOptionNew(option) ? option : option.subtitle;
}

/** Returns the value for the option, which is normally the identity for existing users */
export function getOptionValue(option: SelectedOption): string {
  if (isOptionNew(option)) {
    return option;
  } else if (isOptionMember(option)) {
    return option.value;
  } else if (isOptionTeam(option)) {
    return option.value;
  }

  return "";
}

/** Returns the value for the option, which is normally the identity for existing users */
export function getOptionThumbnail(option: SelectedOption): string {
  if (isOptionNew(option)) {
    return option;
  } else if (isOptionMember(option)) {
    return option.originalObject.thumbnailUrl ?? "";
  } else if (isOptionTeam(option)) {
    return getTeamAvatarInitials(option.originalObject.name);
  }
  return "";
}

/**
 * Returns whether the option should be disabled or it is was preselected.
 * For new users they are never disabled.
 */
export function getOptionDisabled(option: SelectedOption): boolean {
  if (isOptionNew(option)) {
    return false;
  }
  // Preselected props are treated as disabled because they can't be removed
  return (option.isDisabled || option.isPreselected) ?? false;
}

/** Returns the disabled message for the option, or an empty string */
export function getOptionDisabledMessage(option: SelectedOption): string {
  return isOptionNew(option) ? "" : option.disabledMessage;
}

/** Returns the original member object if the selected option is not new */
export function getOptionOriginalOption(
  option: SelectedOption
): MemberTypes | SphereDashboardAPITypes.ITeam | null {
  return isOptionNew(option) ? null : option.originalObject;
}

/** Returns the updated selected options merging with newly added value if there is any email */
export function getUpdatedSelectedOptions(
  selectedOptions: SelectedOption[],
  newValue: string
): SelectedOption[] {
  const emails = parseTextToEmails(newValue);

  // Early return if there is no new email added
  if (!emails.length) {
    return [];
  }

  // Remove the delimiters first and then the emails from the input value
  newValue = newValue.replaceAll(";", "").replaceAll(",", "");

  // Create a copy of the current selected options
  const updatedSelectedOptions = [...selectedOptions];

  emails.forEach((email) => {
    // Only add email if it doesn't already exist in the selectedOptions
    if (!updatedSelectedOptions.includes(email)) {
      updatedSelectedOptions.push(email);
    }
    newValue = newValue.replace(email, "").trim();
  });

  return updatedSelectedOptions;
}

/** Returns the options sorting first by type and then alphabetically */
export function getSortedOptions(
  options: NonStringOption[]
): NonStringOption[] {
  const optionTypeOrder: AutocompleteOptionType[] = ["team", "member"];
  return options.sort((a, b) => {
    if (a.type.toLowerCase() === b.type.toLowerCase()) {
      return getOptionLabel(a).localeCompare(getOptionLabel(b));
    }

    return Math.sign(
      optionTypeOrder.indexOf(a.type) - optionTypeOrder.indexOf(b.type)
    );
  });
}

type CreateOptionBaseProps = Partial<
  Pick<
    AutoCompleteMemberOption,
    "isDisabled" | "disabledMessage" | "isPreselected"
  >
>;

type CreateMemberOptionsProps = CreateOptionBaseProps & {
  /** The member to be converted to AutoCompleteMemberOption */
  member: MemberTypes;
};

/** Create autocomplete options based on MemberTypes */
export function createMemberOption({
  member,
  isDisabled = false,
  disabledMessage = "",
  isPreselected = false,
}: CreateMemberOptionsProps): AutoCompleteMemberOption {
  return {
    type: "member",
    title: getPrettyName(member),
    subtitle: isMemberActive(member) ? member.email : "",
    label: getMemberLabel({ member }),
    value: member.identity,
    originalObject: member,
    isDisabled,
    disabledMessage,
    isPreselected,
  };
}

type CreateTeamOptionsProps = CreateOptionBaseProps & {
  /** The team to be converted to AutoCompleteTeamOption */
  team: SphereDashboardAPITypes.ITeam;
};

/** Create autocomplete options based on ITeam */
export function createTeamOption({
  team,
  isDisabled = false,
  disabledMessage = "",
  isPreselected = false,
}: CreateTeamOptionsProps): AutoCompleteTeamOption {
  return {
    type: "team",
    title: team.name,
    subtitle: `${nounPluralize({
      counter: team.memberCount,
      word: "member",
    })}`,
    label: team.name,
    value: team.id,
    originalObject: team,
    isDisabled,
    disabledMessage,
    isPreselected,
  };
}
