import { Checkbox, MenuItem, Stack } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import CheckIcon from "@assets/icons/new/check_18px.svg?react";
import { MarkupStatusIcon } from "@pages/project-details/project-markups/status/markup-status-icon";
import { MarkupStatusOption } from "@pages/project-details/project-markups/status/markup-status-types";
import { AnnotationStatus } from "@faro-lotv/ielement-types";

interface Props {
  /** Contains properties of dropdown item */
  markupOption: MarkupStatusOption;

  /** The active statuses of the markup to show selected style */
  activeStatuses: string[];

  /** Callback function to update markup status */
  onStatusChange(newStatus: AnnotationStatus): void;

  /** Whether to show a checkbox next to each status or not */
  shouldShowCheckbox?: boolean;
}

/** Renders items inside the markup status dropdown */
export function MarkupStatusDropdownItem({
  markupOption,
  activeStatuses,
  onStatusChange,
  shouldShowCheckbox = false,
}: Props): JSX.Element {
  const isStatusActive = activeStatuses?.includes(markupOption.value);

  return (
    <MenuItem
      disableRipple
      disableTouchRipple
      key={markupOption.key}
      value={markupOption.value}
      selected={isStatusActive}
      onClick={() => onStatusChange(markupOption.value)}
      sx={{
        fontSize: "12px",
        paddingX: "8px",
      }}
    >
      {shouldShowCheckbox && (
        <Checkbox
          size="small"
          disableRipple
          disableFocusRipple
          disableTouchRipple
          checked={isStatusActive}
          onChange={() => onStatusChange(markupOption.value)}
        />
      )}
      <Stack
        sx={{
          alignItems: "center",
          gap: "20px",
          flexDirection: "row",
          fontSize: "14px",
          fontWeight: 400,
          color: isStatusActive ? sphereColors.blue500 : "inherit",
        }}
      >
        <MarkupStatusIcon status={markupOption.value} shouldHaveBorder={true} />

        {markupOption.label}

        {!shouldShowCheckbox && isStatusActive && (
          <CheckIcon
            style={{
              fontSize: "13px",
              color: sphereColors.blue500,
              fontWeight: "bold",
            }}
          />
        )}
      </Stack>
    </MenuItem>
  );
}
