import { useAppParams } from "@router/router-helper";
import { ProjectDataManagement } from "@pages/project-details/project-data-management/project-data-management";
import { ErrorPageBase } from "@pages/error-page-base";
import NotFoundIcon from "@assets/icons/new/page-not-found_566px.svg";

/** Renders the data management component if the project ID is defined. Otherwise it shows an error page */
export function DataManagement(): JSX.Element {
  const { projectId } = useAppParams();

  if (!projectId) {
    return (
      <ErrorPageBase title="Project ID is not defined" iconImg={NotFoundIcon}>
        The project ID is not defined. Please reload the application to try
        again.
      </ErrorPageBase>
    );
  }

  return <ProjectDataManagement projectId={projectId} />;
}
