import { IElementAttachment } from "@faro-lotv/ielement-types";
import { Box, CircularProgress, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { memo, useState } from "react";
import { withEllipsis } from "@styles/common-styles";
import { fileIcons } from "@pages/project-details/project-markups/sidepanel/markup-sidepanel-utils";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import { useDateTime } from "@hooks/use-date-time";
import { AttachmentItemActions } from "@pages/project-details/project-markups/sidepanel/attachment/attachment-item-actions";
import { FaroImagePreview } from "@components/common/dialog/faro-image-preview";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import {
  ACTION_BTN_SIZE,
  ACTION_ICON_SIZE,
} from "@components/common/project-actions";
import { getFileExtension, isImageFileType } from "@utils/file-utils";

interface Props {
  /** The attachment item to display */
  attachment: IElementAttachment;
}

const BYTES = 1024;

/**
 * Component representing an attachment item within the markups side panel.
 *
 * @param {MarkupsSidePanelAttachmentItemProps} props - The properties for the component, including the attachment data.
 * @returns {JSX.Element} The rendered component.
 */
function MarkupsSidePanelAttachmentItemComponent({
  attachment,
}: Props): JSX.Element {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] =
    useState<boolean>(false);

  const { hasPermissionToEditMarkup } = useMarkupContext();

  const { formatDate } = useDateTime();

  const { trackEvent } = useTrackEvent();

  /**
   * It will convert the bytes in MB
   *
   * @param bytes
   * @returns the MB with 2 case decimals
   */
  function bytesToMB(bytes: number): string {
    const megabytes = bytes / (BYTES * BYTES);

    return megabytes.toFixed(2);
  }

  // Get the file extension from the attachment's name and check if it represents an image file type.
  const fileExtension = getFileExtension(attachment.name);
  const isImage = isImageFileType(fileExtension);

  const FileIcon = fileIcons[fileExtension.toLowerCase()] || fileIcons.default;

  const editPermissionOffset =
    hasPermissionToEditMarkup && !isDownloading ? "145px" : "106px";
  const widthOffset = isHovered ? editPermissionOffset : "77px";

  /**
   * Handles the click event on an attachment item by:
   *  - Tracking a `preview attachment` event with file type and extension.
   *  - Opening the image preview dialog if the attachment is an image.
   *  - Opening the attachment URL in a new secure tab if it's not an image.
   */
  function onAttachmentItemClick(): void {
    if (isImage) {
      trackEvent({
        name: AnnotationEvents.previewAttachment,
        props: { fileType: "image", fileExtension },
      });
      setIsPreviewDialogOpen(true);
    } else {
      trackEvent({
        name: AnnotationEvents.previewAttachment,
        props: { fileType: "other", fileExtension },
      });
      window.open(attachment.uri, "_blank", "noopener,noreferrer");
    }
  }

  return (
    <Box>
      <Box
        data-testid="markup-side-panel-attachment-item-container"
        sx={{
          display: "flex",
          gap: "8px",
          borderBottom: 1,
          borderColor: "divider",
          padding: "8px",
          transition: "background-color 0.3s",
          alignItems: "center",
          "&:hover": {
            backgroundColor: sphereColors.gray100,
            cursor: "pointer",
          },
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => onAttachmentItemClick()}
      >
        {isImage ? (
          <Box
            component="img"
            src={attachment.uri}
            alt="attachment annotation"
            width="60px"
            minWidth="60px"
            height="43px"
            data-testid="markup-side-panel-attachment-image"
          />
        ) : (
          <Box sx={{ height: "43px", display: "flex", alignItems: "center" }}>
            <FileIcon width="60px" height="32px" />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: !isDownloading ? `calc(100% - ${widthOffset})` : "100%",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              color: isHovered ? sphereColors.blue500 : sphereColors.gray800,
              ...withEllipsis,
            }}
          >
            <var>{attachment.name}</var>
          </Typography>
          <Box sx={{ display: "flex", gap: "8px" }}>
            <Typography
              sx={{
                fontSize: "10px",
                color: isHovered ? sphereColors.blue500 : sphereColors.gray800,
              }}
            >
              <var>{formatDate(attachment.createdAt)}</var>
            </Typography>
            {attachment.fileSize && (
              <>
                <Typography
                  sx={{
                    fontSize: "10px",
                    color: isHovered
                      ? sphereColors.blue500
                      : sphereColors.gray800,
                  }}
                >
                  .
                </Typography>
                <Typography
                  sx={{
                    fontSize: "10px",
                    color: isHovered
                      ? sphereColors.blue500
                      : sphereColors.gray800,
                  }}
                >
                  {bytesToMB(attachment.fileSize)} MB
                </Typography>
              </>
            )}
          </Box>
        </Box>
        {isDownloading && <CircularProgress size={"1rem"} />}

        {isHovered && !isDownloading && (
          <Box onClick={(e) => e.stopPropagation()}>
            <AttachmentItemActions
              attachment={attachment}
              setIsDownloading={setIsDownloading}
              eventSource="side panel"
              buttonSize={ACTION_BTN_SIZE}
              iconSize={ACTION_ICON_SIZE}
            />
          </Box>
        )}
      </Box>
      {isPreviewDialogOpen && (
        <FaroImagePreview
          attachment={attachment}
          setIsDownloading={setIsDownloading}
          setIsPreviewDialogOpen={setIsPreviewDialogOpen}
        />
      )}
    </Box>
  );
}

export const MarkupsSidePanelAttachmentItem = memo(
  MarkupsSidePanelAttachmentItemComponent
);
