import {
  CaptureTreeEntity,
  CaptureTreeEntityType,
  RegistrationRevision,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "@store/store-helper";
import {
  allRegistrationRevisionsAdapter,
  captureTreeForMainRevisionAdapter,
} from "@store/capture-tree/capture-tree-slice";
import { sdbBackgroundTasksAdapter } from "@store/sdb-background-tasks/sdb-background-tasks-slice";
import { SdbBackgroundTask, SdbBackgroundTaskStates } from "@custom-types/sdb-background-tasks-types";
import { CaptureTreeState } from "@store/capture-tree/capture-tree-slice-types";
import { GUID } from "@faro-lotv/foundation";
import {
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import { isIElementPointCloudE57 } from "@faro-lotv/ielement-types";

/** Returns all the capture tree entities (for the current main revision of the selected project) */
export const captureTreeForMainRevisionSelector: (
  state: RootState
) => CaptureTreeEntity[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return captureTreeForMainRevisionAdapter
      .getSelectors()
      .selectAll(state.captureTree.captureTreeForMainRevision);
  }
);

/** Returns the fetching status of the capture tree entities (for the current main revision of the selected project) */
export const fetchingStatusCaptureTreeForMainRevisionSelector: (
  state: RootState
) => CaptureTreeState["fetchingStatus"]["captureTreeForMainRevision"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.captureTree.fetchingStatus.captureTreeForMainRevision;
    }
  );

/** Returns whether capture tree entities have been fetched at least once */
export const hasFetchedCaptureTreeForMainRevisionSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.hasFetched.hasFetchedCaptureTreeForMainRevision;
  }
);

/** Returns all the registration revisions of the current project */
export const allRegistrationRevisionsSelector: (
  state: RootState
) => RegistrationRevision[] = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return allRegistrationRevisionsAdapter
      .getSelectors()
      .selectAll(state.captureTree.allRegistrationRevisions);
  }
);

/** Returns the fetching status of all the registration revisions of the current project */
export const fetchingStatusAllRegistrationRevisionsSelector: (
  state: RootState
) => CaptureTreeState["fetchingStatus"]["allRegistrationRevisions"] =
  createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      return state.captureTree.fetchingStatus.allRegistrationRevisions;
    }
  );

/** Returns whether all registration revisions of the current project have been fetched at least once */
export const hasFetchedAllRegistrationRevisionsSelector: (
  state: RootState
) => boolean = createSelector(
  (state: RootState) => state,
  (state: RootState) => {
    return state.captureTree.hasFetched.hasFetchedAllRegistrationRevisions;
  }
);

/**
 * Gets a capture tree entity by providing its ID
 *
 * * @param id ID of the capture tree entity
 */
export function captureTreeEntityByIdSelector(
  id: GUID
): (state: RootState) => CaptureTreeEntity | undefined {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      if (!id) {
        return undefined;
      }

      const entities = captureTreeForMainRevisionSelector(state);
      return entities.find((entity) => entity.id === id);
    }
  );
}

/**
 * @returns A string representing the path to the cluster where the capture tree entity is located
 *
 * @param id ID of the capture tree entity
 */
export function captureTreeEntityClusterPathSelector(
  id: GUID
): (state: RootState) => string {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      let entity = captureTreeEntityByIdSelector(id)(state);

      if (!entity) {
        return "";
      }

      const pathArray: string[] = [];

      if (entity.type === CaptureTreeEntityType.cluster) {
        pathArray.unshift(entity.name);
      }

      while (entity && entity.parentId) {
        entity = captureTreeEntityByIdSelector(entity.parentId)(state);

        if (entity && entity.type === CaptureTreeEntityType.cluster) {
          pathArray.unshift(entity.name);
        }
      }

      return pathArray.join("/");
    }
  );
}

/**
 * @returns true if the capture tree scan entity is processed.
 * To check if a scan entity has been processed we first check for the presence of
 * the ielement of type "PointCloudE57" as children of the scan ielement, then
 * we check presence and status of the relevant tasks from ProgressAPI.
 *
 * @param id ID of the capture tree scan entity
 */
export function isCaptureTreeScanEntityProcessingSelector(
  id: GUID
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const scanElement = selectIElement(id)(state);
      if (!scanElement) {
        return false;
      }

      const pointCloudStreamElement = selectChildDepthFirst(
        scanElement,
        isIElementPointCloudE57
      )(state);

      // Early out if PCS file already exist.
      if (pointCloudStreamElement) {
        return false;
      }

      // Check the task states got from ProgressAPI.
      const selectedScanTasks: SdbBackgroundTask[] = getSelectedScanTasks(id)(state);
      const checkStates: SdbBackgroundTaskStates[] = ["Pending", "Created", "Scheduled", "Started"];
      // We care only about the newest task as they can theoretically be recreated on failure or so.
      if (selectedScanTasks[0]?.status && checkStates.includes(selectedScanTasks[0].status)) {
          return true;
      }

      // There are no active tasks found.
      return false;
    }
  );
}

/**
 * @returns true if the capture tree scan entity has processing errors.
 * To check if a scan entity has errors we look at the relevant tasks from ProgressAPI.
 *
 * @param id ID of the capture tree scan entity
 */
export function hasCaptureTreeScanEntityTaskErrorsSelector(
  id: GUID
): (state: RootState) => boolean {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const scanElement = selectIElement(id)(state);
      if (!scanElement) {
        return false;
      }

      const pointCloudStreamElement = selectChildDepthFirst(
        scanElement,
        isIElementPointCloudE57
      )(state);

      // Early out if PCS file already exist. Means there is no error.
      if (pointCloudStreamElement) {
        return false;
      }

      // Check the task states got from ProgressAPI.
      const selectedScanTasks: SdbBackgroundTask[] = getSelectedScanTasks(id)(state);
      const errorStates: SdbBackgroundTaskStates[] = ["Aborted", "Failed"];
      // We care only about the newest task as they can theoretically be recreated on failure or so.
      if (selectedScanTasks[0]?.status && errorStates.includes(selectedScanTasks[0].status)) {
          return true;
      }

      // There are no failed tasks found.
      return false;
    }
  );
}

/**
 * @returns All the tasks related to the capture tree scan entity ordered by creation date, newest first.
 *
 * @param id ID of the capture tree scan entity
 */
function getSelectedScanTasks(id: GUID): (state: RootState) => SdbBackgroundTask[] {
  return createSelector(
    (state: RootState) => state,
    (state: RootState) => {
      const scanElement = selectIElement(id)(state);

      // Read all tasks from the store got from ProgressAPI and filter out the ones related to the scan entity.
      const tasks = sdbBackgroundTasksAdapter
        .getSelectors()
        .selectAll(state.sdbBackgroundTasks);

      const selectedScanTasks: SdbBackgroundTask[] = [];
      for (const task of tasks) {
        const hasRelevantScanChildElements = scanElement?.childrenIds?.some(
          (childrenId: string) => childrenId === task.context?.elementId
        );
        if (hasRelevantScanChildElements) {
          selectedScanTasks.push(task);
        }
      }

      // Sorted from newest creation date to oldest.
      selectedScanTasks.sort((a, b) => {
        const aCreatedAt = new Date(a.createdAt).getTime();
        const bCreatedAt = new Date(b.createdAt).getTime();
        return bCreatedAt - aCreatedAt;
      });

      return selectedScanTasks;
    }
  );
}