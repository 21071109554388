import { FaroIconButton } from "@components/common/faro-icon-button";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import { useMarkupContext } from "@context-providers/markup/markup-context";
import DeleteIcon from "@assets/icons/new/delete_32px.svg?react";
import DownloadIcon from "@assets/icons/new/download_24px.svg?react";
import { IElementAttachment } from "@faro-lotv/ielement-types";
import { Box, IconButtonProps, Typography } from "@mui/material";
import { DownloadUtils } from "@stellar/web-core";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AnnotationEvents } from "@utils/track-event/track-event-list";
import { FaroDialog } from "@components/common/dialog/faro-dialog";
import { sphereColors } from "@styles/common-colors";
import { cloneDeep, findIndex } from "lodash";
import { useProjectMarkupUpdate } from "@hooks/project-markups/use-project-markup-update";
import { useState } from "react";
import { getFileExtension, isImageFileType } from "@utils/file-utils";

interface Props {
  /** The attachment item to display */
  attachment: IElementAttachment;

  /** Function to update the downloading state of the attachment */
  setIsDownloading: (value: boolean) => void;

  /**
   *  Indicates the component or context that triggers the event,
   *  For example: "Preview Attachment Dialog" or "Side Panel".
   */
  eventSource: string;

  /** Size in pixels for the outer button. */
  buttonSize: string | number;

  /** Size in pixels for the icon. */
  iconSize?: string | number;

  /** Optional props to be forwarded to the IconButtonProps.  */
  iconBtnProps?: IconButtonProps;
}

/**
 * Component that rendering action buttons for managing an attachment item,
 * including downloading the attachment and deleting it (if the user has permission).
 *
 * @param {AttachmentItemActionsProps} Props - The properties for the component, including the attachment data,
 * a function to update the downloading state and a function to control the delete modal visibility.
 * @returns {JSX.Element} The rendered component.
 */
export function AttachmentItemActions({
  attachment,
  setIsDownloading,
  eventSource,
  buttonSize,
  iconSize,
  iconBtnProps,
}: Props): JSX.Element {
  const {
    hasPermissionToEditMarkup,
    projectId,
    markups,
    selectedMarkup,
    setIsMarkupUpdating,
    updateMarkups,
  } = useMarkupContext();

  const { deleteAttachmentToMarkup } = useProjectMarkupUpdate({ projectId });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const { trackEvent } = useTrackEvent();

  // Get the file extension from the attachment's name and determine if it's an image file type
  const fileExtension = getFileExtension(attachment.name);
  const isImage = isImageFileType(fileExtension);

  /**
   * Downloads a file from a given URL and saves it to the user's device.
   *
   * This function performs the following steps:
   * 1. Initiates the download of the file from the specified URL as a Blob object.
   * 2. Provides optional callback functions to track the download progress, start, and end events.
   * 3. Creates a downloadable link for the Blob object.
   * 4. Triggers the browser to download the file using the created link.
   *
   * @returns {Promise<void>} A promise that resolves when the download and save operation is complete.
   */
  async function downloadAndSaveFile(): Promise<void> {
    trackEvent({
      name: `${AnnotationEvents.downloadAttachment}`,
      props: {
        eventSource: eventSource,
        fileType: isImage ? "image" : "other",
        fileExtension,
      },
    });
    const fileBlob = await DownloadUtils.downloadFilePromise(attachment.uri, {
      onFileDownloadStart: () => setIsDownloading(true),
      onFileDownloadEnd: () => setIsDownloading(false),
    });

    const fileUrl = URL.createObjectURL(fileBlob);
    DownloadUtils.downloadFile(attachment.name, fileUrl);
  }

  /**
   * Deletes an attachment from the project and updates the local copy of the project.
   *
   * This function performs the following steps:
   * 1. Creates a mutation to delete the attachment using its ID.
   * 2. Applies the mutation to the project API client to delete the attachment.
   * 3. Fetches the updated sub-tree of elements related to the attachment and updates the local state.
   *
   * @returns {Promise<void>} A promise that resolves when the attachment deletion and state update are complete.
   */
  async function deleteAttachment(): Promise<void> {
    trackEvent({
      name: `${AnnotationEvents.deleteAttachment}`,
      props: {
        eventSource: eventSource,
        fileType: isImage ? "image" : "other",
        fileExtension,
      },
    });

    setIsDeleting(true);
    setIsMarkupUpdating(true);

    const mutation = await deleteAttachmentToMarkup(attachment);

    if (!mutation || !selectedMarkup) {
      return;
    }

    const newMarkups = cloneDeep(markups);
    const markupIndex = findIndex(newMarkups, { id: selectedMarkup.id });
    const attachmentList = newMarkups[markupIndex].attachments;

    const updateAttachmentList = attachmentList.filter(
      (attachment) => attachment.id !== mutation.elementId
    );

    newMarkups[markupIndex].attachments = updateAttachmentList;

    updateMarkups(newMarkups);
    setIsDeleting(false);
    setIsMarkupUpdating(false);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <FaroIconButton
        buttonSize={buttonSize}
        iconSize={iconSize}
        component={DownloadIcon}
        onClick={downloadAndSaveFile}
        dataTestId="markup-side-panel-attachment-item-download-icon"
        iconButtonProps={iconBtnProps ? iconBtnProps : {}}
      />
      {hasPermissionToEditMarkup && (
        <>
          <SphereActionDivider />
          <FaroIconButton
            buttonSize={buttonSize}
            iconSize={iconSize}
            component={DeleteIcon}
            onClick={() => setIsDeleteModalOpen(true)}
            dataTestId="markup-side-panel-attachment-item-delete-icon"
            iconButtonProps={iconBtnProps ? iconBtnProps : {}}
          />
        </>
      )}
      <Box onClick={(e) => e.stopPropagation()}>
        <FaroDialog
          title="Delete attachment?"
          confirmText="Delete"
          open={isDeleteModalOpen}
          onConfirm={deleteAttachment}
          onClose={() => setIsDeleteModalOpen(false)}
          isConfirmLoading={isDeleting}
          confirmButtonColor="red"
        >
          <Typography
            data-testid="delete-annotation-attachment-dialog-content"
            sx={{ fontSize: "14px", color: sphereColors.gray800 }}
          >
            This will remove the <var>{attachment.name}</var> from your project.
          </Typography>
        </FaroDialog>
      </Box>
    </Box>
  );
}
