import { Constraint, SubscriptionId } from "@custom-types/subscription-types";
import { AdminAPITypes } from "@stellar/api-logic";

/** URL for the page to request a quote on a subscription */
export const REQUEST_A_QUOTE_URL = "https://www.holobuilder.com/pricing";

/** Name of each subscription */
export const SUBSCRIPTION_NAME: { [key in SubscriptionId]: string } = {
  standard360: "360° Standard",
  standardPointCloud: "Point Cloud Standard",
  pro360: "360° Professional",
  proPointCloud: "Point Cloud Professional",
  enterprise360: "360° Enterprise",
  enterprisePointCloud: "Point Cloud Enterprise",
  enterprise360PointCloud: "Point Cloud & 360° Enterprise",
  trial360: "360° Trial",
  trialPointCloud: "Point Cloud Trial",
  trial360PointCloud: "Point Cloud & 360° Trial",
};

/** Constraints of each subscription */
export const SUBSCRIPTION_CONSTRAINTS: {
  [key in SubscriptionId]: Constraint[];
} = {
  standard360: [
    {
      type: AdminAPITypes.EConstraintType.userRole,
      max: 3,
    },
    {
      type: AdminAPITypes.EConstraintType.projectCount,
      max: 3,
    },
  ],
  standardPointCloud: [
    {
      type: AdminAPITypes.EConstraintType.userRole,
      max: 3,
    },
  ],
  pro360: [
    {
      type: AdminAPITypes.EConstraintType.userRole,
      max: 10,
    },
    {
      type: AdminAPITypes.EConstraintType.projectCount,
      max: 10,
    },
  ],
  proPointCloud: [
    {
      type: AdminAPITypes.EConstraintType.userRole,
      max: 10,
    },
  ],
  enterprise360: [],
  enterprisePointCloud: [],
  enterprise360PointCloud: [],
  trial360: [],
  trialPointCloud: [],
  trial360PointCloud: [],
};

/** Label for each constraint type */
export const CONSTRAINT_LABEL: {
  [key in AdminAPITypes.EConstraintType]: string;
} = {
  [AdminAPITypes.EConstraintType.projectArea]: "Area",
  [AdminAPITypes.EConstraintType.projectCount]: "Active projects",
  [AdminAPITypes.EConstraintType.userCount]: "Users",
  [AdminAPITypes.EConstraintType.waypoint]: "Waypoints",
  [AdminAPITypes.EConstraintType.timetravelOnWaypoint]: "Time travel waypoints",
  [AdminAPITypes.EConstraintType.storage]: "Storage",
  [AdminAPITypes.EConstraintType.userRole]: "Active members",
};

interface ConstraintNoun {
  singular: string;
  plural: string;
}

/** Noun for each constraint type */
export const CONSTRAINT_NOUN: {
  [key in AdminAPITypes.EConstraintType]: ConstraintNoun;
} = {
  [AdminAPITypes.EConstraintType.projectArea]: {
    singular: "",
    plural: "",
  },
  [AdminAPITypes.EConstraintType.projectCount]: {
    singular: "project",
    plural: "projects",
  },
  [AdminAPITypes.EConstraintType.userCount]: {
    singular: "",
    plural: "",
  },
  [AdminAPITypes.EConstraintType.waypoint]: {
    singular: "",
    plural: "",
  },
  [AdminAPITypes.EConstraintType.timetravelOnWaypoint]: {
    singular: "",
    plural: "",
  },
  [AdminAPITypes.EConstraintType.storage]: {
    singular: "",
    plural: "",
  },
  [AdminAPITypes.EConstraintType.userRole]: {
    singular: "member",
    plural: "members",
  },
};
