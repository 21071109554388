import { FileUploadTask } from "@custom-types/file-upload-types";
import {
  createEntityAdapter,
  createSlice,
  EntityAdapter,
  EntityId,
} from "@reduxjs/toolkit";
import { BaseEntityState } from "@store/store-types";

/** Creates an entity adapter to store a map with all the upload tasks */
export const uploadTasksAdapter: EntityAdapter<FileUploadTask, EntityId> =
  createEntityAdapter({
    selectId: (uploadTask): EntityId => uploadTask.id,
  });

/** Initial uploadTasks state */
export const initialState: BaseEntityState<FileUploadTask> = {
  ...uploadTasksAdapter.getInitialState(),
};

/** Slice to access the state of the upload tasks */
const uploadTasksSlice = createSlice({
  name: "uploadTasks",
  initialState,
  reducers: {
    /**
     * Accepts a single upload task entity and adds or replaces it.
     *
     * @param state store state
     * @param entity task to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setOne: uploadTasksAdapter.setOne,
    /**
     * Accepts an array of upload task entities, and adds or replaces them.
     *
     * @param state store state
     * @param entity tasks to be set or added to the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    setMany: uploadTasksAdapter.setMany,

    /**
     * Accepts id and changes of a task entities, and update in store.
     *
     * @param state store state
     * @param entity task changes to update the task
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    updateOne: uploadTasksAdapter.updateOne,

    /**
     * Accepts a single task id, and removes the task entity with that ID if it exists.
     *
     * @param state store state
     * @param entity task Id to be removed from the store.
     *
     * @see https://redux-toolkit.js.org/api/createEntityAdapter#crud-functions
     */
    removeOne: uploadTasksAdapter.removeOne,

    /** Resets the upload task store to its initial state. */
    resetUploadTasksState: () => initialState,
  },
});

export const { setOne, setMany, removeOne, updateOne, resetUploadTasksState } =
  uploadTasksSlice.actions;

export const uploadTasksReducer = uploadTasksSlice.reducer;
