import { runtimeConfig } from "@src/runtime-config";
import { APITypes } from "@stellar/api-logic";
import { BackgroundTaskState, CaptureTreeEntityType, CaptureTreePointCloudType } from "@faro-lotv/service-wires";
import { FileUploadTask } from "@custom-types/file-upload-types";
import { TableItem, TableItemState, WorkflowState } from "@pages/project-details/project-data-management/data-management-types";
import { getGlsUuid } from "@pages/project-details/project-data-management/import-data/import-data-utils";
import { RegisteredDataBase } from "@pages/project-details/project-data-management/registered-data/registered-data-types";
import { UploadedElsScan } from "@pages/project-details/project-data-management/uploaded-data/uploaded-data-types";
import { ICompanyMemberBase } from "@stellar/api-logic/dist/api/core-api/sphere-dashboard-api-types";
import { isElsScanFileUploadTaskContext } from "@custom-types/file-upload-type-guards";
import { getScanByFilename } from "@api/stagingarea-api/stagingarea-api";

/** Specify base number to parse integers from */
const RADIX = 10;

/**
 * Constructs a URL for publishing a registration revision for a specified project.
 *
 * @param {APITypes.ProjectId} projectId - The ID of the project for which the registration is being published.
 * @param {string} revisionId - The ID of the registration revision to be published.
 * @returns {string} A URL object pointing to the endpoint for publishing the specified registration revision.
 */
export function getInspectAndPublishToolUrl(
  projectId: APITypes.ProjectId,
  revisionId: string
): string {
  const url = new URL(
    `/data-preparation/project/${projectId}`,
    runtimeConfig.urls.sphereViewerUrl
  );
  url.searchParams.append("revisionId", revisionId);
  return url.href;
}

/**
 * Returns a map of the IDs of the uploaded ELS scans.
 * @param uploadedEntities Array of uploaded entities returned by uploadedDataSelector.
 */
export function getUploadedIdsMap(uploadedEntities: UploadedElsScan[]): { [key: string]: boolean } {
  const idMap: { [key: string]: boolean } = {};
    for (const uploadedEntity of uploadedEntities) {
      if (!uploadedEntity.pointClouds) {
        continue;
      }
      for (const pointCloud of uploadedEntity.pointClouds) {
        if (pointCloud.type === CaptureTreePointCloudType.elsRaw && pointCloud.externalId) {
          idMap[pointCloud.externalId] = true;
        }
      }
    }
    return idMap;
}

/**
 * Creates table entries for the scan table shown in the Staging Area based on the provided upload tasks.
 * @param uploadTasks Array of upload tasks for the current project.
 * @param uploadedIdsMap Result of the getUploadedIdsMap function.
 * @param currentUser Current user returned by currentUserSelector.
 * @returns Object that also contains if at least one upload task is currently active and if there is at least one
 *          upload error.
 */
export function getTableItemsFromTasks(
  uploadTasks: FileUploadTask[],
  uploadedIdsMap: { [key: string]: boolean },
  currentUser: ICompanyMemberBase | null
) : {
  tableItemsFromTasks: TableItem[],
  isUploadingFromTasks: boolean,
  hasUploadErrorFromTasks: boolean,
} {
  const tableItemsFromTasks: TableItem[] = [];
  let isUploadingFromTasks: boolean = false;
  let hasUploadErrorFromTasks: boolean = false;

  for (const task of uploadTasks) {
    const lsDataV2 = isElsScanFileUploadTaskContext(task.context) ? task.context.lsDataV2 : null;
    const scan = getScanByFilename(task.fileName, lsDataV2);
    const id = getGlsUuid(task.fileName, lsDataV2);
    if (id && uploadedIdsMap[id]) {
      continue;
    }

    let itemState: TableItemState;
    switch (task.status) {
      case BackgroundTaskState.started:
        itemState = "uploading";
        break;
      case BackgroundTaskState.succeeded:
        // It takes a while until a finished upload task results in an added entity to uploadedEntities in which case
        // the uploadedFilenamesMap check above resolves to true.
        // We have to handle this by marking the upload as still in progress in this case.
        isUploadingFromTasks = true;
        // "processing" would be confusing to the user.
        itemState = "uploading";
        break;
      case BackgroundTaskState.failed:
        itemState = "error";
        hasUploadErrorFromTasks = true;
        break;
      case BackgroundTaskState.aborted:
        // User-aborted tasks are not considered as upload errors so we don't set hasUploadErrorFromTasks to true here.
        itemState = "error";
        break;
      case BackgroundTaskState.created:
      case BackgroundTaskState.scheduled:
      default:
        itemState = "start";
        break;
    }

    const item: TableItem = {
      id: task.id,
      name: scan?.name || task.fileName,
      createdAt: task.createdAt,
      // Since it's only possible to see the uploads from the current user then one can correctly assume that all
      // local uploads are from the current user.
      createdBy: currentUser?.id ?? "",
      deviceType: CaptureTreeEntityType.elsScan,
      state: itemState,
      progress: task.progress,
    };
    tableItemsFromTasks.push(item);
  }

  return {
    tableItemsFromTasks,
    isUploadingFromTasks,
    hasUploadErrorFromTasks,
  };
}

/**
 * Creates table entries for the scan table shown in the Staging Area based on the provided uploaded entities.
 * @param uploadedEntities Array of uploaded entities returned by uploadedDataSelector.
 * @param state State of the Staging Area workflow.
 */
export function getTableItemsFromEntities(
  state: WorkflowState,
  uploadedEntities: UploadedElsScan[]
): TableItem[] {
  const tableItemsFromUploads: TableItem[] = [];

  for (const upload of uploadedEntities) {
    let itemState: TableItemState;
    if (upload.isProcessing) {
      itemState = "processing";
    } else if (upload.hasTaskErrors) {
      itemState = "error";
    } else if (state === "published") {
      itemState = "published";
    } else {
      itemState = "unpublished";
    }

    const item: TableItem = {
      id: upload.id,
      name: upload.name,
      createdAt: upload.createdAt,
      createdBy: upload.createdBy,
      deviceType: upload.type,
      state: itemState,
    };
    tableItemsFromUploads.push(item);
  }

  return tableItemsFromUploads;
}

/**
 * Returns the progress of the upload tasks by calculating the average progress value.
 * This wouldn't work well if the uploaded files had very different file sizes. But this shouldn't be much of a problem
 * for our use case in the Staging Area, so it should be a reasonably good heuristic.
 * @param uploadTasks Array of upload tasks for the current project.
 */
function getUploadProgressFromTasks(uploadTasks: FileUploadTask[]): number {
  if (!uploadTasks.length) {
    return 0;
  }
  let sumProgress = 0;

  for (const task of uploadTasks) {
    switch (task.status) {
      case BackgroundTaskState.succeeded:
      case BackgroundTaskState.aborted:
      case BackgroundTaskState.failed:
        // We treat errors as the task having finished for the sake of the progress bar.
        sumProgress += 100;
        break;
      case BackgroundTaskState.created:
      case BackgroundTaskState.scheduled:
      case BackgroundTaskState.started:
      default:
        sumProgress += task.progress ?? 0;
        break;
    }
  }

  return sumProgress / uploadTasks.length;
}

/**
 * Returns the upload progress.
 * @param state State of the Staging Area workflow.
 * @param uploadTasks Array of upload tasks for the current project.
 */
export function getUploadProgress(state: WorkflowState, uploadTasks: FileUploadTask[]): number {
  switch (state) {
    case "start":
      return 0;
    case "uploading":
    case "uploadError": {
      const progress = getUploadProgressFromTasks(uploadTasks);
      // There's unfortunately an additional waiting time of various length from the moment when all upload tasks report
      // 100% and the start of the processing step. Instead of showing 100%, we rather show 98% to indicate that there's
      // still work getting done.
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- See comment above
      return Math.min(progress, 98);
    }
    case "uploaded":
    case "processing":
    case "processError":
    case "processed":
    case "registering":
    case "registerError":
    case "registerBadResult":
    case "registered":
    case "publishing":
    case "publishError":
    case "published":
      return 100;
    default:
      return 0;
  }
}

/**
 * Returns the progress for the processing of the provided entities.
 * @param uploadedEntities Array of uploaded entities returned by uploadedDataSelector.
 */
export function getProcessProgressFromEntities(uploadedEntities: UploadedElsScan[]): number {
  // TODO: Get actual value after made available by changes for https://faro01.atlassian.net/browse/TF-1699
  return -1;

  // if (!uploadedEntities.length) {
  //   return 0;
  // }
  // let sumProgress = 0;

  // for (const upload of uploadedEntities) {
  //   // isProcessing also returns false if processing hasn't started yet. So also check the desired result.
  //   // There needs to be the initial ElsRaw and the created E57 point cloud.
  //   const isProcessed = !upload.isProcessing && upload.pointClouds?.length && 2 <= upload.pointClouds.length;

  //   if (isProcessed) {
  //     sumProgress += 100;
  //   } else if (upload.isProcessing) {
  //     ...
  //   }
  // }

  // return sumProgress / uploadedEntities.length;
}

/**
 * Returns the processing progress.
 * @param state State of the Staging Area workflow.
 * @param uploadedEntities Array of uploaded entities returned by uploadedDataSelector.
 */
export function getProcessProgress(state: WorkflowState, uploadedEntities: UploadedElsScan[]): number {
  switch (state) {
    case "start":
    case "uploading":
    case "uploadError":
    case "uploaded":
    case "processError":
      return 0;
    case "processing":
      return getProcessProgressFromEntities(uploadedEntities);
    case "processed":
    case "registering":
    case "registerError":
    case "registerBadResult":
    case "registered":
    case "publishing":
    case "publishError":
    case "published":
      return 100;
    default:
      return 0;
  }
}

/**
 * Returns the progress of the provided registration task.
 * @param registeredEntity Registered entity that's the first entry in the array returned by registeredDataSelector.
 */
export function getRegisterProgressFromEntities(registeredEntity?: RegisteredDataBase): number {
  const task = registeredEntity?.task;
  if (!task) {
    return 0;
  }

  switch (task.status) {
    case "Succeeded":
    case "Aborted":
    case "Failed":
      // We treat errors as the task having finished for the sake of the progress bar.
      return 100;
    case "Pending":
    case "Created":
    case "Scheduled":
    case "Started":
    default: {
      // Cut off the % suffix added by generateSdbBackgroundTaskProgress.
      if (task.progress?.endsWith("%")) {
        const progressStr = task.progress.substring(0, task.progress.length - 1);
        return parseInt(progressStr, RADIX);
      } else {
        return 0;
      }
    }
  }
}

/**
 * Returns the registration progress.
 * @param state State of the Staging Area workflow.
 * @param registeredEntity Registered entity that's the first entry in the array returned by registeredDataSelector.
 */
export function getRegisterProgress(state: WorkflowState, registeredEntity?: RegisteredDataBase): number {
  switch (state) {
    case "start":
    case "uploading":
    case "uploadError":
    case "uploaded":
    case "processing":
    case "processError":
    case "registerError":
    case "registerBadResult":
    case "processed":
      return 0;
    case "registering":
      return getRegisterProgressFromEntities(registeredEntity);
    case "registered":
    case "publishing":
    case "publishError":
    case "published":
      return 100;
    default:
      return 0;
  }
}
