import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { GUID } from "@faro-lotv/foundation";
import {
  GridSortCellParams,
  gridStringOrNumberComparator,
} from "@mui/x-data-grid-pro";
import { MemberTypes } from "@custom-types/member-types";
import { getMarkupAssigneeName } from "@pages/project-details/project-markups/markup-assignee-utils";
import { Markup } from "@custom-types/project-markups-types";
import { AnnotationStatus } from "@faro-lotv/ielement-types";

/** Headers for the project markups table */
export enum ProjectMarkupsTableHeaders {
  /** Markup name */
  name = "Name",

  /** Markup assignee */
  assignee = "Assignee",

  /** Markup due date */
  dueDate = "Due Date",

  /** Markup status */
  status = "Status",

  /** Markup location which is the combination of sheet and scene */
  location = "Sheet / Scene",

  /** Markup sheet */
  sheet = "Sheet",

  /** Markup scene */
  scene = "Scene",

  /** Markup external integration sync */
  syncWith = "Sync with",

  /** Markup options */
  options = "Options",

  /** Markup tags */
  tags = "Tags",

  /** Markup attachments */
  attachments = "Attachments",
}

/** Sorts the project markups based on status */
export function markupStatusComparator(
  v1: AnnotationStatus,
  v2: AnnotationStatus
): number {
  const statusOrder: AnnotationStatus[] = [
    AnnotationStatus.Unclassified,
    AnnotationStatus.Open,
    AnnotationStatus.InProgress,
    AnnotationStatus.ToReview,
    AnnotationStatus.Resolved,
  ];

  return statusOrder.indexOf(v1) - statusOrder.indexOf(v2);
}

// TODO: Add alphabetical sorting to tags: https://faro01.atlassian.net/browse/ST-2090
/**
 * Sorts the project markups based on tags
 * The rows with more tags will be shown first
 */
export function markupLabelComparator(id1: GUID, id2: GUID): number {
  if (!id1) {
    return -1;
  }
  if (!id2) {
    return 1;
  }

  return id1.split(",").length - id2.split(",").length;
}

/** Sorts the project markups based on assignee name */
export function markupAssigneeComparator(
  cellParams1: GridSortCellParams<GUID>,
  cellParams2: GridSortCellParams<GUID>,
  companyMembers: MemberTypes[],
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[]
): number {
  const row1Entity = cellParams1.api.getRow(cellParams1.id).entity;
  const row2Entity = cellParams2.api.getRow(cellParams2.id).entity;

  const assigneeName1 = getMarkupAssigneeName({
    assignee: row1Entity.assignee,
    companyMembers,
    projectMembers,
  });

  const assigneeName2 = getMarkupAssigneeName({
    assignee: row2Entity.assignee,
    companyMembers,
    projectMembers,
  });

  return gridStringOrNumberComparator(
    assigneeName1,
    assigneeName2,
    cellParams1,
    cellParams2
  );
}

/** Sorts the project markups based on sheet name */
export function markupSheetComparator(
  cellParams1: GridSortCellParams,
  cellParams2: GridSortCellParams
): number {
  const row1Entity: Markup = cellParams1.api.getRow(cellParams1.id).entity;
  const row2Entity: Markup = cellParams2.api.getRow(cellParams2.id).entity;

  const sheetName1 = row1Entity.areaSection?.name ?? "";
  const sheetName2 = row2Entity.areaSection?.name ?? "";

  return gridStringOrNumberComparator(
    sheetName1,
    sheetName2,
    cellParams1,
    cellParams2
  );
}

/** Sorts the project markups based on scene name */
export function markupSceneComparator(
  cellParams1: GridSortCellParams,
  cellParams2: GridSortCellParams
): number {
  const row1Entity: Markup = cellParams1.api.getRow(cellParams1.id).entity;
  const row2Entity: Markup = cellParams2.api.getRow(cellParams2.id).entity;

  const sceneName1 = row1Entity.section?.name ?? "";
  const sceneName2 = row2Entity.section?.name ?? "";

  return gridStringOrNumberComparator(
    sceneName1,
    sceneName2,
    cellParams1,
    cellParams2
  );
}

/** Sorts the project markups based on tags */
export function markupTagsComparator(
  cellParams1: GridSortCellParams,
  cellParams2: GridSortCellParams
): number {
  const row1Entity: Markup = cellParams1.api.getRow(cellParams1.id).entity;
  const row2Entity: Markup = cellParams2.api.getRow(cellParams2.id).entity;

  const labels1 = row1Entity.labels?.map((label) => label.name).sort() ?? [];
  const labels2 = row2Entity.labels?.map((label) => label.name).sort() ?? [];

  if (labels1.length !== labels2.length) {
    return gridStringOrNumberComparator(
      labels1.length,
      labels2.length,
      cellParams1,
      cellParams2
    );
  }

  const differentIndex = labels1.findIndex((label1, index) => {
    return label1 !== labels2[index];
  });

  return gridStringOrNumberComparator(
    labels2[differentIndex],
    labels1[differentIndex],
    cellParams1,
    cellParams2
  );
}
