import { APITypes } from "@stellar/api-logic";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

/**
 * This type is the combination of workspace and company
 * The purpose of it is to remove the confusion between using company or workspaces
 * and have a unique type that covers both the workspaces and companies
 */
export type SdbCompany = Pick<
  SphereDashboardAPITypes.ICompanyWithRoleDetails,
  "domain" | "id" | "logoUrl" | "name" | "tags"
> &
  Pick<SphereDashboardAPITypes.IWorkspace, "apiUrl" | "type" | "url"> & {
    role: {
      /** Role of user in company. Optional if workspace type is admin */
      company?: CoreAPITypes.EUserCompanyRole;

      /** Role of user in workspace */
      workspace: SphereDashboardAPITypes.EWorkspaceRole;
    };

    /** Defines that this company was migrated from Sphere Legacy */
    isSphereLegacyMigrated: boolean;
  };

/** Base props for companyId */
export interface BaseCompanyIdProps {
  /** The ID of the company */
  companyId: APITypes.CompanyId;
}

/** Base props for projectId */
export interface BaseProjectIdProps {
  /** The ID of the project */
  projectId: APITypes.ProjectId;
}

/** Base props for groupId */
export interface BaseGroupIdProps {
  /** The ID of the group */
  groupId: APITypes.GroupId;
}

/** Base props for userId */
export interface BaseUserIdProps {
  /** The ID of the current user */
  userId: APITypes.UserId;
}

/** Base props for teamId */
export interface BaseTeamIdProps {
  /** The ID of the team */
  teamId: SphereDashboardAPITypes.TeamId;
}

/** Defines the available options for opening the project. */
export enum ProjectLaunchTarget {
  /** Open the project in the Sphere Viewer */
  sphereViewer = "SphereViewer",

  /** Open the project in the WebEditor */
  webEditor = "WebEditor",
}
