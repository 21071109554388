import { FARO_CARD_IMAGE_OVERLAY_CLASS } from "@components/common/faro-card/faro-card";
import { Box } from "@mui/material";
import { sphereColors, EDecimalToHex } from "@styles/common-colors";
import { addTransparency } from "@utils/ui-utils";

/** Height for the card thumbnail image */
const IMAGE_HEIGHT = "150px";

interface Props {
  /** The src for the image to be displayed */
  src: string;
}

/**
 * Component that shows the image of a card.
 * It has an overlay that is shown on hover, and makes sure to fit the image to the size of the card.
 */
export function FaroCardImage({
  src,
}: Props): JSX.Element {
  return (
    <Box component="div">
        {/* Overlay on top of the image that is shown on hover
          This makes that images with white background have the exact same gray background color
          as the middle and bottom part of the card  */}
        <Box
          component="div"
          className={FARO_CARD_IMAGE_OVERLAY_CLASS}
          sx={{
            // Hidden by default and it is overridden to visible by the parent component on hover.
            visibility: "hidden",
            height: IMAGE_HEIGHT,
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: addTransparency({
              color: sphereColors.gray100,
              alpha: EDecimalToHex.twentySix,
            }),
          }}
        />
        <Box
          component="div"
          sx={{
            height: IMAGE_HEIGHT,
            width: "100%",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              height: "auto",
              maxHeight: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            src={src}
          />
        </Box>
      </Box>
  );
}