import { selectedCompanyContextSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { currentUserSelector } from "@store/user/user-selector";
import { hasUserValidRoleCompanyLevel } from "@utils/access-control/company/company-access-control";
import {
  HasUserValidRoleCompanyLevelProps,
  RequiredRoleCompanyLevelName,
} from "@utils/access-control/company/company-access-control-types";

export type ReturnProps = {
  [key in RequiredRoleCompanyLevelName]: boolean;
} & {
  /** Wrapper to check if user has a permission to do given action (by role name) or not */
  hasUserPermissionCompanyLevel: ({
    roleName,
    memberId,
  }: Pick<
    HasUserValidRoleCompanyLevelProps<RequiredRoleCompanyLevelName>,
    "memberId" | "roleName"
  >) => boolean;
};

/** Checks whether a user has permission for an action on company level or not */
export function useHasUserValidRoleCompanyLevel({
  memberId,
}: Pick<
  HasUserValidRoleCompanyLevelProps<RequiredRoleCompanyLevelName>,
  "memberId"
> = {}): ReturnProps {
  const currentUser = useAppSelector(currentUserSelector);
  const companyContext = useAppSelector(selectedCompanyContextSelector);

  const canViewAllCompanyUsers = hasUserValidRoleCompanyLevel({
    roleName: RequiredRoleCompanyLevelName.canViewAllCompanyUsers,
    currentUser,
    companyContext,
  });

  const canViewCompanyMemberProfile = hasUserValidRoleCompanyLevel({
    currentUser,
    roleName: RequiredRoleCompanyLevelName.canViewCompanyMemberProfile,
    memberId,
    companyContext,
  });

  const canChangeDisplayName = false;

  const canCreateProjects = hasUserValidRoleCompanyLevel({
    roleName: RequiredRoleCompanyLevelName.canCreateProjects,
    currentUser,
    companyContext,
  });

  const canInviteUsersToCompany = hasUserValidRoleCompanyLevel({
    roleName: RequiredRoleCompanyLevelName.canInviteUsersToCompany,
    currentUser,
    companyContext,
  });

  const canEditMember = hasUserValidRoleCompanyLevel({
    roleName: RequiredRoleCompanyLevelName.canEditMember,
    currentUser,
    companyContext,
  });

  const canDeleteMemberFromCompany = hasUserValidRoleCompanyLevel({
    roleName: RequiredRoleCompanyLevelName.canDeleteMemberFromCompany,
    currentUser,
    companyContext,
  });

  const canViewAllCompanyGroups = hasUserValidRoleCompanyLevel({
    roleName: RequiredRoleCompanyLevelName.canViewAllCompanyGroups,
    currentUser,
    companyContext,
  });

  const canCreateGroups = hasUserValidRoleCompanyLevel({
    roleName: RequiredRoleCompanyLevelName.canCreateGroups,
    currentUser,
    companyContext,
  });

  // Set to false as they are not used in the Dashboard yet. The correct code should be implement when needed
  const canViewAnalytics = false;
  const canViewWorkspaceSettings = false;
  const canViewPremiumAnalytics = false;

  const canEditDefaultPlatform = hasUserValidRoleCompanyLevel({
    currentUser,
    roleName: RequiredRoleCompanyLevelName.canEditDefaultPlatform,
    companyContext,
  });

  const canViewTeams = hasUserValidRoleCompanyLevel({
    currentUser,
    roleName: RequiredRoleCompanyLevelName.canViewTeams,
    companyContext,
  });

  const canCreateNewTeam = hasUserValidRoleCompanyLevel({
    currentUser,
    roleName: RequiredRoleCompanyLevelName.canCreateNewTeam,
    companyContext,
  });

  const canDeleteMembersFromTeam = hasUserValidRoleCompanyLevel({
    currentUser,
    roleName: RequiredRoleCompanyLevelName.canDeleteMembersFromTeam,
    companyContext,
  });

  const canEditTeams = hasUserValidRoleCompanyLevel({
    currentUser,
    roleName: RequiredRoleCompanyLevelName.canEditTeams,
    companyContext,
  });

  const canDeleteTeams = hasUserValidRoleCompanyLevel({
    currentUser,
    roleName: RequiredRoleCompanyLevelName.canDeleteTeams,
    companyContext,
  });

  const canInviteMembersToTeam = hasUserValidRoleCompanyLevel({
    currentUser,
    roleName: RequiredRoleCompanyLevelName.canInviteMembersToTeam,
    companyContext,
  });

  const canViewSubscriptionDetails = hasUserValidRoleCompanyLevel({
    currentUser,
    roleName: RequiredRoleCompanyLevelName.canViewSubscriptionDetails,
    companyContext,
  });

  function hasUserPermissionCompanyLevel({
    roleName,
    memberId,
  }: Pick<
    HasUserValidRoleCompanyLevelProps<RequiredRoleCompanyLevelName>,
    "memberId" | "roleName"
  >): boolean {
    return hasUserValidRoleCompanyLevel({
      roleName,
      currentUser,
      memberId,
      companyContext,
    });
  }

  return {
    canViewAllCompanyUsers,
    canViewCompanyMemberProfile,
    canChangeDisplayName,
    canCreateProjects,
    canInviteUsersToCompany,
    canEditMember,
    canDeleteMemberFromCompany,
    canViewAllCompanyGroups,
    canCreateGroups,
    canViewAnalytics,
    canViewWorkspaceSettings,
    canViewPremiumAnalytics,
    canEditDefaultPlatform,
    canCreateNewTeam,
    canDeleteMembersFromTeam,
    canViewTeams,
    canEditTeams,
    canDeleteTeams,
    canInviteMembersToTeam,
    canViewSubscriptionDetails,
    hasUserPermissionCompanyLevel,
  };
}
