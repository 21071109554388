import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { useProjectIntegrations } from "@pages/project-details/project-integrations/use-project-integrations";
import { NoIntegration } from "@pages/integrations/no-integration";
import { IntegrationSection } from "@pages/integrations/integration-section";

export function ProjectIntegrations({
  projectId,
}: BaseProjectIdProps): JSX.Element {
  const {
    connectProject,
    disconnectProject,
    enabledWorkspaceIntegrations,
    enabledProjectIntegrations,
  } = useProjectIntegrations({
    projectId,
  });

  return (
    <>
      {enabledProjectIntegrations.length === 0 ? (
        <NoIntegration subject="project" />
      ) : (
        <IntegrationSection
          title="Connected"
          integrations={enabledProjectIntegrations}
          actionButtonText="DisconnectFromProject"
          onIntegrationAction={disconnectProject}
        />
      )}

      {enabledWorkspaceIntegrations.length !== 0 && (
        <IntegrationSection
          title="Available apps"
          integrations={enabledWorkspaceIntegrations}
          actionButtonText="ConnectToProject"
          onIntegrationAction={connectProject}
        />
      )}
    </>
  );
}
