import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { exponentialBackOff, retry } from "@faro-lotv/foundation";
import { ApiClient } from "@stellar/api-logic";
import {
  ChunkUploadRequestDescription,
  IChunkUploadResponse,
} from "@stellar/api-logic/dist/api/core-api/sphere-dashboard-api-types";
import { FILE_SIZE_MULTIPLIER, isValidFileExtension } from "@utils/file-utils";
import SparkMD5 from "spark-md5";

/** Higher numbers starting from this considers error code */
const SMALLEST_ERROR_CODE = 300;

/**
 * Maximum file name length.
 * This is restriction set by the Project API where mutations to add files fail if the name is longer than 200
 */
const MAX_LENGTH_NAME = 200;

type FinalizeUpload = {
  /** The URL of the uploaded file at the remote location */
  downloadUrl: string;

  /** The hash of the uploaded file */
  md5: string;
};

type IsValidFile = {
  /** The file to validate */
  file: File;

  /** The hash of the uploaded file */
  allowedExtensions: string[];

  /** Maximum file size in MB */
  maxFileSize: number;
};

type IsValidFileResponse = {
  /** True if the file is valid */
  isValid: boolean;

  /** The message about the invalidity of the file */
  message?: string;

  /** Description about the reason why the file is invalid */
  description?: string;
};

interface DoUploadParams extends BaseProjectIdProps {
  /** The file to upload */
  file: File;

  /** Core api client to send request through */
  coreApiClient: ApiClient;

  /** Callback function for retrieving the upload progress */
  onUploadProgress(progress: ProgressEvent | number): void;
}

/**
 * Upload a single chunk to the backend
 *
 * @param fileSize Total size of the file
 * @param chunk Single chunk request data
 * @param blobData A single chunk as ArrayBuffer to upload
 * @param coreApiClient Core api client to send request through to upload asset
 * @param bytesUploaded Total file size already uploaded
 * @param onUploadProgress Callback function to show progress
 * @returns boolean True if the chunk uploaded successfully, otherwise false
 */
async function uploadChunk(
  fileSize: number,
  chunk: ChunkUploadRequestDescription,
  blobData: ArrayBuffer,
  coreApiClient: ApiClient,
  bytesUploaded: number,
  onUploadProgress: (progress: number) => void
): Promise<boolean> {
  // upload chunk
  const response = await retry(
    () =>
      coreApiClient.V3.SDB.uploadAsset({
        method: chunk.method,
        uploadUrl: chunk.url,
        file: blobData,
        type: "application/octet-stream",
        headers: { ...chunk.headers },
        onUploadProgress: (event) => {
          onProgress(event, fileSize, bytesUploaded, onUploadProgress);
        },
      }),
    {
      max: 5,
      delay: exponentialBackOff,
    }
  );

  // handle error
  if (!response.isUploaded || response.status >= SMALLEST_ERROR_CODE) {
    throw new Error(response.statusText);
  }

  return true;
}

/**
 * Calculates the total upload progress while chunk uploading
 *
 * @param event The progress event of a chunk upload
 * @param fileSize Total file size
 * @param bytesUploaded Total file size that already uploaded
 * @param onUploadProgress Call back function to show the progress
 */
function onProgress(
  event: ProgressEvent,
  fileSize: number,
  bytesUploaded: number,
  onUploadProgress: (progress: number) => void
): void {
  const totalProgress = ((bytesUploaded + event.loaded) / fileSize) * 100;

  onUploadProgress(totalProgress);
}

/**
 * Finalize the chunk upload to the backend
 *
 * @param chunkUploadDescription the descriptor for the entire upload
 * @param spark Array buffer to compute entire file MD5
 * @param finalizer Function to commit the chunk upload
 */
async function finalizeUpload(
  chunkUploadDescription: IChunkUploadResponse,
  spark: SparkMD5.ArrayBuffer
): Promise<FinalizeUpload> {
  const { finalize, downloadUrl } = chunkUploadDescription;

  // Finalize chunk upload
  const finalizeResponse = await fetch(finalize.url, {
    method: finalize.method,
    headers: { ...finalize.headers },
    body: finalize.body,
  });

  // check error
  if (!finalizeResponse.ok || finalizeResponse.status >= SMALLEST_ERROR_CODE) {
    throw new Error(finalizeResponse.statusText);
  }

  const md5 = spark.end();

  return {
    downloadUrl,
    md5,
  };
}

/**
 * Performs the chunk uploading.
 */
export async function doUpload({
  file,
  projectId,
  coreApiClient,
  onUploadProgress,
}: DoUploadParams): Promise<FinalizeUpload> {
  try {
    const { token } = await coreApiClient.V3.SDB.getUserProjectToken(projectId);

    // Get description on how to split the chunked upload.
    const chunkUploadDescription =
      await coreApiClient.V3.SDB.generateChunkUploadData({
        projectId,

        // without the mimetype below, the whole upload does not work.
        contentType: "application/octet-stream",
        downloadName: file.name,
        size: file.size,
        token,
      });

    // initialize MD5 computation
    const spark = new SparkMD5.ArrayBuffer();

    let bytesUploaded = 0;

    // progressively upload all file chunks
    for (const chunk of chunkUploadDescription.chunks) {
      const startByte = chunk.bytes.start;
      const endByte = chunk.bytes.start + chunk.bytes.length;
      const blob = file.slice(startByte, endByte);
      const blobData = await blob.arrayBuffer();
      spark.append(blobData);

      if (
        !(await uploadChunk(
          file.size,
          chunk,
          blobData,
          coreApiClient,
          bytesUploaded,
          onUploadProgress
        ))
      ) {
        throw new Error("Upload failed because of an unknown error.");
      }
      bytesUploaded += chunk.bytes.length;
    }

    return await finalizeUpload(chunkUploadDescription, spark);
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error("Upload failed because of an unknown error.");
    }
  }
}

/**
 * Validates a file based on the params
 *
 * @returns IsValidFileResponse based on the validity of the file
 */
export function isValidFile({
  file,
  allowedExtensions,
  maxFileSize,
}: IsValidFile): IsValidFileResponse {
  if (!isValidFileExtension(file, allowedExtensions)) {
    return {
      isValid: false,
      message: "Invalid file type",
      description: "The selected file is not valid type to upload.",
    };
  }

  if (file.size > maxFileSize * FILE_SIZE_MULTIPLIER * FILE_SIZE_MULTIPLIER) {
    return {
      isValid: false,
      message: "File size exceeds the maximum limit",
      description: `Please select a file with a size not exceeding ${maxFileSize} MB.`,
    };
  }

  if (file.name.length > MAX_LENGTH_NAME) {
    return {
      isValid: false,
      message: "File name is too long",
      description:
        "The file name must have a maximum length of 200 characters.",
    };
  }

  return { isValid: true };
}
