import { FaroStatusText } from "@faro-lotv/flat-ui";
import { AnnotationStatus } from "@faro-lotv/ielement-types";
import { sphereColors } from "@styles/common-colors";

/** Different colors of the status */
export interface MarkupStatusColorReturn {
  /** The color of the icon */
  icon: string;

  /** The color of the icon's background color */
  iconBackground: string;

  /** The color of the icon's border color */
  iconBorder: string;
}

/** Contains different colors for (icon, icon-background, icon-border) based on the status */
export const markupStatusColor: Record<
  AnnotationStatus,
  MarkupStatusColorReturn
> = {
  [AnnotationStatus.Open]: {
    icon: sphereColors.gray700,
    iconBackground: sphereColors.gray100,
    iconBorder: sphereColors.gray300,
  },
  [AnnotationStatus.InProgress]: {
    icon: sphereColors.blue500,
    iconBackground: sphereColors.blue50,
    iconBorder: sphereColors.blue100,
  },
  [AnnotationStatus.ToReview]: {
    icon: sphereColors.purple500,
    iconBackground: sphereColors.purple50,
    iconBorder: sphereColors.purple100,
  },
  [AnnotationStatus.Resolved]: {
    icon: sphereColors.green700,
    iconBackground: sphereColors.green50,
    iconBorder: sphereColors.green100,
  },
  [AnnotationStatus.Unclassified]: {
    icon: sphereColors.gray500,
    iconBackground: sphereColors.pureWhite,
    iconBorder: sphereColors.gray300,
  },
};

/** All the dropdown options for markup status */
export interface MarkupStatusOption {
  /** Unique key for every option */
  key: AnnotationStatus;

  /** Value represents each key */
  value: AnnotationStatus;

  /** Text to show for each option */
  label: string;
}

/** List of available options to use for the DropDownMarkupField's value property */
export const STATUS_OPTIONS: MarkupStatusOption[] = Object.values(
  AnnotationStatus
).map((status) => ({
  key: status,
  value: status,
  label: FaroStatusText[status],
}));
