import { useGetBulkDialogContent } from "@components/common/faro-table/bulk-dialog/bulk-dialog-helper";
import { BulkDialogs } from "@components/common/faro-table/bulk-dialog/bulk-dialog-types";
import {
  bulkActionResultsSelector,
  numberOfUpdatingItemsSelector,
} from "@store/table/table-selector";
import { useAppSelector } from "@store/store-helper";
import { nounPluralize } from "@utils/data-display";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { AccessLevelDisplayNames } from "@custom-types/project-types";

/** Determines the content of bulk dialog and bulk success dialog for changing access level */
export function useChangeAccessLevelBulkDialogContent(
  accessLevel: SphereDashboardAPITypes.EAccessLevel
): BulkDialogs {
  const numberOfUpdatingItems = useAppSelector(numberOfUpdatingItemsSelector);
  const { numberOfSuccess, numberOfErrors } = useAppSelector(
    bulkActionResultsSelector
  );

  const bulkDialogContent = useGetBulkDialogContent({
    initial: {
      title: "Change access level for these projects?",
      description: `When you confirm this action, 
      the access level of the selected projects will be changed to ${AccessLevelDisplayNames[accessLevel]}`,
    },

    failed: {
      title: `Couldn't change the access level of ${numberOfErrors} out of ${numberOfUpdatingItems} projects`,
      description:
        "The access level of these projects couldn't be changed, check the details and try again.",
    },
    confirmButtonText: "change",
    entityType: "project",
  });

  const successDialogContent = {
    title: `${nounPluralize({
      counter: numberOfSuccess,
      word: "project",
    })} changed access level.`,
    description:
      "All the access level of the selected projects was successfully changed.",
  };

  return { bulkDialogContent, successDialogContent };
}
