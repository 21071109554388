import { configureStore } from "@reduxjs/toolkit";
import {
  projectsReducer,
  resetProjectsState,
} from "@store/projects/projects-slice";
import { resetUiState, uiReducer } from "@store/ui/ui-slice";
import {
  sdbCompanyReducer,
  resetSdbCompanyState,
} from "@store/sdb-company/sdb-company-slice";
import {
  membersReducer,
  resetMembersState,
} from "@store/members/members-slice";
import { resetUserState, userReducer } from "@store/user/user-slice";
import {
  backgroundTasksReducer,
  resetSdbBackgroundTasksState,
} from "@store/sdb-background-tasks/sdb-background-tasks-slice";
import { AppDispatch } from "@store/store-helper";
import { groupsReducer, resetGroupsState } from "@store/groups/groups-slice";
import { errorsReducer, resetErrorsState } from "@store/errors/errors-slice";
import {
  analyticsReducer,
  resetAnalyticsState,
} from "@store/analytics/analytics-slice";
import {
  resetSnapshotsState,
  snapshotsReducer,
} from "@store/snapshots/snapshots-slice";
import { tableReducer, resetTableState } from "@store/table/table-slice";
import { clearStore, iElementsReducer } from "@faro-lotv/project-source";
import {
  resetSlideNodesState,
  slideNodesReducer,
} from "@store/slide-nodes/slide-nodes-slice";
import { sdbIElementsReducer } from "@store/sdb-i-elements/sdb-i-elements-slice";
import {
  companyMemberGroupsReducer,
  resetCompanyMemberGroupsState,
} from "@store/company-member-groups/company-member-groups-slice";
import {
  captureTreeReducer,
  resetCaptureTreeState,
} from "@store/capture-tree/capture-tree-slice";
import {
  resetUploadTasksState,
  uploadTasksReducer,
} from "@store/upload-tasks/upload-tasks-slice";
import { resetTeamState, teamReducer } from "@store/teams/teams-slice";
import { appReducer, resetAppState } from "@store/app/app-slice";

export const rootReducer = {
  // When adding a new reducer here also add the corresponding reset action to the "resetStore" function.
  analytics: analyticsReducer,
  captureTree: captureTreeReducer,
  companyMemberGroups: companyMemberGroupsReducer,
  errors: errorsReducer,
  groups: groupsReducer,
  iElements: iElementsReducer,
  members: membersReducer,
  projects: projectsReducer,
  sdbBackgroundTasks: backgroundTasksReducer,
  sdbCompany: sdbCompanyReducer,
  sdbIElements: sdbIElementsReducer,
  slideNodes: slideNodesReducer,
  snapshots: snapshotsReducer,
  table: tableReducer,
  ui: uiReducer,
  uploadTasks: uploadTasksReducer,
  user: userReducer,
  teams: teamReducer,
  app: appReducer,
};

export const store = configureStore({
  reducer: rootReducer,
});

/**
 * Resets the store. Dispatches all reducers reset actions. It can be called from any component.
 * For every new store slice added it's corresponding reset action must be added here.
 *
 * @param dispatch AppDispatch
 */
export function resetStore(dispatch: AppDispatch): void {
  dispatch(resetAnalyticsState());
  dispatch(resetCaptureTreeState());
  dispatch(resetCompanyMemberGroupsState());
  dispatch(resetErrorsState());
  dispatch(resetGroupsState());
  dispatch(resetMembersState());
  dispatch(resetProjectsState());
  dispatch(resetSdbBackgroundTasksState());
  dispatch(resetSdbCompanyState());
  dispatch(resetSlideNodesState());
  dispatch(resetSnapshotsState());
  dispatch(resetTableState());
  dispatch(resetUiState());
  dispatch(resetUserState());
  dispatch(resetUploadTasksState());
  dispatch(resetTeamState());
  dispatch(resetAppState());
  // `clearStore` is the reset action for the iElementsReducer store slice and for all other store slices
  // imported from the @faro-lotv/project-source package
  dispatch(clearStore());
}
