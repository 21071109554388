import { APITypes, CoreAPITypes } from "@stellar/api-logic";
import { SplashScreenProps } from "@components/splash-screen/splash-screen-utils";
import welcomeToFaroSlide02 from "@assets/splash-screens/welcome-to-faro/slide-02.png";
import welcomeToFaroSlide03 from "@assets/splash-screens/welcome-to-faro/slide-03.png";
import welcomeToFaroSlide04 from "@assets/splash-screens/welcome-to-faro/slide-04.png";
import welcomeToFaroSlide05 from "@assets/splash-screens/welcome-to-faro/slide-05.png";
import welcomeToFaroSlide06 from "@assets/splash-screens/welcome-to-faro/slide-06.png";
import import3dModelSlide01 from "@assets/splash-screens/import-3d-model/slide-01.png";
import import3dModelSlide02 from "@assets/splash-screens/import-3d-model/slide-02.gif";

/**
 * All the possible keys for SDB Splash screen
 * The values are going to be used to pass to backend and
 * make it clear that they should be shown in Sphere Dashboard and not HoloBuilder apps.
 * Important: Don't change the values, as they are used to pass to backend and check if user has seen the splash screen
 * Important: Don't change the orders of the values, as they are used to determine the order of the splash screens
 */
export enum SplashScreenSdbTopic {
  /** Showing welcome page and introducing the new functionalities */
  "welcomeToFaro" = "sdb:welcome-to-faro",

  /** Showing tutorial for importing 3d models */
  "import3DModels" = "sdb:import-3d-models",
}

const welcomeToFaroScreens: SplashScreenProps = {
  topic: SplashScreenSdbTopic.welcomeToFaro,
  allowedRoles: Object.values(CoreAPITypes.EUserCompanyRole),
  disallowedFeatures: [APITypes.EUserSubscriptionRole.sphereDashboard],
  // Do not show this splash screen to Sphere XG exclusive companies
  shouldExclude: (company) => company?.isSphereLegacyMigrated ?? false,
  content: [
    {
      title: "New & Intuitive design",
      description: `Find your data in a fresh and modern design in Sphere XG. 
        The intuitive interface makes managing your projects efficient and enjoyable.`,

      image: {
        src: welcomeToFaroSlide02,
        altText: "New dashboard design",
      },
    },
    {
      title: "Boost efficiency with bulk actions",
      description: `Bulk actions help you save time when making changes across projects, 
        team members or groups at scale. Select multiple members, projects or groups to perform 
        bulk actions such as sending emails, changing roles, archiving, and more.`,

      image: {
        src: welcomeToFaroSlide03,
        altText: "Bulk actions",
      },
    },
    {
      title: "Know exactly what’s happening",
      description: `Keep the track of your reality capture data. 
          See the processing status for VideoMode and point cloud processing in real-time.`,

      image: {
        src: welcomeToFaroSlide04,
        altText: "Updated project status",
      },
    },
    {
      title: "Keep track of issues across your project",
      description: `360 Markups are now called annotations. Have a complete overview of all issues at a glance, 
        keep track of their status and access them for review.`,

      image: {
        src: welcomeToFaroSlide05,
        altText: "Site annotations",
      },
    },
    {
      title: "Try the new dashboard for yourself",
      description: `Now it’s time to experience the new dashboard. If you have questions or suggestions, 
        please use the feedback button and let us know. We're excited to hear from you!`,

      image: {
        src: welcomeToFaroSlide06,
        altText: "Give us feedback",
      },
    },
  ],
};

const import3DModelsScreens: SplashScreenProps = {
  topic: SplashScreenSdbTopic.import3DModels,
  // All users should see this splash screen
  allowedRoles: Object.values(CoreAPITypes.EUserCompanyRole),
  content: [
    {
      title: "Bring Your 3D Models and BIM Data Into FARO Sphere® XG!",
      description: `Import your 3D models into Sphere XG to view and manage all your reality capture 
        and 3D design data efficiently within one unified digital reality platform.`,

      image: {
        src: import3dModelSlide01,
        altText: "Importing 3D models to Dashboard",
      },
    },
    {
      title: "Find Discrepancies Early On With Accuracy",
      description: `The new overlay view of your 3D models and point clouds allows you to compare site 
        conditions with the plan, spot issues early, and visually inspect for future clashes to prevent rework.`,

      image: {
        src: import3dModelSlide02,
        altText: "Finding discrepancies",
      },
    },
  ],
};

export const splashScreenList: Record<SplashScreenSdbTopic, SplashScreenProps> =
  {
    [SplashScreenSdbTopic.welcomeToFaro]: welcomeToFaroScreens,
    [SplashScreenSdbTopic.import3DModels]: import3DModelsScreens,
  };
