import { LinearProgress } from "@mui/material";

interface Props {
  /** Progress value from 0 to 100. */
  progress: number;
}

/**
 * Some basic adjustments for MUI's LinearProgress for the usage in the Staging Area stepper.
 */
export function DataManagementProgressBar({ progress }: Props): JSX.Element {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      color={ 0 < progress ? "primary" : "inherit" }
      sx={{
        "&.MuiLinearProgress-root": {
          flex: "1 1 auto",
          marginLeft: "10px",
          marginRight: "10px",
        },
      }}
    />
  );
}
