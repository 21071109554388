import { Markup } from "@custom-types/project-markups-types";
import { ProjectLaunchTarget } from "@custom-types/sdb-company-types";
import { useDateTime } from "@hooks/use-date-time";
import { useMembersUtils } from "@hooks/use-member-utils";
import { defaultProjectLaunchTargetSelector } from "@store/sdb-company/sdb-company-selector";
import { useAppSelector } from "@store/store-helper";
import { downloadMarkupDataAsCSV } from "@utils/csv-data-export";
import { ExportAsCSV } from "@utils/export-as-csv";
import { TableType } from "@utils/track-event/track-event-list";
import { useCallback } from "react";

interface Props {
  /** List of selected markups in table */
  selectedMarkups: Markup[];

  /** The name of the markup's project */
  projectName: string;

  /** True if some entities are selected */
  areEntitiesSelected: boolean;
}

/** Button to export selected markups as CSV */
export function ExportMarkupsAsCSV({
  selectedMarkups,
  projectName,
  areEntitiesSelected,
}: Props): JSX.Element {
  const { companyMembers, projectMembers } = useMembersUtils();
  const projectLaunchSettings = useAppSelector(
    defaultProjectLaunchTargetSelector
  );

  const { formatDate } = useDateTime();

  /** Returns the url of the markup opening either in Viewer or WebEditor */
  const markupHref = useCallback(
    (markup: Markup) => {
      let openTarget = ProjectLaunchTarget.sphereViewer;
      if (
        projectLaunchSettings.defaultOpenProjectTarget ===
          ProjectLaunchTarget.webEditor &&
        markup?.webEditorUrl
      ) {
        openTarget = projectLaunchSettings.defaultOpenProjectTarget;
      }

      return openTarget === ProjectLaunchTarget.sphereViewer
        ? markup.sphereViewerUrl
        : markup.webEditorUrl;
    },
    [projectLaunchSettings.defaultOpenProjectTarget]
  );

  return (
    <ExportAsCSV
      trackingProps={{
        dataType: TableType.projectMarkups,
        numberOfEntities: selectedMarkups.length,
        areEntitiesSelected,
      }}
      csvExportHandler={() =>
        downloadMarkupDataAsCSV({
          markups: selectedMarkups,
          companyMembers,
          projectMembers,
          fileName: `${projectName}-Annotation List.csv`,
          markupHref,
          formatDate,
        })
      }
      toastMessage={{
        success: "Successfully exported annotations as CSV.",
        error: "Cannot export annotations. Please try again.",
      }}
      buttonTooltipText="Export annotations"
    />
  );
}
