import { getPrettyName } from "@utils/user-utils";
import { MemberAvatars } from "@components/common/member/member-avatars";
import { SdbTeamMemberBase } from "@custom-types/teams-types";

interface Props {
  /** List of member of a team */
  members: SdbTeamMemberBase[];

  /** Total number of members */
  membersCount?: number;
}

/**
 * Shows avatars of the team members of a team
 */
export function TeamsMembersColumn({
  members,
  membersCount,
}: Props): JSX.Element {
  // Show the team members sorted by name
  const sortedTeamMembers = members.sort((a, b) =>
    getPrettyName(a).localeCompare(getPrettyName(b))
  );

  return (
    <MemberAvatars
      members={sortedTeamMembers}
      maxMemberAvatars={4}
      membersCount={membersCount}
      avatarSx={{ height: "36px", width: "36px" }}
    />
  );
}
