import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { useMemo, MouseEvent } from "react";
import {
  ACTION_BTN_SIZE,
  ACTION_ICON_SIZE,
} from "@components/common/project-actions";
import { useAppSelector } from "@store/store-helper";
import {
  defaultProjectLaunchTargetSelector,
} from "@store/sdb-company/sdb-company-selector";
import { ProjectLaunchTarget } from "@custom-types/sdb-company-types";
import {
  OpenProjectProps,
  getOpenProjectTargetName,
  trackOpenProject,
  OPEN_OTHERS_TARGET_ATTRIBUTE,
} from "@utils/project-utils";
import OpenViewerIcon from "@assets/icons/new/open-in-viewer.svg?react";
import OpenWebEditorIcon from "@assets/icons/new/open-in-holobuilder.svg?react";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useSharedMarkupActions } from "@hooks/project-markups/use-shared-markups-actions";
import { Markup } from "@custom-types/project-markups-types";
import { selectedProjectSelector } from "@store/projects/projects-selector";

interface MarkupActionSphereViewerProps {
  /** The markup for which the action button is triggered */
  markup: Markup;
}

/**
 * Component that represents an action button to open a markup in Sphere Viewer or HoloBuilder.
 * Renders an icon button that, when clicked, opens the markup in the specified viewer/editor.
 *
 * @param markup The properties of the markup to be opened
 * @returns A JSX element representing the action button for opening the markup,
 * wrapped in a tooltip component with a dynamic title based on the workspace type.
 */
export function MarkupActionSphereViewer({
  markup,
}: MarkupActionSphereViewerProps): JSX.Element | null {
  const { trackAsyncEvent } = useTrackEvent();
  const { openTarget, markupHref } = useSharedMarkupActions({ markup });
  const selectedProject = useAppSelector(selectedProjectSelector);

  const projectLaunchSettings = useAppSelector(
    defaultProjectLaunchTargetSelector
  );

  // Decides the title for the open annotation button
  const openAnnotationTitle = useMemo(() => {
    // If it should not show the default app setting, then there is only one option to open the annotation.
    if (!projectLaunchSettings.shouldShowDefaultAppSetting) {
      // For workspaces that are Sphere XG exclusive, saying "Open annotation in Sphere XG" is redundant
      return "Open annotation";
    }

    return `Open annotation in ${getOpenProjectTargetName(
      projectLaunchSettings.defaultOpenProjectTarget
    )}`;
  }, [
    projectLaunchSettings,
  ]);

  /**
   * Triggered to track the opening of markup in either the Sphere Viewer or HoloBuilder.
   */
  function onOpenMarkup(
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    clickType: OpenProjectProps["clickType"]
  ): void {
    event.stopPropagation();

    trackOpenProject({
      openFrom: "projectDetails-annotations",
      clickType,
      numberOfMembers: undefined,
      openTarget,
      ...selectedProject,
      trackAsyncEvent,
    });
  }

  if (!markupHref) {
    return null;
  }

  return (
    <SphereTooltip title={openAnnotationTitle}>
      <FaroIconButton
        buttonSize={ACTION_BTN_SIZE}
        iconSize={ACTION_ICON_SIZE}
        component={
          openTarget === ProjectLaunchTarget.sphereViewer
            ? OpenViewerIcon
            : OpenWebEditorIcon
        }
        target={OPEN_OTHERS_TARGET_ATTRIBUTE}
        href={markupHref}
        onClick={(event) => onOpenMarkup(event, "left button click")}
        onAuxClick={(event) => onOpenMarkup(event, "middle button click")}
        onContextMenu={(event) => onOpenMarkup(event, "open context menu")}
      />
    </SphereTooltip>
  );
}
