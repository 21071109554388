import InfoSvg from "@assets/icons/new/info-circle_24px.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { Stack } from "@mui/material";

interface Props {
  /** Flag if an empty page is currently shown. */
  isEmpty: boolean,
}

export function DataManagementTitle({ isEmpty }: Props): JSX.Element {
  return (
    <Stack direction="row">
      <div style={{ fontSize: "24px" }}>
        Data
      </div>

      <SphereTooltip
        enterDelay={0}
        title={
          <div>
            <div style={{ fontWeight: "bold", fontSize: "14px" }}>
              Blink Data
            </div>
            Currently, only Blink data is supported.
          </div>
        }
      >
        {/* No click handler, we only want the hover effect. */}
        <FaroIconButton
          component={InfoSvg}
          iconButtonProps={{ sx: { marginLeft: "2px", top: "1px" } }}
        />
      </SphereTooltip>

      { isEmpty &&
        <div style={{
          fontSize: "16px",
          marginLeft: "20px",
          display: "flex",
          alignItems: "center",
        }}>
          Let’s get started by adding some data!
        </div>
      }
    </Stack>
  );
}
