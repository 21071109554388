import { Subscription, SubscriptionId } from "@custom-types/subscription-types";
import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";

/**
 * @returns the subscription entity generated from the features available in the company
 * @param features features available in the company
 */
export function getSubscription(
  features: SphereDashboardAPITypes.IFeature[]
): Subscription | undefined {
  const enabledFeatures = features.filter((feature) => feature.enabled);
  const subscriptionFeatures = getSubscriptionFeatures(enabledFeatures);
  return getSubscriptionProperties(subscriptionFeatures);
}

interface SubscriptionFeatures {
  enterpriseTier?: SphereDashboardAPITypes.IFeature;
  proTier?: SphereDashboardAPITypes.IFeature;
  standardTier?: SphereDashboardAPITypes.IFeature;
  addon360?: SphereDashboardAPITypes.IFeature;
  addonPC?: SphereDashboardAPITypes.IFeature;
  isTrial: boolean;
}

/**
 * @returns The company features of type subscription
 * @param features company features
 */
function getSubscriptionFeatures(
  features: SphereDashboardAPITypes.IFeature[]
): SubscriptionFeatures {
  return {
    enterpriseTier: features.find((feature) => {
      return (
        feature.identifier === APITypes.EUserSubscriptionRole.sphereXGEnterprise
      );
    }),
    proTier: features.find((feature) => {
      return feature.identifier === APITypes.EUserSubscriptionRole.sphereXGPro;
    }),
    standardTier: features.find((feature) => {
      return (
        feature.identifier === APITypes.EUserSubscriptionRole.sphereXGStandard
      );
    }),
    addon360: features.find((feature) => {
      return (
        feature.identifier === APITypes.EUserSubscriptionRole.sphereXGAddon360
      );
    }),
    addonPC: features.find((feature) => {
      return (
        feature.identifier ===
        APITypes.EUserSubscriptionRole.sphereXGAddonPointCloud
      );
    }),
    isTrial: features.some((feature) => {
      return (
        feature.identifier === APITypes.EUserSubscriptionRole.user_role_fm_trial
      );
    }),
  };
}

/**
 * @returns The ID, expiration date and isTrial flag of the subscription based on the workspace features
 * @param subscriptionFeatures The available subscription features
 * @param trialExpiresAt The date of the trial subscription if available
 */
function getSubscriptionProperties(
  subscriptionFeatures: SubscriptionFeatures
): Subscription | undefined {
  let id: SubscriptionId | undefined;
  let expiresAt: string | undefined;

  const { enterpriseTier, proTier, standardTier, addon360, addonPC, isTrial } =
    subscriptionFeatures;

  if (enterpriseTier) {
    expiresAt = enterpriseTier.expiresAt;
    if (addon360 && addonPC) {
      id = isTrial ? "trial360PointCloud" : "enterprise360PointCloud";
    } else if (addon360) {
      id = isTrial ? "trial360" : "enterprise360";
    } else if (addonPC) {
      id = isTrial ? "trialPointCloud" : "enterprisePointCloud";
    }
  } else if (proTier) {
    expiresAt = proTier.expiresAt;
    if (addon360) {
      id = "pro360";
    } else if (addonPC) {
      id = "proPointCloud";
    }
  } else if (standardTier) {
    expiresAt = standardTier.expiresAt;
    if (addon360) {
      id = "standard360";
    } else if (addonPC) {
      id = "standardPointCloud";
    }
  }

  if (id === undefined || expiresAt === undefined) {
    return;
  }

  return { id, expiresAt, isTrial };
}
