import { UiErrorType } from "@context-providers/error-boundary/error-types";
import { AnyAction, isAsyncThunkAction } from "@reduxjs/toolkit";
import { BaseErrorState } from "@store/errors/errors-slice";
import {
  fetchProjects,
  fetchProjectDetails,
  fetchSearchedProjects,
  removeMemberFromProject,
  updateProjectDetails,
  fetchUserProjects,
  deleteProject,
  getProjectFeatures,
  fetchProjectSettings,
  updateMemberRoleInProjectSingle,
  updateProjectSettings,
} from "@store/projects/projects-slice-thunk";
import {
  fetchCompaniesAndWorkspaces,
  fetchCompanyBrandingSettings,
  fetchCompanyCommunicationSettings,
  fetchCompanyFeatures,
  updateCompanyBrandingSettings,
  updateCompanyCommunicationSettings,
} from "@store/sdb-company/sdb-company-slice";
import {
  fetchCompanyMembers,
  fetchCompanyMemberDetails,
  removeMemberFromCompany,
  updateMemberRoleInWorkspace,
} from "@store/members/members-slice";
import {
  fetchCurrentUser,
  logoutUser,
  setFueFlags,
  getLoggedInUser,
} from "@store/user/user-slice";
import {
  fetchGroupDetails,
  fetchGroups,
  removeMemberFromGroup,
  updateGroupDetails,
  updateMemberRoleInGroup,
} from "@store/groups/groups-slice";
import {
  fetchProjectSnapshots,
  fetchSnapshotMembers,
  deleteProjectSnapshot,
  inviteMembersToSnapshot,
  removeMemberFromSnapshot,
} from "@store/snapshots/snapshots-slice";
import {
  fetchCompanyStats,
  fetchProjectStats,
} from "@store/analytics/analytics-slice";
import { fetchProjectIElements } from "@faro-lotv/project-source";
import { fetchCompanyMemberGroups } from "@store/company-member-groups/company-member-groups-thunk";
import {
  createTeam,
  deleteTeam,
  fetchTeamDetails,
  fetchTeamMembers,
  removeMembersFromTeam,
  updateTeamDetails,
} from "@store/teams/teams-slice-thunk";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";

/** Creates an error based on the rejected thunk  */
export function createErrorState(action: AnyAction): BaseErrorState {
  let title = "An error occurred";
  let uiError: UiErrorType = "dialog";

  switch (true) {
    case isAsyncThunkAction(fetchSearchedProjects)(action):
      title = "Cannot fetch requested searched project";
      uiError = "toast";
      break;

    case isAsyncThunkAction(updateProjectDetails)(action):
      title = "Cannot update project details";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchProjectDetails)(action):
      title = "Cannot fetch project details";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchProjectSettings)(action):
      title = "Cannot fetch project settings";
      uiError = "toast";
      break;

    case isAsyncThunkAction(updateProjectSettings)(action):
      title = "Cannot update project settings";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchProjects)(action):
      title = "Cannot fetch projects";
      uiError = "toast";
      break;

    case isAsyncThunkAction(getProjectFeatures)(action):
      title = "Cannot fetch project features";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchUserProjects)(action):
      title = "Cannot fetch projects";
      uiError = "toast";
      break;

    case isAsyncThunkAction(removeMemberFromProject)(action):
      title = "Cannot remove member from project";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchCompanyFeatures)(action):
      title = "Cannot fetch workspace features";
      uiError = "page";
      break;

    case isAsyncThunkAction(fetchCompaniesAndWorkspaces)(action):
      title = "Cannot fetch workspaces";
      uiError = "page";
      break;

    case isAsyncThunkAction(removeMemberFromCompany)(action):
      title = "Cannot remove this member from workspace";
      uiError = "toast";
      break;

    case isAsyncThunkAction(updateMemberRoleInWorkspace)(action):
      title = "Cannot change the member role";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchCompanyMembers)(action):
      title = "Cannot fetch workspace members";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchCompanyMemberDetails)(action):
      title = "Cannot fetch workspace member details";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchCurrentUser)(action):
      title = "Cannot fetch current user";
      uiError = "page";
      break;

    case isAsyncThunkAction(setFueFlags)(action):
      title = "Cannot update the fueFlags";
      uiError = "toast";
      break;

    case isAsyncThunkAction(logoutUser)(action):
      title = "Failed to logout the user";
      uiError = "toast";
      break;

    case isAsyncThunkAction(getLoggedInUser)(action):
      title = "Failed to fetch the logged in user";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchGroups)(action):
      title = "Cannot fetch the groups";
      uiError = "dialog";
      break;

    case isAsyncThunkAction(fetchCompanyMemberGroups)(action):
      title = "Cannot fetch the workspace member groups";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchGroupDetails)(action):
      title = "Cannot fetch group details";
      uiError = "toast";
      break;

    case isAsyncThunkAction(removeMemberFromGroup)(action):
      title = "Cannot remove member from group";
      uiError = "toast";
      break;

    case isAsyncThunkAction(updateMemberRoleInGroup)(action):
      title = "Cannot change the manager role";
      uiError = "toast";
      break;

    case isAsyncThunkAction(updateGroupDetails)(action):
      title = "Cannot update group details";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchProjectSnapshots)(action):
      title = "Cannot fetch project snapshots";
      uiError = "toast";
      break;

    case isAsyncThunkAction(deleteProject)(action):
      title = "Cannot delete project";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchSnapshotMembers)(action):
      title = "Cannot fetch snapshot members";
      uiError = "toast";
      break;

    case isAsyncThunkAction(deleteProjectSnapshot)(action):
      title = "Cannot delete SnapShot";
      uiError = "toast";
      break;

    case isAsyncThunkAction(inviteMembersToSnapshot)(action):
      title = "Cannot invite members to snapshot";
      uiError = "toast";
      break;

    case isAsyncThunkAction(removeMemberFromSnapshot)(action):
      title = "Cannot remove member from snapshot";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchCompanyStats)(action):
      title = "Cannot fetch workspace analytics";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchProjectStats)(action):
      title = "Cannot fetch project analytics";
      uiError = "toast";
      break;

    case isAsyncThunkAction(updateCompanyBrandingSettings)(action):
      title = "Cannot update workspace branding setting";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchCompanyBrandingSettings)(action):
      title = "Cannot fetch the workspace branding settings";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchProjectIElements)(action):
      title = "Cannot fetch projects IElements";
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchCompanyCommunicationSettings)(action):
      title = "Cannot fetch the workspace communication settings";
      uiError = "toast";
      break;

    case isAsyncThunkAction(updateCompanyCommunicationSettings)(action):
      title = "Cannot update the workspace communication settings";
      uiError = "toast";
      break;

    case isAsyncThunkAction(updateMemberRoleInProjectSingle)(action):
      title = "Cannot update team member role in project";
      uiError = "toast";
      break;

    case isAsyncThunkAction(createTeam)(action):
      title = `Cannot create ${TEAM_DISPLAY_NAME}`;
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchTeamDetails)(action):
      title = `Cannot fetch ${TEAM_DISPLAY_NAME} details`;
      uiError = "toast";
      break;

    case isAsyncThunkAction(updateTeamDetails)(action):
      title = `Cannot update ${TEAM_DISPLAY_NAME} details`;
      uiError = "toast";
      break;

    case isAsyncThunkAction(fetchTeamMembers)(action):
      title = `Cannot fetch ${TEAM_DISPLAY_NAME} members`;
      uiError = "toast";
      break;

    case isAsyncThunkAction(removeMembersFromTeam)(action):
      title = `Cannot remove member from ${TEAM_DISPLAY_NAME}`;
      uiError = "toast";
      break;

    case isAsyncThunkAction(deleteTeam)(action):
      title = `Cannot delete ${TEAM_DISPLAY_NAME}`;
      uiError = "toast";
      break;
  }
  return {
    id: action.type,
    title,
    error: action.error.message,
    uiError,
  };
}

/** The order of the importance of an error. The higher number, the more important */
const errorImportance: { [key in UiErrorType]: number } = {
  toast: 1,
  dialog: 2,
  page: 3,
};

/**
 * Compares errors by their importance reflected with the uiError
 */
export function compareErrorImportance(
  errorA: BaseErrorState,
  errorB: BaseErrorState
): number {
  if (errorImportance[errorA.uiError] > errorImportance[errorB.uiError]) {
    return 1;
  }
  if (errorImportance[errorA.uiError] < errorImportance[errorB.uiError]) {
    return -1;
  }

  return 0;
}
