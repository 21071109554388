import { FaroTextButton } from "@components/common/faro-text-button";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props extends BaseProjectIdProps {
  /** Setter for showing or hiding the upload dialog. */
  setIsUploadDialogOpen(isUploadDialogOpen: boolean): void;
}

export function AddDataButton({ projectId, setIsUploadDialogOpen }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();

  function openDialog(): void {
    setIsUploadDialogOpen(true);
    trackEvent({
      name: DataManagementEvents.addData,
      props: { projectId },
    });
  }

  return (
    <FaroTextButton
      size="small"
      dataTestId="sa-add-data-button"
      tooltipText="Upload additional data."
      onClick={() => openDialog()}
    >
      Add Data
    </FaroTextButton>
  );
}
