import {
  FaroDialog,
  SPACE_ELEMENTS_OF_MODAL,
} from "@components/common/dialog/faro-dialog";
import { useCoreApiClient } from "@api/use-core-api-client";
import { sphereColors } from "@styles/common-colors";
import { useState } from "react";
import { Typography } from "@mui/material";
import { SphereDropzone } from "@components/common/sphere-dropzone/sphere-dropzone";
import { UserProfile } from "@utils/track-event/track-event-list";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { updateCurrentUserProfile } from "@store/user/user-slice";
import { currentUserSelector } from "@store/user/user-selector";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { SphereAvatar } from "@components/header/sphere-avatar";
import { getUserInitials } from "@utils/user-utils";
import { ReactSetStateFunction } from "@custom-types/types";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { UploadElementType } from "@custom-types/file-upload-types";
import { isSingleFileUploadResponse } from "@custom-types/file-upload-type-guards";

interface Props {
  /** Whether the update profile picture dialog should show or not */
  isDialogOpen: boolean;

  /** Callback function to close the profile picture dialog */
  setIsDialogOpen: ReactSetStateFunction<boolean>;
}

/** Renders the update profile picture dialog and update the picture */
export function UpdateProfilePictureDialog({
  isDialogOpen,
  setIsDialogOpen,
}: Props): JSX.Element {
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const { handleErrorWithToast } = useErrorContext();
  const { trackEvent } = useTrackEvent();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function updateProfilePicture(newImageUrl: string): Promise<void> {
    try {
      // Just to check the type, user shouldn't be here without the currentUser info
      if (!currentUser) {
        throw new Error("Current user information is not available");
      }

      trackEvent({
        name: UserProfile.updateProfile,
        props: { attribute: "profileImageUrl" },
      });
      await dispatch(
        updateCurrentUserProfile({
          coreApiClient,
          user: {
            ...currentUser,
            thumbnailUrl: newImageUrl,
          },
        })
      );
    } catch (error) {
      handleErrorWithToast({
        id: `updateProfilePicture-${Date.now().toString()}`,
        title: "Could not change profileImageUrl",
        error,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {/* Update profile picture dialog */}
      <FaroDialog
        title="Profile picture"
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        shouldHideActions={true}
      >
        <Typography
          sx={{
            fontSize: "14px",
            marginTop: "0px",
            marginBottom: SPACE_ELEMENTS_OF_MODAL,
            color: sphereColors.gray800,
          }}
        >
          Personalize your account and help others recognize you.
        </Typography>

        <SphereDropzone
          avatar={
            <SphereAvatar
              src={currentUser?.thumbnailUrl}
              initials={getUserInitials(currentUser)}
              size="x-large"
              shouldHideWhiteRim
            />
          }
          existingImageUrl={currentUser?.thumbnailUrl}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          context={{
            uploadElementType: UploadElementType.default,
          }}
          onUploadComplete={(uploadedResponse) => {
            if (isSingleFileUploadResponse(uploadedResponse)) {
              updateProfilePicture(uploadedResponse);
            }
          }}
          onDeleteButtonClick={() => updateProfilePicture("")}
          deleteIconTooltipText="Remove profile picture"
        />
      </FaroDialog>
    </>
  );
}
