import { Box } from "@mui/material";
import { HelpCenterMenu, LanguageCodes } from "@faro-lotv/flat-ui";
import { SendFeedbackDialog } from "@components/header/send-feedback-dialog";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { AppBarEvents, FeedbackModalEvents } from "@utils/track-event/track-event-list";
import { useState } from "react";

export function FaroHelpCenterMenu(): JSX.Element {
  const { trackEvent } = useTrackEvent();

  const [shouldGiveFeedback, setsShouldGiveFeedback] = useState<boolean>(false);

  function openFeedbackDialog(): void {
    setsShouldGiveFeedback(true);
    trackEvent({ name: FeedbackModalEvents.openFeedbackDialog });
  }

  return (
    <>
      <Box
        component="div"
        sx={{
          marginLeft: "12px",
        }}
      >
        <HelpCenterMenu
          helpCenterLanguages={LanguageCodes.English}
          sendFeedback={openFeedbackDialog}
          onOpenHelpCenter={() => {
            trackEvent({ name: AppBarEvents.openHelpMenu });
          }}
          onCloseHelpCenter={() => {
            trackEvent({ name: AppBarEvents.closeHelpMenu });
          }}
          onOpenHelpLink={(itemName) => {
            trackEvent({
              name: AppBarEvents.openHelpMenuItem,
              props: {
                itemName,
              },
            });
          }}
        />
      </Box>

      <SendFeedbackDialog
        /**
         * TODO: https://faro01.atlassian.net/browse/ST-2140 use the correct language - hash commit where was removed
         * 97fdac6aed9358cfd4efeb06d1c6e7afabe961a8
         */
        shouldShowFeedbackDialog={shouldGiveFeedback}
        setShouldShowFeedbackDialog={setsShouldGiveFeedback}
      />
    </>
  );
}
