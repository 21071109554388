import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import defaultUploadImage from "/src/assets/upload-image.png";
import { SphereCircularProgress } from "@components/common/sphere-circular-progress";
import DeleteIcon from "@assets/icons/new/delete_32px.svg?react";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { ReactSetStateFunction } from "@custom-types/types";
import { ChangeEvent, useMemo } from "react";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FILE_SIZE_MULTIPLIER } from "@utils/file-utils";

interface Props {
  /** Avatar component of user. If provided existingImageUrl will be ignored */
  avatar?: JSX.Element;

  /** Progress value to show inside the spinner */
  uploadProgress: number;

  /** Existing url of an image to display inside the dropzone */
  existingImageUrl?: string;

  /** True if logo uploading is in progress */
  isLoading: boolean;

  /** Callback setState function to set the input element */
  setFileInputEl: ReactSetStateFunction<HTMLInputElement | null>;

  /** Triggers when a file is selected from file manager dialog */
  selectFileFromDialog(e: ChangeEvent<HTMLInputElement>): void;

  /** Callback function which triggers on delete button click */
  onDeleteButtonClick?(): void;

  /** Text to show in delete icon tooltip */
  deleteIconTooltipText?: string;

  /** Whether the supported format should show in the dropzone */
  shouldShowSupportedFormats?: boolean;

  /** Whether the size limit should show in the dropzone */
  shouldShowSizeLimit?: boolean;

  /** List of file extensions that are allowed in the dropzone */
  allowedExtensions: string[];

  /** First part of instruction, can be different based on the usage of the dropzone */
  instruction?: string;

  /** Maximum file size the dropzone should allow to upload */
  maxFileSize: number;

  /** Whether the multiple files can be selected or not */
  shouldAllowMultiUpload: boolean;

  /** Whether the dropzone should handle files. Default: true. */
  shouldAllowFiles?: boolean;
}

/** CSS class name for the remove workspace logo button  */
export const REMOVE_WORKSPACE_LOGO = "removeWorkspaceLogo";

/** Renders the dropzone elements based on the loading state */
export function DropzoneElements({
  avatar,
  isLoading,
  uploadProgress,
  existingImageUrl,
  setFileInputEl,
  selectFileFromDialog,
  onDeleteButtonClick,
  deleteIconTooltipText,
  shouldShowSupportedFormats = true,
  shouldShowSizeLimit = true,
  allowedExtensions,
  instruction = "Drag your image here",
  maxFileSize,
  shouldAllowMultiUpload,
  shouldAllowFiles = true,
}: Props): JSX.Element {
  /** Contains the extensions for the accept attribute of input element  */
  const allowedExtensionsList = useMemo(() => {
    return allowedExtensions.map((extension) => `.${extension}`).join(", ");
  }, [allowedExtensions]);

  /** Show file size in GB if larger than or equal to 1024 MB */
  const maxFileSizeToShow = useMemo(() => {
    return maxFileSize >= FILE_SIZE_MULTIPLIER
      ? `${maxFileSize / FILE_SIZE_MULTIPLIER} GB`
      : `${maxFileSize} MB`;
  }, [maxFileSize]);

  /** returns the entity (file(s) or folder(s)) type to upload*/
  const entitiesToUploadStr = useMemo((): string => {
    // The browser's folder upload dialog does not allow multi-selection.
    if (shouldAllowMultiUpload && shouldAllowFiles) {
      return "files";
    } else {
      return shouldAllowFiles === false ? "folder" : "file";
    }
  }, [shouldAllowFiles, shouldAllowMultiUpload]);

  if (isLoading) {
    return (
      <SphereCircularProgress value={uploadProgress} label="Uploading..." />
    );
  }

  return (
    <>
      <Box
        component="div"
        sx={{
          height: "100px",
          width: "100px",
        }}
      >
        {/* Showing avatar if provided, otherwise existing or default image */}
        {avatar ? (
          avatar
        ) : (
          <img
            src={
              existingImageUrl?.length ? existingImageUrl : defaultUploadImage
            }
            style={{
              maxHeight: "100%",
              maxWidth: "100%",
            }}
            alt={
              existingImageUrl?.length
                ? "Your uploaded image"
                : "The default image indicates that an image can be uploaded here"
            }
          />
        )}
      </Box>

      {/* Instruction for upload a file */}
      <Box>
        <Box
          component="div"
          sx={{
            color: sphereColors.gray800,
            fontSize: "14px",
            marginBottom: "7px",
          }}
        >
          {instruction}, or{" "}
          <span
            style={{
              textDecoration: "underline",
              color: sphereColors.blue500,
            }}
          >
            select {entitiesToUploadStr}
          </span>{" "}
          to upload
        </Box>

        {shouldShowSupportedFormats && (
          <Typography
            sx={{
              color: sphereColors.gray600,
              fontSize: "14px",
            }}
          >
            Supported formats {allowedExtensionsList}
          </Typography>
        )}

        {shouldShowSizeLimit && (
          <Typography
            sx={{
              color: sphereColors.gray600,
              fontSize: "14px",
            }}
          >
            Maximum file size: {maxFileSizeToShow}
          </Typography>
        )}
      </Box>
      {/* Input field to select file from dialog */}
      <input
        ref={setFileInputEl}
        type="file"
        style={{ display: "none" }}
        accept={allowedExtensionsList}
        onChange={selectFileFromDialog}
        multiple={shouldAllowMultiUpload}
      />

      {/* Delete icon which is visible on hover on dropzone */}
      {onDeleteButtonClick && existingImageUrl?.length !== 0 && (
        <Box
          className={REMOVE_WORKSPACE_LOGO}
          sx={{
            position: "absolute",
            top: 10,
            right: 10,
            // Overridden by REMOVE_WORKSPACE_LOGO class
            visibility: "hidden",
          }}
        >
          <SphereTooltip title={deleteIconTooltipText}>
            <FaroIconButton
              component={DeleteIcon}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteButtonClick();
              }}
              iconSize="19px"
              buttonSize="40px"
            />
          </SphereTooltip>
        </Box>
      )}
    </>
  );
}
