import { SphereDashboardAPITypes } from "@stellar/api-logic";
import {
  HasUserValidPermissionProjectLevelProps,
  RequiredPermissionProjectLevelName,
  RequiredPermissionsProjectLevel,
} from "@utils/permission-control/project-permission-control-types";

/**
 * Object that determines all different permission rules for users to get
 * access on the project level.
 */
export const requiredPermissionsProjectLevel: RequiredPermissionsProjectLevel<RequiredPermissionProjectLevelName> =
  {
    /** Whether the user can change the role of a team member */
    [RequiredPermissionProjectLevelName.canChangeRole]: {
      permissions: [
        SphereDashboardAPITypes.EProjectPermission.all,
        SphereDashboardAPITypes.EProjectPermission.admin,
      ],
    },
  };

/**
 * Checks whether a user has permissions within the project level.
 *
 * @returns True if the user has a valid project level permission.
 */
export function hasUserValidPermissionProjectLevel<
  PermissionNameT extends string = RequiredPermissionProjectLevelName
>({
  permissionName,
  selectedProject,
  defaultRequiredPermissions = requiredPermissionsProjectLevel as RequiredPermissionsProjectLevel<PermissionNameT>,
}: HasUserValidPermissionProjectLevelProps<PermissionNameT>): boolean {
  if (!selectedProject) {
    return false;
  }

  // Return true if user has all permission, further checking not required
  if (
    selectedProject.permissions.includes(
      SphereDashboardAPITypes.EProjectPermission.all
    )
  ) {
    return true;
  }

  const requiredPermissionsInProject =
    defaultRequiredPermissions[permissionName];
  const requiredPermissions = requiredPermissionsInProject.permissions;

  // If there is no required permissions that means user doesn't required any permission
  if (requiredPermissions.length === 0) {
    return true;
  }

  // Check for the necessary permission to allow users when they have all the required permission in project level
  return requiredPermissions.some((requiredPermission) =>
    selectedProject.permissions.includes(requiredPermission)
  );
}
