import { Box, Grid, Typography } from "@mui/material";
import {
  DEFAULT_TEXT_FONT_SIZE,
  DEFAULT_TITLE_FONT_SIZE,
} from "@styles/common-styles";
import {
  PageSectionItem,
  PageSectionItemData,
} from "@components/page/page-section-item";
import { sphereColors } from "@styles/common-colors";
import { PageSectionInfoIcon } from "@components/page/page-section-info-icon";

export interface PageSectionProps {
  /** The title of the section, shown above the subtitle on the left side of the screen. */
  title: string;

  /** Additional information about the section to displayed in bold text */
  additionalInfo?: string;

  /** An optional text that shows as a tooltip on hovering an info icon that will be shown if tooltipInfo is passed */
  tooltipInfo?: React.ReactNode;

  /** The subtitle of the section, shown below the title on the left side of the screen. */
  subtitle: React.ReactNode;

  /** The items in the section */
  items: PageSectionItemData[];

  /** Optional flag to indicate if the screen is small and smaller */
  isScreenXsAndSmall?: boolean;

  /**
   * Optional flag when the content is loading,
   * if true, the content in all items will be replaced with a skeleton.
   */
  isLoading?: boolean;

  /**
   * Optional flag to indicate if this section should be hidden.
   * If true, the section will be hidden and it won't take any space.
   */
  isHidden?: boolean;

  /**
   * Optional flag to indicate if this is the top section, in that case,
   * the top margin will be different and it won't have a top border.
   */
  isTopSection?: boolean;

  /**
   * Optional flag to indicate if this is the last section, in that case,
   * the bottom margin will be different.
   */
  isLastSection?: boolean;
}

/**
 * Defines a page section that can be used in pages like account or settings,
 * includes a title, subtitle and a list of items.
 */
export function PageSection({
  title,
  additionalInfo,
  tooltipInfo,
  subtitle,
  items,
  isScreenXsAndSmall,
  isLoading = false,
  isTopSection = false,
  isLastSection = false,
}: PageSectionProps): JSX.Element {
  return (
    // Wrapper for the whole section, usually shown as a row in the page
    <Grid
      container
      // Since the sections in isScreenXsAndSmall are placed one after the other, we need to extra bottom margin
      marginBottom={isScreenXsAndSmall && !isLastSection ? "45px" : "0px"}
    >
      {/* Left column with title and subtitle */}
      <Grid
        item
        xs={12}
        sm={12}
        md={5}
        lg={4}
        sx={{
          paddingRight: {
            xs: "0px",
            md: "60px",
          },
          paddingBottom: {
            xs: "30px",
            md: "0px",
          },
        }}
      >
        {/* Title and subtitle wrapper */}
        <Box
          component="div"
          sx={{
            marginTop: {
              md: isTopSection ? "24px" : "75px",
            },
          }}
        >
          {/* Title of the section */}
          <Box
            component="div"
            sx={{
              fontSize: DEFAULT_TITLE_FONT_SIZE,
              letterSpacing: "-0.16px",
              fontWeight: 600,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>{title}</Box>
            {tooltipInfo && <PageSectionInfoIcon text={tooltipInfo} />}
          </Box>

          {/* Additional Information section */}
          {additionalInfo && (
            <Typography
              sx={{
                color: sphereColors.gray600,
                fontSize: "10px",
                fontWeight: "bold",
                textTransform: "uppercase",
              }}
            >
              {additionalInfo}
            </Typography>
          )}

          {/* Subtitle of the section */}
          <Box
            component="div"
            sx={{
              fontSize: DEFAULT_TEXT_FONT_SIZE,
              marginTop: "10px",
            }}
          >
            {subtitle}
          </Box>
        </Box>
      </Grid>

      {/* Right column with the items */}
      <Grid
        item
        xs={12}
        sm={12}
        md={7}
        lg={8}
        sx={{
          marginTop: {
            md: isTopSection ? "0px" : "75px",
          },
        }}
      >
        {items
          .filter((item) => !item.isHidden)
          .map((item, index) => (
            <PageSectionItem
              item={item}
              key={`page-subsection-${index}`}
              isLoading={isLoading}
              shouldShowTopBorder={
                index === 0 && !isTopSection && !isScreenXsAndSmall
              }
              // Bottom margin should only be added in small screens because items are on top of each other
              // If this is the last section and the last item, don't add bottom margin
              marginBottom={
                isScreenXsAndSmall &&
                !(isLastSection && index === items.length - 1)
                  ? "20px"
                  : "0px"
              }
            />
          ))}
      </Grid>
    </Grid>
  );
}
