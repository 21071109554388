import { useCoreApiClient } from "@api/use-core-api-client";
import { FaroSwitch } from "@components/common/faro-switch";
import { useAppParams } from "@router/router-helper";
import { APITypes } from "@stellar/api-logic";
import {
  getProjectByIdSelector,
  isFeatureEnabledForProjectSelector,
  isSelectedProjectEditableSelector,
  selectedProjectContextSelector,
} from "@store/projects/projects-selector";
import { editProjectFeature } from "@store/projects/projects-slice-thunk";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { isProjectDemo } from "@utils/project-utils";
import { useState } from "react";
import {
  FeatureSettingLayout,
  TOOLTIP_TEXT_CHECKED,
  TOOLTIP_TEXT_NOT_CHECKED,
} from "@pages/project-details/project-settings/feature-setting-layout";
import { hasSubscriptionProjectLevel } from "@utils/access-control/project/project-access-control";
import { useErrorContext } from "@context-providers/error-boundary/error-handling-context";
import { useDialog } from "@components/common/dialog/dialog-provider";
import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SPACE_ELEMENTS_OF_MODAL } from "@components/common/dialog/faro-dialog";
import { ContactSupportLocations } from "@custom-types/types";
import { runtimeConfig } from "@src/runtime-config";
import { selectedSdbCompanyNameSelector } from "@store/sdb-company/sdb-company-selector";
import {
  ProjectEvents,
  WorkspaceEvents,
} from "@utils/track-event/track-event-list";
import { Alert } from "@faro-lotv/flat-ui";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { useHasUserValidRoleProjectLevel } from "@hooks/access-control/use-has-user-valid-role-project-level";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";

/** Renders Face Blurring feature setting */
export function FeatureFaceBlurring({
  projectId,
}: BaseProjectIdProps): JSX.Element | null {
  const { companyId } = useAppParams();
  const { handleErrorWithToast } = useErrorContext();
  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const companyName = useAppSelector(selectedSdbCompanyNameSelector);
  const selectedProject = useAppSelector(getProjectByIdSelector(projectId));
  const projectContext = useAppSelector(selectedProjectContextSelector);
  const isProjectEditable = useAppSelector(isSelectedProjectEditableSelector());
  const { createDialog } = useDialog();
  const { trackEvent } = useTrackEvent();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  /** Determines if the global face blurring feature is enabled for the selected project */
  const isFaceBlurringEnabled = useAppSelector(
    isFeatureEnabledForProjectSelector(
      projectId,
      APITypes.EUserSubscriptionRole.globalFaceBlurring
    )
  );

  const { canEditProjectSettings } = useHasUserValidRoleProjectLevel({
    selectedProject,
  });

  /** Return true with showing a warning if face blurring v1 is available */
  async function onExistFaceBlurringV1(): Promise<boolean> {
    const hasFaceBlurringV1 = hasSubscriptionProjectLevel({
      projectContext,
      requiredProjectSubscriptionRole: [
        APITypes.EUserSubscriptionRole.ft_face_blurring,
      ],
    });

    // Show warning dialog if face blurring v1 feature is available
    if (hasFaceBlurringV1) {
      const shouldContactSupport = await createDialog(
        {
          title: "Activating FaceBlurring is temporarily unavailable",
          confirmText: "Contact Support",
          closeText: "Understood",
          maxWidth: "sm",
          // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
          fullWidth: true,
        },
        <Box>
          <Alert
            title="FaceBlurring is still active, all images on already activated projects are still being blurred. 
            We are preparing the rollout of a new and much improved automatic Face Blurring version."
            variant="error"
            sx={{
              fontSize: "14px",
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
              backgroundColor: sphereColors.blue100,
              color: sphereColors.black,
            }}
          />
          <Typography
            sx={{
              marginBottom: SPACE_ELEMENTS_OF_MODAL,
              fontSize: "14px",
              color: sphereColors.gray800,
            }}
          >
            If you need to activate Face Blurring on a new project, just click
            “Contact Support” and we will help you swiftly.
          </Typography>
        </Box>
      );

      if (shouldContactSupport) {
        trackEvent({
          name: WorkspaceEvents.contactSupport,
          props: { location: ContactSupportLocations.faceBlurringV1Warning },
        });

        const subject = encodeURIComponent("FaceBlurring support needed");
        const ccMailAddress = "marx.bievor@faro.com";

        const body = encodeURIComponent(
          // eslint-disable-next-line max-len -- required to have the email body in one line
          `Dear HoloBuilder team,\n\nI tried to activate FaceBlurring for my project ${projectId} in my company ${companyName}. \n\n It seems the FaceBlurring button is currently unavailable. Can you please provide support and activate the feature for this project?\n`
        );

        window.open(
          `mailto:${runtimeConfig.mailSupportAddress}?subject=${subject}&body=${body}&cc=${ccMailAddress}`
        );
      }

      return true;
    }

    return false;
  }

  /** Triggers when feature settings toggle button in clicked */
  async function onChangeFeatureSetting(
    shouldChangeFeature: boolean
  ): Promise<void> {
    if (await onExistFaceBlurringV1()) {
      return;
    }

    const hasConfirmed = await createDialog(
      {
        title: "Activate Face Blurring",
        confirmText: "Activate",
        closeText: "Cancel",
        maxWidth: "sm",
        // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
        fullWidth: true,
      },
      <Box
        sx={{
          fontSize: "14px",
          color: sphereColors.gray800,
        }}
      >
        <Typography
          sx={{
            marginBottom: SPACE_ELEMENTS_OF_MODAL,
          }}
        >
          Face Blurring cannot be deactivated. All faces in the project will be
          blurred permanently.
        </Typography>
        <Typography
          sx={{
            marginBottom: SPACE_ELEMENTS_OF_MODAL,
          }}
        >
          Do you want to continue?
        </Typography>
      </Box>
    );

    if (!hasConfirmed) {
      return;
    }

    // Track event feature toggling
    trackEvent({
      name: ProjectEvents.adjustFeatureSettings,
      props: {
        featureName: APITypes.EUserSubscriptionRole.globalFaceBlurring,
        isFeatureEnabled: shouldChangeFeature,
      },
    });

    if (companyId && selectedProject) {
      setIsLoading(true);

      const payload = {
        [APITypes.EProjectFeatureKey.globalFaceBlurring]: shouldChangeFeature,
      } as APITypes.ProjectFeaturesPayload;

      try {
        await dispatch(
          editProjectFeature({
            coreApiClient,
            companyId,
            selectedProject,
            payload,
          })
        );
      } catch (error) {
        handleErrorWithToast({
          id: `editFaceBlurringSetting-${Date.now().toString()}`,
          title: "Error changing face blurring setting",
          error,
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  // Early return if selected project is not available
  if (!selectedProject) {
    return null;
  }

  const actionComponent = (
    <FaroSwitch
      disabled={
        !isProjectEditable ||
        isProjectDemo(selectedProject) ||
        isFaceBlurringEnabled ||
        !canEditProjectSettings
      }
      checked={isFaceBlurringEnabled}
      isLoading={isLoading}
      onChange={(event, isChecked) => onChangeFeatureSetting(isChecked)}
      tooltipTextNotChecked={TOOLTIP_TEXT_NOT_CHECKED}
      tooltipTextChecked={TOOLTIP_TEXT_CHECKED}
      sx={{ cursor: !isProjectEditable ? "not-allowed" : "pointer" }}
    />
  );

  return (
    <FeatureSettingLayout
      featureName="Automatic Face Blurring"
      featureDescription="Blurs all faces visible in the 360 images of this project."
      actionComponent={actionComponent}
    />
  );
}
