import {
  IntegrationsService,
  IntegrationsServiceProps,
} from "@services/integrations-service/integrations-service";
import { useMemo } from "react";

/** Returns a memoized instance of the integrations service */
export function useIntegrationsService({
  coreApiClient,
  projectApiClient,
  procoreApiUrl,
}: IntegrationsServiceProps): IntegrationsService {
  return useMemo(() => new IntegrationsService({
    coreApiClient,
    projectApiClient,
    procoreApiUrl,
  }), [coreApiClient, procoreApiUrl, projectApiClient]);
}
