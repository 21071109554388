import { useCallback } from "react";
import { ProjectApi } from "@api/project-api/project-api";
import { useCancelRevision } from "@hooks/data-management/use-cancel-revision";
import { useToast } from "@hooks/use-toast";
import { GUID } from "@faro-lotv/foundation";

type ReturnFunction = (
  projectApiClient: ProjectApi,
  registrationRevisionId: GUID,
) => Promise<void>;

/** Handles the case when all uploads failed. */
export function useHandleAllUploadsFailed(): ReturnFunction {
  const { showToast } = useToast();
  const cancelRevision = useCancelRevision();

  return useCallback(
    async (
      projectApiClient: ProjectApi,
      registrationRevisionId: GUID
    ): Promise<void> => {
      showToast({
        message: "All Blink scan imports failed. Please try to upload them again.",
        type: "error",
      });

      await cancelRevision(projectApiClient, registrationRevisionId);
    },
    [cancelRevision, showToast]
  );
}
