import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "@src/index.css";
import { store } from "@store/store";
import { RouterProvider } from "react-router-dom";
import { router } from "@router/router";
import { AuthProvider } from "@context-providers/auth/auth-provider";
import { LoadingSpinnerProvider } from "@context-providers/loading-spinner-provider";
import { ErrorBoundary } from "@context-providers/error-boundary/error-boundary";
import { ToastProvider } from "@context-providers/toast/toast-provider";
import { NetworkConnectionProvider } from "@context-providers/network-connection/network-connection-provider";

createRoot(document.getElementById("root") as HTMLElement).render(
  <StrictMode>
    <Provider store={store}>
      <ToastProvider>
        <NetworkConnectionProvider>
          <ErrorBoundary>
            <LoadingSpinnerProvider>
              <AuthProvider>
                <RouterProvider
                  router={router}
                  future={{
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    v7_startTransition: true,
                  }}
                />
              </AuthProvider>
            </LoadingSpinnerProvider>
          </ErrorBoundary>
        </NetworkConnectionProvider>
      </ToastProvider>
    </Provider>
  </StrictMode>
);
