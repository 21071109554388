import {
  Box,
  BoxProps,
  ThemeProvider,
  Tooltip,
  TooltipProps,
  createTheme,
} from "@mui/material";
import { sphereColors } from "@styles/common-colors";

/** The props of this tooltip are the same as the MUI Tooltip */
export interface SphereTooltipProps {
  /**
   * Any content that will be the activator of the tooltip.
   * Can be a button, icon, some text, etc.
   */
  children: JSX.Element | string;

  /** Required title to show inside the tooltip */
  title: TooltipProps["title"];

  /** Optional props to override the Box element that wraps all the tooltip content */
  component?: BoxProps["component"];

  /** Optional time to wait between the initial hover and showing the tooltip content */
  enterDelay?: TooltipProps["enterDelay"];

  /** Optional props to override the MUI Tooltip element. */
  tooltipProps?: Partial<Omit<TooltipProps, "children">>;

  /** Optional props to override the Box element that wraps all the tooltip content */
  boxProps?: BoxProps;

  /**
   * Optional flag to skip the wrapper component. As default the tooltip content is
   * wrapped in a Box to make sure the tooltip has a valid component that can hold a ref.
   * If disabled make sure that the component inside the tooltip can host a ref.
   */
  shouldSkipWrapper?: boolean;

  /**
   * Optional flag to show the tooltip, if false it won't wrap
   * the content in a tooltip and just return the children.
   * Default: true.
   */
  shouldShowTooltip?: boolean;

  /** ID to identify the tooltip. It is used for our automation framework playwright */
  dataTestId?: string;

  /** Disable the tooltip hover */
  // eslint-disable-next-line @typescript-eslint/naming-convention -- attribute from dependency
  disableHoverListener?: boolean;
}

/** Creates a theme to override the style of MUI Tooltip */
const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: sphereColors.gray950,
          color: sphereColors.gray100,
          fontSize: "12px",
        },
      },
    },
  },
});

/* Default time in ms to wait on hover to open the tooltip */
const DEFAULT_ENTER_DELAY = 600;

/* Default Y offset for the tooltip */
const Y_OFFSET = -4;

/**
 * React component for a tooltip that follows our design guidelines.
 */
export function SphereTooltip({
  children,
  title,
  component = "div",
  enterDelay = DEFAULT_ENTER_DELAY,
  tooltipProps,
  boxProps,
  shouldSkipWrapper = false,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  disableHoverListener = false,
  shouldShowTooltip = true,
  dataTestId,
}: SphereTooltipProps): JSX.Element {
  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={title}
        data-testid={dataTestId}
        enterDelay={enterDelay}
        enterNextDelay={enterDelay}
        disableHoverListener={disableHoverListener}
        PopperProps={{
          // To hide the tooltip, keep the children wrapped in the component and just use the hidden prop.
          // Otherwise the parent component might behave different when switching from being wrapped.
          // eslint-disable-next-line @typescript-eslint/naming-convention -- external package
          hidden: !shouldShowTooltip,
          popperOptions: {
            modifiers: [
              {
                name: "offset",
                options: {
                  // Move the tooltip a bit to the left to align with the button
                  // [x, y]
                  offset: [0, Y_OFFSET],
                },
              },
            ],
          },
        }}
        {...tooltipProps}
      >
        {/* If the children is a string we must put it inside a box wrapper, otherwise the tooltip
            does not have a component to attach to. */}
        {shouldSkipWrapper && typeof children !== "string" ? (
          children
        ) : (
          <Box component={component} {...boxProps}>
            {children}
          </Box>
        )}
      </Tooltip>
    </ThemeProvider>
  );
}
