import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import { setBulkActionName } from "@store/table/table-slice";
import { useEffect, useState } from "react";
import { BulkSuccessDialog } from "@components/common/faro-table/bulk-dialog/bulk-success-dialog";
import {
  bulkActionNameSelector,
  selectedEntitiesSelector,
  shouldShowSuccessDialogSelector,
} from "@store/table/table-selector";
import { BulkActionEvents } from "@utils/track-event/track-event-list";
import { useCoreApiClient } from "@api/use-core-api-client";
import { SdbProject } from "@custom-types/project-types";
import { updateProjectDetails } from "@store/projects/projects-slice-thunk";
import { FaroBulkDialog } from "@components/common/faro-table/bulk-dialog/bulk-dialog";
import { useChangeAccessLevelBulkDialogContent } from "@components/table/projects/bulk-actions/bulk-change-project-access-level-helper";
import { FaroTextMenuButton } from "@components/common/faro-text-menu-button";
import { ProjectAccessLevelDropdown } from "@components/project/project-access-level-dropdown";

/** Button allowing to change project access level in company */
export function BulkChangeProjectAccessLevel(): JSX.Element {
  const [shouldShowDialog, setShouldShowDialog] = useState<boolean>(false);
  const [newAccessLevel, setNewAccessLevel] =
    useState<SphereDashboardAPITypes.EAccessLevel>(
      SphereDashboardAPITypes.EAccessLevel.private
    );

  const bulkActionName = useAppSelector(bulkActionNameSelector);

  const dispatch = useAppDispatch();
  const coreApiClient = useCoreApiClient();
  const shouldShowSuccessDialog = useAppSelector(
    shouldShowSuccessDialogSelector
  );
  const selectedEntities = useAppSelector(selectedEntitiesSelector("projects"));

  const { bulkDialogContent, successDialogContent } =
    useChangeAccessLevelBulkDialogContent(newAccessLevel);

  // Closes the bulk dialog when the bulk action is successful to show the success dialog
  useEffect(() => {
    if (shouldShowSuccessDialog) {
      setShouldShowDialog(false);
    }
  }, [shouldShowSuccessDialog]);

  function onAccessLevelChange(
    accessLevel: SphereDashboardAPITypes.EAccessLevel
  ): void {
    dispatch(setBulkActionName("changeAccessLevel"));
    setNewAccessLevel(accessLevel);
    setShouldShowDialog(true);
  }

  return (
    <>
      <FaroTextMenuButton
        buttonTitle="Change access level"
        tooltipText="Change access level of selected projects"
        buttonSx={{
          fontWeight: 600,
        }}
      >
        <ProjectAccessLevelDropdown onAccessLevelChange={onAccessLevelChange} />
      </FaroTextMenuButton>

      {/* Checking for bulkActionName to make sure the dialog of another bulk action is not opened by mistake */}
      {bulkActionName === "changeAccessLevel" && shouldShowSuccessDialog && (
        <BulkSuccessDialog dialogContent={successDialogContent} />
      )}

      {bulkActionName === "changeAccessLevel" && shouldShowDialog && (
        <FaroBulkDialog
          dialogContent={bulkDialogContent}
          onClose={() => setShouldShowDialog(false)}
          trackingEvent={BulkActionEvents.bulkChangeGroup}
          bulkActionCallback={(project: SdbProject) =>
            dispatch(
              updateProjectDetails({
                coreApiClient,
                projectId: project.id,
                payload: { accessLevel: newAccessLevel },
              })
            ).unwrap()
          }
          selectedEntities={selectedEntities}
          uniqueIdKey="id"
          tableDataType="projects"
        />
      )}
    </>
  );
}
