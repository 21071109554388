import { Box, Grid } from "@mui/material";
// Here we do not need it as a React component because will be used as src for an image
import groupThumbnail1 from "@src/assets/group-thumbnail_default.svg";
import groupThumbnail2 from "@src/assets/group-thumbnail_2.svg";
import groupThumbnail3 from "@src/assets/group-thumbnail_3.svg";
import groupThumbnail4 from "@src/assets/group-thumbnail_4.svg";
import groupThumbnail5 from "@src/assets/group-thumbnail_5.svg";
import groupThumbnail6 from "@src/assets/group-thumbnail_6.svg";

import projectThumbnail1 from "@src/assets/sdb-default-project-thumbnail_1.svg";
import projectThumbnail2 from "@src/assets/project-thumbnail_2.svg";
import projectThumbnail3 from "@src/assets/project-thumbnail_3.svg";
import projectThumbnail4 from "@src/assets/project-thumbnail_4.svg";
import projectThumbnail5 from "@src/assets/project-thumbnail_5.svg";

import GenericCheckIcon from "@assets/icons/generic-check_l.svg?react";
import { sphereColors } from "@styles/common-colors";
import { ThumbnailSubjectType } from "@utils/thumbnail-utils";

interface Props {
  /** The subject type (group or project) for thumbnail display */
  subjectType: ThumbnailSubjectType;

  /** The selected image from gallery */
  selectedThumbnail: string;

  /** Callback function to enable or disable confirm selection of image from gallery */
  onSelectImage(imageUrl: string): void;
}

/** List of default thumbnail images for group */
const groupThumbnails = [
  groupThumbnail1,
  groupThumbnail2,
  groupThumbnail3,
  groupThumbnail4,
  groupThumbnail5,
  groupThumbnail6,
];

/** List of default thumbnail images for project */
const projectThumbnails = [
  projectThumbnail1,
  projectThumbnail2,
  projectThumbnail3,
  projectThumbnail4,
  projectThumbnail5,
];

/** Map of thumbnails based on the subject type */
const thumbnailsMap = {
  [ThumbnailSubjectType.project]: projectThumbnails,
  [ThumbnailSubjectType.group]: groupThumbnails,
};

/**
 * Renders the thumbnail gallery tab content for a given subject type.
 *
 * @param {Props} props - The component properties, including subject type, selected thumbnail, and callback function.
 *
 * @returns {JSX.Element} A grid of selectable thumbnails based on the specified subject type
 */
export function FaroThumbnailSelector({
  subjectType,
  selectedThumbnail,
  onSelectImage,
}: Props): JSX.Element {
  const thumbnails = thumbnailsMap[subjectType];
  return (
    <Grid container width="100%" marginTop="12px" spacing={1.5}>
      {thumbnails.map((thumbnail) => {
        const isSelected = selectedThumbnail === thumbnail;

        return (
          <Grid
            item
            key={thumbnail}
            xs={6}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            component="div"
            position="relative"
          >
            <Box
              component="img"
              src={thumbnail}
              alt={`${subjectType} thumbnail`}
              onClick={() => onSelectImage(thumbnail)}
              sx={{
                width: "100%",
                height: "105px",
                objectFit: "contain",
                borderRadius: "8px",
                padding: "5px",
                cursor: "pointer",
                border: isSelected
                  ? `2px solid ${sphereColors.blue500}`
                  : `1px solid ${sphereColors.gray200}`,
                "&:hover": {
                  border: `2px solid ${sphereColors.blue500}`,
                },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
            {isSelected && (
              <GenericCheckIcon
                style={{
                  fill: sphereColors.blue500,
                  height: "32px",
                  width: "32px",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}
