import { Box, Typography } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { DEFAULT_TEXT_FONT_SIZE, withEllipsis } from "@styles/common-styles";
import { bytesToMegaBytes } from "@utils/file-utils";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { SphereTooltip } from "@components/common/sphere-tooltip";
import CancelIcon from "@assets/icons/Cancel.svg?react";
import InfoIcon from "@assets/icons/new/info_20px.svg?react";
import ScanDataIcon from "@assets/icons/new/scan-data_24px.svg?react";
import CheckmarkIcon from "@assets/icons/new/checkmark-circle_24px.svg?react";

interface Props {
  /** The name of the file */
  fileName: string;

  /** The translatable title of the file, to be displayed instead of the fileName. Also determines the icon. */
  fileTitle?: "Scan Metadata";

  /** The size of the file in byte */
  fileSize: number;

  /** True if the file is valid */
  isValid?: boolean;

  /** True if the file was already uploaded, and will therefore be skipped. */
  isExistingScan?: boolean;

  /** Callback function to remove file from list */
  onDelete?(): void;
}

/** Class name for the scan data file size */
const scanDataFileSizeClass = "scanDataFileSizeClass";

/** Class name for the scan data remove button */
const scanDataRemoveClass = "scanDataRemoveClass";

const existingScanOpacity = 0.5;

/** Renders scan data files  */
export function ScanDataFile({
  fileName,
  fileTitle,
  fileSize,
  isValid = true,
  isExistingScan = false,
  onDelete,
}: Props): JSX.Element {
  let icon: JSX.Element;
  if (fileTitle === "Scan Metadata") {
    // The info icon is aligned slightly different, so we need a margin.
    icon = <InfoIcon style={{marginTop: "2px", marginLeft: "2px"}} />;
  } else if (isExistingScan) {
    icon = <CheckmarkIcon style={{opacity: existingScanOpacity}} />;
  } else {
    icon = <ScanDataIcon />;
  }

  return (
    <SphereTooltip title={isExistingScan ? "This scan will be skipped because it was already uploaded." : undefined}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          border: `1px solid ${
            isValid ? sphereColors.gray200 : sphereColors.red300
          }`,
          background: sphereColors.gray50,
          padding: "6px",
          mb: "2px",
          "&:hover": {
            [`& .${scanDataFileSizeClass}`]: {
              display: "none",
            },
            [`& .${scanDataRemoveClass}`]: {
              display: "block",
            },
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 0.5,
            width: "calc(100% - 70px)",
          }}
        >
          {/* Icon */}
          <Box sx={{ width: "24px", height: "24px" }}>
            {icon}
          </Box>

          {/* File name */}
          <Typography
            sx={{
              fontSize: DEFAULT_TEXT_FONT_SIZE,
              color: sphereColors.gray800,
              opacity: isExistingScan ? existingScanOpacity : undefined,
              ...withEllipsis,
            }}
          >
            {fileTitle ? fileTitle : <var>{fileName}</var>}
          </Typography>
        </Box>

        {/* File Size */}
        <Typography
          className={scanDataFileSizeClass}
          sx={{ fontSize: "10px", color: sphereColors.gray800 }}
        >
          {fileSize >= 0 ? <var>{bytesToMegaBytes(fileSize)} MB</var> : null}
        </Typography>

        <Box
          className={scanDataRemoveClass}
          sx={{
            display: "none",
          }}
        >
          <FaroIconButton
            component={CancelIcon}
            onClick={onDelete}
            buttonSize="22px"
            iconSize="16px"
            isDisabled={!onDelete}
          />
        </Box>
      </Box>
    </SphereTooltip>
  );
}
