import CircularProgress, {
  circularProgressClasses,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { sphereColors } from "@styles/common-colors";
import { useMemo } from "react";

interface Props {
  /** Progress value to show inside the spinner */
  value?: number;

  /** Max value. If not set the default is 100 */
  maxValue?: number;

  /** Text to show under the spinner */
  label?: string;

  /** If passed the default percentage unit will be replaced by the given unit */
  unit?: string;

  /** If true the value will not be displayed in the center */
  shouldHideValue?: boolean;

  /** Size of the circular progress */
  size?: number;

  /** Thickness of the circular progress */
  thickness?: number;
}

/** Default size (height and width) of the circular progress in pixels */
const CIRCULAR_PROGRESS_SIZE = 60;

/** Default thickness for the circular progress, it is unitless and refers to CircularProgress thickness */
const CIRCULAR_PROGRESS_THICKNESS = 4;

/** Render a circular progress spinner with progress value if provided */
export function SphereCircularProgress({
  value,
  maxValue,
  label,
  unit,
  shouldHideValue,
  size,
  thickness,
}: Props): JSX.Element {
  const circleSize = useMemo(() => {
    return `${size ?? CIRCULAR_PROGRESS_SIZE}px`;
  }, [size]);

  const indicatorThickness = useMemo(() => {
    return thickness ?? CIRCULAR_PROGRESS_THICKNESS;
  }, [thickness]);

  const indicatorUnit = useMemo(() => {
    return typeof unit === "string" ? unit : "%";
  }, [unit]);

  const indicatorValue = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }
    return value > 0 ? value : 0;
  }, [value]);

  const progress = useMemo(() => {
    if (indicatorValue === undefined) {
      return;
    }

    const indicatorMaxValue = maxValue ?? 100;

    if (indicatorValue > indicatorMaxValue) {
      return 100;
    }

    return (indicatorValue / indicatorMaxValue) * 100;
  }, [maxValue, indicatorValue]);

  return (
    <Box data-testid="sphere-circular-progress">
      <Box
        sx={{
          position: "relative",
          height: circleSize,
          width: circleSize,
        }}
      >
        <CircularProgress
          variant="determinate"
          sx={{
            color: sphereColors.gray200,
            position: "absolute",
            left: 0,
          }}
          size={circleSize}
          thickness={indicatorThickness}
          value={100}
        />
        <CircularProgress
          variant={
            typeof indicatorValue !== "undefined"
              ? "determinate"
              : "indeterminate"
          }
          sx={{
            color: sphereColors.blue500,
            position: "absolute",
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: "round",
            },
          }}
          size={circleSize}
          thickness={indicatorThickness}
          value={progress}
        />
        {typeof indicatorValue !== "undefined" && !shouldHideValue && (
          <Box
            data-testid="sphere-circular-progress-value"
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color={sphereColors.gray800}
            >{`${Math.round(indicatorValue)}${indicatorUnit}`}</Typography>
          </Box>
        )}
      </Box>

      {label && (
        <Typography
          data-testid="sphere-circular-progress=label"
          variant="caption"
          component="div"
          color={sphereColors.gray800}
          sx={{ paddingTop: "10px" }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
}
