import { Box, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { EDecimalToHex, sphereColors } from "@styles/common-colors";
import { FaroChip } from "@faro-lotv/flat-ui";
import { addTransparency } from "@utils/ui-utils";
import { ILabel } from "@faro-lotv/ielement-types";

interface Props {
  /** An array of tags to be displayed */
  labels: ILabel[] | null | undefined;
}

/**
 * MarkupSidePanelTags is a component that displays a list of tags.
 *
 * @param labels - An array of ILabel objects that represent the tags to be displayed.
 */
export function MarkupSidePanelTags({ labels }: Props): JSX.Element {
  /** receive the tags and sort them alphabetically */
  const sortedTags = useMemo(() => {
    if (!labels) {
      return [];
    }

    return [...labels].sort((a, b) => a.name.localeCompare(b.name));
  }, [labels]);

  return (
    <Box
      component="div"
      marginTop={"40px"}
      data-testid="markup-side-panel-tags-content"
    >
      <Typography
        sx={{
          fontWeight: "700",
          fontSize: "12px",
          color: sphereColors.gray700,
        }}
      >
        Tags
      </Typography>

      {sortedTags.length === 0 ? (
        "-"
      ) : (
        <Stack
          gap={"8px"}
          marginTop={"6px"}
          flexDirection={"row"}
          flexWrap={"wrap"}
        >
          {sortedTags.map((tag) => (
            <FaroChip
              key={tag.id}
              sx={{
                maxWidth: "390px",
              }}
              backgroundColor={sphereColors.gray100}
              color={sphereColors.gray800}
              borderColor={addTransparency({
                color: sphereColors.gray800,
                alpha: EDecimalToHex.thirtyTwo,
              })}
              label={<var>{tag.name}</var>}
              size="small"
              textTransform="capitalize"
              variant="outlined"
              icon={
                tag.color ? (
                  <Box
                    sx={{
                      height: "10px",
                      width: "10px",
                      borderRadius: "50%",
                      background: tag.color,
                      marginRight: "6px",
                    }}
                  />
                ) : undefined
              }
              tooltip={tag.name}
            />
          ))}
        </Stack>
      )}
    </Box>
  );
}
