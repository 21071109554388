import {
  CanceledUploadFile,
  ElsScanFileUploadTaskContext,
  FileUploadTaskContext,
  MultiFileUploadResponse,
  MultiUploadedFileResponse,
  SingleFileUploadResponse,
  UploadedFile,
  UploadedFileResponse,
  UploadElementType,
  UploadFailedFile,
} from "@custom-types/file-upload-types";
import { isString } from "lodash";

/** @returns True if `context` is an ElsScanFileUploadTaskContext. */
export function isElsScanFileUploadTaskContext(
  context: FileUploadTaskContext
): context is ElsScanFileUploadTaskContext {
  // One could check many properties here, but it seems less fragile to only check the most important ones.
  return (
    context?.uploadElementType === UploadElementType.elsScan &&
    (typeof context?.projectId === "string" || typeof context?.projectId === "number")
  );
}

/** Whether the uploadedFileResponse is of type SingleFileUploadResponse */
export function isSingleFileUploadResponse(
  uploadedFileResponse: UploadedFileResponse
): uploadedFileResponse is SingleFileUploadResponse {
  return isString(uploadedFileResponse);
}

/** Whether the uploadedFileResponse is of type MultiUploadedFileResponse */
export function isMultiUploadedFileResponse(
  uploadedFileResponse: UploadedFileResponse
): uploadedFileResponse is MultiUploadedFileResponse {
  return (
    !isSingleFileUploadResponse(uploadedFileResponse) &&
    "successful" in uploadedFileResponse &&
    "failed" in uploadedFileResponse &&
    "canceled" in uploadedFileResponse
  );
}

/** Whether the MultiFileUploadResponse is of type UploadedFile */
export function isUploadedFile(
  uploadedFileResponse: MultiFileUploadResponse
): uploadedFileResponse is UploadedFile {
  return (
    "id" in uploadedFileResponse &&
    typeof uploadedFileResponse.id === "string" &&
    "fileName" in uploadedFileResponse &&
    typeof uploadedFileResponse.fileName === "string" &&
    "fileSize" in uploadedFileResponse &&
    typeof uploadedFileResponse.fileSize === "number" &&
    "fileType" in uploadedFileResponse &&
    typeof uploadedFileResponse.fileType === "string" &&
    "downloadUrl" in uploadedFileResponse &&
    typeof uploadedFileResponse.downloadUrl === "string" &&
    "md5" in uploadedFileResponse &&
    typeof uploadedFileResponse.md5 === "string"
  );
}

/** Whether the MultiFileUploadResponse is of type UploadFailedFile */
export function isUploadFailedFile(
  uploadedFileResponse: MultiFileUploadResponse
): uploadedFileResponse is UploadFailedFile {
  return (
    "id" in uploadedFileResponse &&
    typeof uploadedFileResponse.id === "string" &&
    "fileName" in uploadedFileResponse &&
    typeof uploadedFileResponse.fileName === "string" &&
    "errorMessage" in uploadedFileResponse &&
    typeof uploadedFileResponse.errorMessage === "string"
  );
}

/** Whether the MultiFileUploadResponse is of type CanceledUploadFile */
export function isCanceledUploadFile(
  uploadedFileResponse: MultiFileUploadResponse
): uploadedFileResponse is CanceledUploadFile {
  return (
    "id" in uploadedFileResponse &&
    typeof uploadedFileResponse.id === "string" &&
    "fileName" in uploadedFileResponse &&
    typeof uploadedFileResponse.fileName === "string" &&
    !("errorMessage" in uploadedFileResponse) &&
    !("downloadUrl" in uploadedFileResponse)
  );
}
