import { SdbTeamMemberBase } from "@custom-types/teams-types";
import { CoreAPITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { getPrettyName } from "@utils/user-utils";

/** Maps team members to sdb members */
export function mapTeamMembersToSdbMembers(
  members: SphereDashboardAPITypes.ITeamMember[]
): SdbTeamMemberBase[] {
  return members.map((member) => ({
    ...member.userResponse,
    name: getPrettyName(member.userResponse),
    role: CoreAPITypes.EUserCompanyRole.member,
  }));
}
