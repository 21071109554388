import { Link, SxProps } from "@mui/material";
import { sphereColors } from "@styles/common-colors";

interface FaroLinkProps {
  /** The title or text content of the link. */
  title: string;

  /** The URL or destination of the link. */
  link: string;

  /** Controls when the link should have an underline. */
  underline?: "none" | "hover" | "always";

  /** Style props */
  sx?: SxProps;
}

/**
 * @returns a link
 */
export function FaroLink({
  title,
  link,
  underline = "always",
  sx,
}: FaroLinkProps): JSX.Element {
  return (
    <Link
      href={link}
      target="_blank"
      color={sphereColors.blue500}
      data-testid="faro-link"
      underline={underline}
      sx={{
        fontSize: "14px",
        fontWeight: "600",
        textDecorationColor: sphereColors.blue500,
        ...sx,
      }}
    >
      {title}
    </Link>
  );
}
