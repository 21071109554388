import { IReactChildrenOnly } from "@custom-types/types";
import { LocalizeProvider } from "@faro-lotv/foreign-observers";
import { runtimeConfig } from "@src/runtime-config";

/** The localization loader making it possible to implement language translation */
export function LocalizeLoader({ children }: IReactChildrenOnly): JSX.Element {
  return (
    <LocalizeProvider projectKey={runtimeConfig.features.localizeKey}>
      {children}
    </LocalizeProvider>
  );
}
