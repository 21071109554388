import { ExportAsCSV } from "@utils/export-as-csv";
import { TableType } from "@utils/track-event/track-event-list";
import { downloadTeamDataAsCSV } from "@utils/csv-data-export";
import { useDateTime } from "@hooks/use-date-time";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { TEAMS_DISPLAY_NAME } from "@src/constants/team-constants";

interface Props {
  /** List of selected teams in table */
  selectedTeams: SphereDashboardAPITypes.ITeam[];

  /** True if some entities are selected */
  areEntitiesSelected: boolean;
}

/** Button to export selected teams as CSV */
export function ExportTeamsAsCSV({
  selectedTeams,
  areEntitiesSelected,
}: Props): JSX.Element {
  const { formatDate } = useDateTime();
  return (
    <ExportAsCSV
      trackingProps={{
        dataType: TableType.teams,
        numberOfEntities: selectedTeams.length,
        areEntitiesSelected,
      }}
      csvExportHandler={() => downloadTeamDataAsCSV(selectedTeams, formatDate)}
      toastMessage={{
        success: `Successfully exported ${TEAMS_DISPLAY_NAME} as CSV.`,
        error: `Cannot export ${TEAMS_DISPLAY_NAME}. Please try again.`,
      }}
      buttonTooltipText={`Export ${TEAMS_DISPLAY_NAME}`}
    />
  );
}
