import { SphereTooltip } from "@components/common/sphere-tooltip";
import { FaroIconButton } from "@components/common/faro-icon-button";
import { Box } from "@mui/material";
import ShareIcon from "@assets/icons/Share.svg?react";
import OpenViewerIcon from "@assets/icons/new/open-in-viewer.svg?react";
import OpenWebEditorIcon from "@assets/icons/new/open-in-holobuilder.svg?react";
import { FARO_CARD_ACTIONS } from "@components/common/faro-card/faro-card";
import { useDialog } from "@components/common/dialog/dialog-provider";
import {
  OPEN_PROJECT_TARGET_ATTRIBUTE,
  OpenProjectProps,
  getOpenProjectHref,
  getOpenProjectTargetName,
  trackOpenProject,
  trackShareProject,
} from "@utils/project-utils";
import {
  ShareProject,
  SHARE_PROJECT_DIALOG_PROVIDER_PROPS,
} from "@components/project/share-project";
import { SdbProject } from "@custom-types/project-types";
import { MouseEvent, useMemo } from "react";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { ProjectLaunchTarget } from "@custom-types/sdb-company-types";
import { useAppSelector } from "@store/store-helper";
import {
  defaultProjectLaunchTargetSelector,
} from "@store/sdb-company/sdb-company-selector";
import { SphereActionDivider } from "@components/common/sphere-action-divider";
import { useTrackEvent } from "@utils/track-event/use-track-event";

/** Size in pixels for each project action */
export const ACTION_BTN_SIZE = "30px";

/** Size in pixels for each project action icon */
export const ACTION_ICON_SIZE = "18px";

/** Determines the context or places where the the ProjectActions component can be used */
export type ProjectActionContext =
  | "project-list-view"
  | "project-tiles-view"
  | "group-project-list-view"
  | "member-group-project-list-view";

interface Props {
  /** Project entity */
  project: SdbProject | SphereDashboardAPITypes.GroupDetailsProjectSummary;

  /** Determines on which context the ProjectActions component is being used */
  contextType: ProjectActionContext;
}

/** Project actions buttons displayed in a project card or a project row */
export function ProjectActions({ project, contextType }: Props): JSX.Element {
  const { createDialog } = useDialog();
  const { trackEvent, trackAsyncEvent } = useTrackEvent();
  const projectLaunchSettings = useAppSelector(
    defaultProjectLaunchTargetSelector
  );

  // Decides the title for the open project button
  const openProjectTitle = useMemo(() => {
    // If it should not show the default app setting, then it should just say "Open project"
    // Since there is only one option to open the project.
    if (!projectLaunchSettings.shouldShowDefaultAppSetting) {
      // For workspaces that are Sphere XG exclusive, saying "Open project in Sphere XG" is redundant
      return "Open project";
    }

    return `Open project in ${getOpenProjectTargetName(
      projectLaunchSettings.defaultOpenProjectTarget
    )}`;
  }, [
    projectLaunchSettings,
  ]);

  /** Triggered to share a project by link */
  function onShareProject(event: MouseEvent): void {
    // We need to preventDefault and stopPropagation to avoid having the default
    // click behavior for the card of opening project details.
    event.preventDefault();
    event.stopPropagation();

    trackShareProject({
      ...project,
      eventLocation: contextType,
      trackEvent,
    });

    createDialog(
      SHARE_PROJECT_DIALOG_PROVIDER_PROPS,
      <ShareProject projectId={project.id} />
    );
  }

  /** Triggered to open a project in the Sphere Viewer */
  async function onTrackOpenProject({
    clickType,
  }: Pick<OpenProjectProps, "clickType">): Promise<void> {
    const numberOfMembers =
      "members" in project && project.members
        ? project.members.length
        : undefined;

    await trackOpenProject({
      openFrom: contextType,
      numberOfMembers,
      openTarget: projectLaunchSettings.defaultOpenProjectTarget,
      clickType,
      ...project,
      trackAsyncEvent,
    });
  }

  return (
    <Box
      component="div"
      className={FARO_CARD_ACTIONS}
      sx={{
        display: "flex",
        alignItems: "center",
        // When the context is "project-tiles-view" the visibility is overwritten in the hover of the parent component
        // Otherwise visibility is performed in general faro table component
        visibility: contextType === "project-tiles-view" ? "hidden" : undefined,
      }}
    >
      <SphereTooltip title="Share project">
        <FaroIconButton
          buttonSize={ACTION_BTN_SIZE}
          iconSize={ACTION_ICON_SIZE}
          component={ShareIcon}
          onClick={onShareProject}
        />
      </SphereTooltip>

      <SphereActionDivider />

      <SphereTooltip title={openProjectTitle}>
        <FaroIconButton
          buttonSize={ACTION_BTN_SIZE}
          iconSize={ACTION_ICON_SIZE}
          component={
            projectLaunchSettings.defaultOpenProjectTarget ===
            ProjectLaunchTarget.sphereViewer
              ? OpenViewerIcon
              : OpenWebEditorIcon
          }
          href={getOpenProjectHref({
            projectId: project.id,
            openTarget: projectLaunchSettings.defaultOpenProjectTarget,
          })}
          target={OPEN_PROJECT_TARGET_ATTRIBUTE}
          onClick={(event) => {
            // Prevents the propagation of the click event to avoid opening the project details page.
            event.stopPropagation();
            onTrackOpenProject({ clickType: "left button click" });
          }}
          onAuxClick={() => {
            onTrackOpenProject({ clickType: "middle button click" });
          }}
          onContextMenu={() => {
            onTrackOpenProject({ clickType: "open context menu" });
          }}
        />
      </SphereTooltip>
    </Box>
  );
}
