import { MemberTypes } from "@custom-types/member-types";
import { Box, Button, Grid } from "@mui/material";
import { sphereColors } from "@styles/common-colors";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { useMembersUtils } from "@hooks/use-member-utils";

interface Props {
  /** The member to show the open profile button */
  member?: MemberTypes | SphereDashboardAPITypes.IUserAsManager | null;
}

/**
 * Component that shows the button to open the profile page of the user.
 */
export function UserMoreInfoMenuProfileButton({ member }: Props): JSX.Element {
  const { openProfilePage } = useMembersUtils();

  /**
   * Opens the profile page of the user.
   * The permission validation is done in the canOpenProfilePage variable.
   */
  function onProfileClick(event: React.MouseEvent): void {
    // Since this button is normally shown inside a table row, we need to stop the propagation
    // otherwise it will count the click on the row.
    event.stopPropagation();

    openProfilePage({
      userIdentity: member?.identity,
      source: "user more info menu",
    });
  }

  if (!member) {
    // eslint-disable-next-line react/jsx-no-useless-fragment -- we need as empty component.
    return <></>;
  }

  return (
    <Box
      component="div"
      sx={{
        padding: "10px",
      }}
    >
      <Button
        disableFocusRipple={true}
        disableRipple={true}
        sx={{
          color: sphereColors.gray750,
          fontSize: "14px",
          width: "100%",
          height: "37px",
          backgroundColor: sphereColors.pureWhite,
          border: "none",
          textAlign: "left",
          textTransform: "none",
          "&:hover": {
            backgroundColor: sphereColors.gray100,
          },
          "&:active": {
            backgroundColor: sphereColors.gray200,
          },
          "&:hover,:active": {
            color: sphereColors.blue500,
            cursor: "pointer",
          },
        }}
        onClick={onProfileClick}
      >
        <Grid container textAlign="start">
          Profile
        </Grid>
      </Button>
    </Box>
  );
}
