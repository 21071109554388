import { useEffect, useState } from "react";
import { useCoreApiClient } from "@api/use-core-api-client";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  fetchCompaniesAndWorkspaces,
  fetchCompanyContext,
  fetchCompanyFeatures,
  setSelectedSdbCompanyId,
  fetchCompanyCommunicationSettings,
} from "@store/sdb-company/sdb-company-slice";
import {
  selectedSdbCompanyIdSelector,
  fetchingSdbCompanyFlagsSelector,
  sdbCompaniesSelector,
} from "@store/sdb-company/sdb-company-selector";
import { fetchCurrentUser, getLoggedInUser } from "@store/user/user-slice";
import { currentUserSelector } from "@store/user/user-selector";
import { useAppParams } from "@router/router-helper";
import { useAppNavigation } from "@hooks/use-app-navigation";
import { useLoadingSpinner } from "@context-providers/loading-spinner-provider";
import { IReactChildrenOnly } from "@custom-types/types";
import { setSelectedProjectId } from "@store/projects/projects-slice";
import { SelectWorkspacePage } from "@pages/workspace/select-workspace-page";
import { AuthenticatedRoute } from "@router/authenticated-route";
import { useToast } from "@hooks/use-toast";
import { useWorkspaceParams } from "@hooks/workspaces/use-workspace-params";
import { runtimeConfig } from "@src/runtime-config";
import { useTrackPageLoad } from "@hooks/use-track-page-load";

/**
 * Hooks that loads sdbCompanies (workspaces/companies) and redirect to main app page from root
 */
export function SdbCompanyLoader({
  children,
}: IReactChildrenOnly): JSX.Element {
  useTrackPageLoad();
  const appParams = useAppParams();

  const currentUser = useAppSelector(currentUserSelector);
  const selectedSdbCompanyId = useAppSelector(selectedSdbCompanyIdSelector);
  const { isFetchingSdbCompanies } = useAppSelector(
    fetchingSdbCompanyFlagsSelector
  );
  const sdbCompanies = useAppSelector(sdbCompaniesSelector);

  const coreApiClient = useCoreApiClient();
  const dispatch = useAppDispatch();
  const { navigateToProjects, navigateToRoot } = useAppNavigation();
  const { setLoadingSpinner, isLoadingSpinnerShowing } = useLoadingSpinner();
  const { showToast } = useToast();
  useWorkspaceParams();

  const [shouldShowSelectWorkspaceMenu, setShouldShowSelectWorkspaceMenu] =
    useState<boolean>(false);

  const [
    hasFetchedCompaniesAndWorkspaces,
    setHasFetchedCompaniesAndWorkspaces,
  ] = useState<boolean>(false);

  // Fetch all companies and workspaces only once on page load
  useEffect(() => {
    async function fetchCompaniesAndWorkspacesViaStore(): Promise<void> {
      // Error is handled in the store slice
      await dispatch(fetchCompaniesAndWorkspaces({ coreApiClient })).unwrap();
      setHasFetchedCompaniesAndWorkspaces(true);
    }
    fetchCompaniesAndWorkspacesViaStore();
  }, [coreApiClient, dispatch]);

  // Handles loading spinner when fetching companies and workspaces
  useEffect(() => {
    if (!isFetchingSdbCompanies && isLoadingSpinnerShowing) {
      setLoadingSpinner(false);
    }
  }, [isFetchingSdbCompanies, isLoadingSpinnerShowing, setLoadingSpinner]);

  // Fetches company features once on page load when user company is already defined
  useEffect(() => {
    async function fetchCompanyFeaturesViaStore(): Promise<void> {
      await dispatch(
        fetchCompanyFeatures({
          coreApiClient,
        })
      );
    }

    async function fetchCompanyContextViaStore(): Promise<void> {
      await dispatch(
        fetchCompanyContext({
          coreApiClient,
        })
      );
    }

    async function fetchCompanyCommunicationSettingsViaStore(): Promise<void> {
      await dispatch(
        fetchCompanyCommunicationSettings({
          coreApiClient,
        })
      );
    }

    if (selectedSdbCompanyId) {
      fetchCompanyFeaturesViaStore();
      fetchCompanyContextViaStore();
      fetchCompanyCommunicationSettingsViaStore();
    }
  }, [coreApiClient, dispatch, selectedSdbCompanyId]);

  // Fetch current user if not defined and when user company is already set
  useEffect(() => {
    async function fetchCurrentUserViaStore(): Promise<void> {
      if (selectedSdbCompanyId && !currentUser) {
        await dispatch(
          fetchCurrentUser({ coreApiClient, companyId: selectedSdbCompanyId })
        );
      }
    }

    fetchCurrentUserViaStore();
  }, [coreApiClient, currentUser, dispatch, selectedSdbCompanyId]);

  // Fetch logged in user
  useEffect(() => {
    dispatch(getLoggedInUser({ coreApiClient }));
  }, [coreApiClient, dispatch]);

  // Handles setting the selected company
  useEffect(() => {
    /**
     * Looks for the first company the user has access to and navigates to it.
     */
    function navigateToFirstCompany(): void {
      const firstCompany = sdbCompanies[0];
      if (
        firstCompany &&
        window.location.hostname !== "localhost" &&
        // If the company url is from the old dashboard
        firstCompany.url.includes(runtimeConfig.urls.hbDashboardUrl)
      ) {
        // Makes sure to redirect to the url that it is set on the backend
        // based on the subscriptions for that workspace.
        // This replace of href is only used to navigate to the old dashboard.
        // For the new dashboard, the navigation is handled by the router.
        window.location.href = firstCompany.url;
      } else {
        navigateToProjects({ companyId: firstCompany.id });
      }
    }

    // Wait for user companies to be fetched
    if (!isFetchingSdbCompanies && hasFetchedCompaniesAndWorkspaces) {
      // If the companyId url param is not passed.
      if (!appParams.companyId) {
        // If the user has only one company then navigate to that company.
        if (sdbCompanies.length === 1) {
          navigateToFirstCompany();
          return;
        } else {
          // If the user has zero or many companies then show the workspace selection menu
          setShouldShowSelectWorkspaceMenu(true);
          return;
        }
      }

      // If company URL param does not belong to user
      if (!sdbCompanies.some((company) => company.id === appParams.companyId)) {
        // Show more information about the user options depending on the number of workspaces they still have access to
        let infoMessage: string;

        if (sdbCompanies.length === 0) {
          infoMessage = "Please contact the workspace administrator.";
        } else if (sdbCompanies.length === 1) {
          infoMessage =
            "You will be redirected to your only workspace. Please contact the workspace administrator.";
        } else {
          infoMessage =
            "Please select a different workspace or contact the workspace administrator.";
        }
        showToast({
          message: "Access denied",
          description: (
            <>
              You don't have access to workspace{" "}
              <var>{appParams.companyId}</var>. {infoMessage}
            </>
          ),
          type: "error",
        });

        if (sdbCompanies.length === 1) {
          // If the user has only one company then navigate to that company.
          navigateToFirstCompany();
          return;
        } else {
          // If the user has zero or many companies then navigate to the workspace selection menu
          setShouldShowSelectWorkspaceMenu(true);
          navigateToRoot();
          return;
        }
      }

      // If company URL param belongs to user but is not the same as the selected company
      if (appParams.companyId !== selectedSdbCompanyId) {
        // Set the new selected company
        dispatch(setSelectedSdbCompanyId(appParams.companyId));
        return;
      }

      // If company URL param belongs to user and it's the same as the selected company
      if (appParams.companyId === selectedSdbCompanyId) {
        // Hide selector workspace page
        setShouldShowSelectWorkspaceMenu(false);
        return;
      }
    }
  }, [
    appParams.companyId,
    dispatch,
    isFetchingSdbCompanies,
    navigateToProjects,
    navigateToRoot,
    sdbCompanies,
    selectedSdbCompanyId,
    hasFetchedCompaniesAndWorkspaces,
    showToast,
  ]);

  // React to projectId url param changes and keep the projectId in the store.
  useEffect(() => {
    dispatch(setSelectedProjectId(appParams.projectId ?? null));
  }, [appParams.projectId, dispatch]);

  return (
    <>
      {shouldShowSelectWorkspaceMenu && !isFetchingSdbCompanies && (
        <AuthenticatedRoute>
          <SelectWorkspacePage />
        </AuthenticatedRoute>
      )}
      {selectedSdbCompanyId && children}
    </>
  );
}
