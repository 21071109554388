import {
  APITypes,
  CoreAPITypes,
  ProjectJson,
  SphereDashboardAPITypes,
} from "@stellar/api-logic";
import { runtimeConfig } from "@src/runtime-config";
import {
  ProjectArchivingState,
  ProjectSortByAttrName,
  SdbProject,
} from "@custom-types/project-types";
import { formatAreaUnit, getPrettyDateElapsed } from "@utils/data-display";
import { getPrettyName } from "@utils/user-utils";
import { DASH } from "@utils/ui-utils";
import { MemberTypes } from "@custom-types/member-types";
import defaultThumbnail from "@src/assets/sdb-default-project-thumbnail_1.svg";
import { sphereColors } from "@styles/common-colors";
import { UrlGenerationUtils } from "@stellar/web-core";
import { ProjectEvents } from "@utils/track-event/track-event-list";
import { ProjectActionContext } from "@components/common/project-actions";
import {
  BaseProjectIdProps,
  ProjectLaunchTarget,
} from "@custom-types/sdb-company-types";
import { GUID } from "@faro-lotv/ielement-types";
import { UseTrackEvent } from "@utils/track-event/use-track-event";
import { LinkProps } from "@mui/material";
import { getAge, getDisplayAge } from "@utils/date-utils";

export const defaultProjectThumbnail = defaultThumbnail;

/** Defines the minimum length for the project name */
export const MIN_PROJECT_NAME_LENGTH = 1;

/** Defines the default project size area in sqft to be used when not provided by the the user */
export const DEFAULT_PROJECT_AREA = 1;

/** Defines the minimum number of characters to start a project search */
export const MIN_CHARACTERS_FOR_SEARCH = 3;

/** Specifies the target attribute value for opening the project. */
export const OPEN_PROJECT_TARGET_ATTRIBUTE: LinkProps["target"] = "_self";

/** Specifies the target attribute value for opening other elements annotations, snapshots, etc. */
export const OPEN_OTHERS_TARGET_ATTRIBUTE: LinkProps["target"] = "_blank";

/**
 * Sphere Viewer deep link format:
 * https://faro01.atlassian.net/wiki/spaces/SWEB/pages/3717529850/Sphere+Viewer+Deep+Link+Format
 */
interface GetSphereViewerUrlParamsProps {
  /** ID of the IElement to orient the camera towards to */
  lookAtId?: GUID;

  /** ID of the IElement being visualized */
  id?: GUID;
}

/**
 * @returns the query parameters required to build a valid Sphere Viewer deep link that
 * opens the project and shows the element specified by the ID of the element.
 */
export function getSphereViewerUrlParams({
  lookAtId,
  id,
}: GetSphereViewerUrlParamsProps): URLSearchParams {
  const params = new URLSearchParams();

  if (lookAtId) {
    params.append("lookAtId", lookAtId);
  }

  if (id) {
    params.append("id", id);
  }

  return params;
}

export function getWebEditorUrl(projectId: APITypes.ProjectId): URL {
  return new URL(
    UrlGenerationUtils.generateWebEditorUrl({
      baseUrl: runtimeConfig.urls.hbWebEditorUrl,
      projectId,
      mode: UrlGenerationUtils.EWebEditorMode.editor,
    })
  );
}

type GetSphereViewerUrlProps = GetSphereViewerUrlParamsProps &
  BaseProjectIdProps;

/**
 * Returns the URL to open a project in the Sphere Viewer.
 */
export function getSphereViewerUrl({
  projectId,
  lookAtId,
  id,
}: GetSphereViewerUrlProps): URL {
  const url = new URL(
    `project/${projectId}`,
    runtimeConfig.urls.sphereViewerUrl
  );

  const params = getSphereViewerUrlParams({
    lookAtId,
    id,
  });

  url.search = params.toString();

  return url;
}

export type OpenProjectProps = GetSphereViewerUrlProps &
  Pick<UseTrackEvent, "trackAsyncEvent"> & {
    /** To recognize exactly where the project is open from */
    openFrom:
      | ProjectActionContext
      | "demo-project-empty-page"
      | "projectDetails"
      | "projectDetails-annotations"
      | "projectDetails-data-pointClouds"
      | "projectDetails-dataManagement";

    /** Number of members in the project to use as props in tracking event. */
    numberOfMembers: number | undefined;

    /** Application target to open the project, either Sphere Viewer or WebEditor */
    openTarget?: ProjectLaunchTarget;

    /**
     * Defines the click that opened the project.
     * Click means the left mouse button, auxClick means the middle mouse button,
     * and contextMenu means the right mouse button.
     */
    clickType?:
      | "left button click"
      | "middle button click"
      | "open context menu";
  };

type TrackProjectDateProps = {
  /** Optional date when the project was last modified */
  modifiedAt?: number;

  /** Optional date when the project was created */
  createdAt?: number;
};

type TrackProjectProps = Pick<
  OpenProjectProps,
  | "openFrom"
  | "numberOfMembers"
  | "openTarget"
  | "clickType"
  | "trackAsyncEvent"
> &
  TrackProjectDateProps &
  Partial<Pick<SdbProject, "accessLevel">>;

type TrackProjectDateResult = {
  /** Returns the day in numbers from the last time a project was updated */
  lastUpdateAge?: number;

  /**
   * Returns the day in words from the last time a project was updated.
   * It combines to have 1 week, 2 weeks, 1 month, 2 months, etc.
   */
  lastUpdateAgeRange?: string;

  /** Returns the day in numbers from the creation date of the project */
  creationAge?: number;

  /**
   * Returns the day in words from the creation date of the project.
   * It combines to have 1 week, 2 weeks, 1 month, 2 months, etc.
   */
  creationAgeRange?: string;
};

/**
 * Gets the props related to the creation and last update date of a project to be used in tracking events.
 */
export function getTrackProjectDateProps({
  modifiedAt,
  createdAt,
}: TrackProjectDateProps): TrackProjectDateResult {
  const eventProps: TrackProjectDateResult = {};

  if (modifiedAt) {
    eventProps.lastUpdateAge = getAge(modifiedAt);
    eventProps.lastUpdateAgeRange = getDisplayAge(eventProps.lastUpdateAge);
  }

  if (createdAt) {
    eventProps.creationAge = getAge(createdAt);
    eventProps.creationAgeRange = getDisplayAge(eventProps.creationAge);
  }

  return eventProps;
}

/**
 * Tracks whenever a user wants to open a project in the Sphere Viewer or WebEditor.
 * This function does not open the project, it only tracks the event.
 *
 * @param openFrom
 */
export async function trackOpenProject({
  openFrom,
  numberOfMembers,
  openTarget = ProjectLaunchTarget.sphereViewer,
  clickType,
  trackAsyncEvent,
  modifiedAt,
  createdAt,
  accessLevel,
}: TrackProjectProps): Promise<void> {
  const shouldOpenInSV = openTarget === ProjectLaunchTarget.sphereViewer;

  const eventProps: Record<string, string | number> = {
    openFrom: openFrom,
    ...getTrackProjectDateProps({ modifiedAt, createdAt }),
  };
  if (numberOfMembers) {
    eventProps.numberOfMembers = numberOfMembers;
  }

  if (shouldOpenInSV) {
    eventProps.openTarget = ProjectLaunchTarget.sphereViewer;
  } else {
    eventProps.openTarget = ProjectLaunchTarget.webEditor;
  }

  if (clickType) {
    eventProps.clickType = clickType;
  }

  if (accessLevel) {
    eventProps.accessLevel = accessLevel;
  }

  await trackAsyncEvent({
    name: ProjectEvents.openProject,
    props: eventProps,
  });
}

type TrackShareProjectProps = Partial<
  Pick<SdbProject, "accessLevel" | "createdAt" | "modifiedAt">
> &
  Pick<UseTrackEvent, "trackEvent"> & {
    /**
     * Defines where did this event was originated.
     */
    eventLocation: string;
  };

/**
 * Tracks in Amplitude whenever a user clicks the button to share a project
 */
export function trackShareProject({
  accessLevel,
  createdAt,
  modifiedAt,
  eventLocation,
  trackEvent,
}: TrackShareProjectProps): void {
  trackEvent({
    name: ProjectEvents.shareProject,
    props: {
      // Access level seems to be always available in the project object,
      // but the type definition might not be up to date that the compilers does not agree.
      // TODO: https://faro01.atlassian.net/browse/ST-2226 to update the type definition
      accessLevel: accessLevel ?? "unknown",
      eventLocation,
      ...getTrackProjectDateProps({
        createdAt,
        modifiedAt,
      }),
    },
  });
}

/**
 * Gets the URL to open the project for the provided target.
 */
export function getOpenProjectHref({
  openTarget,
  projectId,
}: Pick<OpenProjectProps, "openTarget" | "projectId">): string {
  return openTarget === ProjectLaunchTarget.sphereViewer
    ? getSphereViewerUrl({
        projectId,
      }).href
    : getWebEditorUrl(projectId).href;
}

/**
 * Gets the display name for the provided target.
 */
export function getOpenProjectTargetName(target: ProjectLaunchTarget): string {
  return target === ProjectLaunchTarget.sphereViewer
    ? "Sphere XG"
    : "HoloBuilder";
}

/**
 * Opens a project in one of the viewer apps (Sphere Viewer or WebEditor). Which one is
 * dependant on the openTarget prop.
 */
export async function openProjectInViewer({
  project,
  openFrom,
  numberOfMembers,
  openTarget = ProjectLaunchTarget.sphereViewer,
  lookAtId,
  id,
  clickType,
  trackAsyncEvent,
}: Omit<OpenProjectProps, "projectId"> & {
  project: SdbProject;
}): Promise<void> {
  const shouldOpenInSV = openTarget === ProjectLaunchTarget.sphereViewer;
  const projectId = project.id;

  await trackOpenProject({
    openFrom,
    numberOfMembers,
    openTarget,
    clickType,
    modifiedAt: project.modifiedAt,
    createdAt: project.createdAt,
    trackAsyncEvent,
  });

  const targetUrl = shouldOpenInSV
    ? getSphereViewerUrl({ projectId, lookAtId, id })
    : getWebEditorUrl(projectId);

  window.location.assign(targetUrl.href);
}

/**
 * Converts to the simplified project archiving state from the more elaborated ArchivingState.
 *
 * @param archivingState Original archiving state, e.g. "ARCHIVED_DOWNLOADED"
 * @returns The simplified version of the archiving state, e.g. "active".
 */
export function getProjectArchivingState(
  archivingState: APITypes.ArchivingState
): ProjectArchivingState {
  switch (archivingState) {
    case APITypes.ArchivingState.ARCHIVED:
    case APITypes.ArchivingState.ARCHIVED_DOWNLOADED:
    case APITypes.ArchivingState.ARCHIVED_READY_TO_DOWNLOAD:
      return ProjectArchivingState.archived;
    case APITypes.ArchivingState.UNARCHIVED:
      return ProjectArchivingState.active;
    default:
      return archivingState;
  }
}

/**
 * Convert the readable project state to the API archiving state
 */
export function projectStateToApiArchivingState(
  projectArchivingState: ProjectArchivingState
): APITypes.ArchivingState[] {
  let archivingStates = [APITypes.ArchivingState.UNARCHIVED];
  if (projectArchivingState === ProjectArchivingState.archived) {
    archivingStates = [
      APITypes.ArchivingState.ARCHIVED,
      APITypes.ArchivingState.ARCHIVED_DOWNLOADED,
      APITypes.ArchivingState.ARCHIVED_READY_TO_DOWNLOAD,
    ];
  }

  return archivingStates;
}

// TODO: Needs to be moved to data-display file
/**
 * Gets the display name for the snapshot archiving state,
 * e.g. "Public access" or "Restricted access".
 */
export function getSnapshotAccessDisplayName(
  accessLevel: CoreAPITypes.IProjectSnapshot["accessLevel"]
): string {
  switch (accessLevel) {
    case SphereDashboardAPITypes.EAccessLevel.private:
      return "Private";
    case SphereDashboardAPITypes.EAccessLevel.unlisted:
      return "Via link";
    default:
      return "Unknown";
  }
}

/**
 * Gets the colors for the badge to show snapshot access level.
 */
export function getSnapshotAccessColors(
  accessLevel: CoreAPITypes.IProjectSnapshot["accessLevel"]
): { backgroundColor: string; textColor: string } {
  switch (accessLevel) {
    case SphereDashboardAPITypes.EAccessLevel.private:
      return {
        backgroundColor: sphereColors.blue100,
        textColor: sphereColors.blue500,
      };
    case SphereDashboardAPITypes.EAccessLevel.unlisted:
    default:
      return {
        backgroundColor: sphereColors.gray200,
        textColor: sphereColors.gray800,
      };
  }
}

/**
 * Filters projects based on the search text, but can be extended in the future to support more filters.
 *
 * @param projects The projects to be filtered. The array won't be mutated.
 * @param options Filtering options like the search text.
 * @returns A new array containing the filtered projects.
 */
export function filterProjects(
  projects: SdbProject[],
  options: {
    searchText: string | undefined;
  }
): SdbProject[] {
  const searchText = options.searchText?.toLowerCase();
  if (!searchText) {
    return projects;
  }
  return projects.filter((project) =>
    project.name?.toLowerCase().includes(searchText)
  );
}

/**
 * Sort projects based on the sorting selected by the user
 *
 * @param projects The projects to be sorted. The array will be mutated.
 * @param options Sorting options like the sort by attribute and the sorting order (ascending or descending).
 * @returns Reference to the array containing the sorted projects.
 */
export function sortProjects(
  projects: SdbProject[],
  options: {
    sortBy: ProjectSortByAttrName;
    shouldSortDesc: boolean;
  }
): SdbProject[] {
  const sortBy = options.sortBy;
  return projects.sort((a: SdbProject, b: SdbProject) => {
    const projectA = options?.shouldSortDesc ? b : a;
    const projectB = options?.shouldSortDesc ? a : b;
    switch (sortBy) {
      case "name":
        return projectA[sortBy].localeCompare(projectB[sortBy]);
      case "modifiedAt":
        return projectA[sortBy] > projectB[sortBy] ? 1 : -1;
      default:
        return projectA[sortBy] > projectB[sortBy] ? 1 : -1;
    }
  });
}

/**
 * Filter projects based on pagination.
 *
 * @param projects The projects to be paginated. The array won't be mutated.
 * @param options Pagination options like the page number or the items per page..
 * @returns @returns A new array containing the paginated projects.
 */
export function paginateProjects(
  projects: SdbProject[],
  options: {
    page: number;
    limit: number;
  }
): SdbProject[] {
  const start = options.page * options.limit;
  const end = start + options.limit;
  return projects.slice(start, end);
}

/**
 * Returns the pretty version of the last time a project was modified.
 *
 * @param project The project to get its modifiedAt property.
 * @returns The last modified date. E.g. 2 days ago.
 */
export function getProjectModifiedElapsed(project: SdbProject): string {
  return getPrettyDateElapsed(project.modifiedAt);
}

/**
 * Returns the name of the group the project belongs to.
 */
export function getProjectGroupName(project: SdbProject): string {
  return project.group?.name ?? DASH;
}

/**
 * Returns the id of the group the project belongs to.
 */
export function getProjectGroupId(
  project: SdbProject
): APITypes.GroupId | null {
  return project.group?.id ?? null;
}

/**
 * Returns true if a project is a demo.
 */
export function isProjectDemo(project: SdbProject): boolean {
  return project.tags.includes(ProjectJson.ESystemTag.demo);
}

/**
 * Gets the project manager for the provided project.
 *
 * @param project Project to extract its project manager from.
 * @returns The project manager, null otherwise.
 */
export function getProjectManager(project: SdbProject): MemberTypes | null {
  // TODO: we don't yet support IUserAsManager in MemberTypes: https://faro01.atlassian.net/browse/ST-1721
  return (project.managers.projectManager as MemberTypes) ?? null;
}

/**
 * Gets the name of the project manager for the provided project.
 *
 * @param project Project to extract its project manager from.
 * @returns The project manager, a dash otherwise.
 */
export function getProjectManagerName(project: SdbProject): string {
  return getPrettyName(getProjectManager(project)) || DASH;
}

/** Returns the project's client name */
export function getProjectClientName(project: SdbProject): string {
  return project.clientName ?? DASH;
}

/** Returns the project assigned area */
export function getProjectArea(project: SdbProject): string {
  return project.area
    ? `${project.area.amount} ${formatAreaUnit(project.area.unit)}`
    : DASH;
}
