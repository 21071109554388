import { SdbProject } from "@custom-types/project-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

/** A single description for permissions on the project level. */
interface RequiredPermissionProjectLevel {
  /**
   * Array filled with permissions where the user
   * should have one of them in order to have access.
   * If the array is empty, it means user has no required permission and has default access
   */
  permissions: SphereDashboardAPITypes.EProjectPermission[];
}

/**
 * Object that determines all different permission rules for users to get
 * access on the project level.
 */
export type RequiredPermissionsProjectLevel<PermissionNameT extends string> = {
  [key in PermissionNameT]: RequiredPermissionProjectLevel;
};

/**
 * Signature for the function hasUserValidPermissionProjectLevel.
 */
export interface HasUserValidPermissionProjectLevelProps<
  PermissionNameT extends string
> {
  /**
   * The internal name used in the UI to determine certain permissions,
   * e.g. "canChangeRole" is used to determine whether the user can change the role
   * teams tab in the project overview page.
   */
  permissionName: PermissionNameT;

  /**
   * The project to get the user's permission level.
   */
  selectedProject: SdbProject | null;

  /**
   * You can optionally change the default required permissions definition,
   * ! This is only used for unit tests,
   * therefore only use the default value.
   */
  defaultRequiredPermissions?: RequiredPermissionsProjectLevel<PermissionNameT>;
}

/** Names for all permissions within the project level. */
export enum RequiredPermissionProjectLevelName {
  /** Whether the user can change the role of a team member */
  canChangeRole = "canChangeRole",
}
