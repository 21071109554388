import { projectMembersSelector } from "@store/projects/projects-selector";
import { useAppSelector } from "@store/store-helper";
import { getPrettyName } from "@utils/user-utils";
import { MemberAvatars } from "@components/common/member/member-avatars";

/**
 * Shows the details regarding team members for a project
 */
export function MemberDetails(): JSX.Element {
  const projectMembers = useAppSelector(projectMembersSelector);

  // Show the project members sorted by name
  const sortedProjectMembers = [...projectMembers].sort((a, b) =>
    getPrettyName(a).localeCompare(getPrettyName(b))
  );

  return (
    <MemberAvatars
      label="Team Members"
      members={sortedProjectMembers}
      maxMemberAvatars={4}
    />
  );
}
