import { BaseBackgroundTaskProps } from "@custom-types/sdb-background-tasks-types";
import { BackgroundTaskCategory } from "@custom-types/sdb-background-tasks-types";
import PointCloudIcon from "@assets/icons/new/point-cloud_32px.svg?react";
import VideoModeIcon from "@assets/icons/new/videomode-icon_24px.svg?react";
import CadIcon from "@assets/icons/new/cad_32px.svg?react";
import InfoIcon from "@assets/icons/new/info-circle_24px.svg?react";
import { ICON_SMALL_STYLE } from "@components/header/sphere-avatar";
import { SphereTooltip } from "@components/common/sphere-tooltip";

/**
 * Renders the backgrounds task icon to display in the cloud activity table.
 */
export function BackgroundTaskIcon({
  task,
}: BaseBackgroundTaskProps): JSX.Element {
  let iconComponent: JSX.Element;

  switch (task.taskCategory) {
    case BackgroundTaskCategory.pointCloud:
      iconComponent = <PointCloudIcon style={ICON_SMALL_STYLE} />;
      break;
    case BackgroundTaskCategory.videoMode:
      iconComponent = <VideoModeIcon style={ICON_SMALL_STYLE} />;
      break;
    case BackgroundTaskCategory.cad:
      iconComponent = <CadIcon style={ICON_SMALL_STYLE} />;
      break;
    default:
      iconComponent = <InfoIcon style={ICON_SMALL_STYLE} />;
      break;
  }

  return (
    <SphereTooltip title={<var>{task.taskType}</var>}>
      {iconComponent}
    </SphereTooltip>
  );
}
