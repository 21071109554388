import { FaroTable } from "@components/common/faro-table/faro-table";
import { MemberTypes } from "@custom-types/member-types";
import { PointCloud } from "@custom-types/point-cloud-types";
import { SphereDashboardAPITypes } from "@stellar/api-logic";
import { resetTableState, setTableType } from "@store/table/table-slice";
import { useAppDispatch, useAppSelector } from "@store/store-helper";
import {
  SidePanelEvents,
  TableType,
} from "@utils/track-event/track-event-list";
import { useEffect, useState } from "react";
import {
  PointCloudsTableHeaders,
  getPointCloudsTableColumns,
} from "@pages/project-details/project-data/point-clouds/point-clouds-table-utils";
import { Box } from "@mui/material";
import { PointCloudsBulkActions } from "@pages/project-details/project-data/point-clouds/point-clouds-bulk-actions";
import { BaseProjectIdProps } from "@custom-types/sdb-company-types";
import { sidebarSelector } from "@store/ui/ui-selector";
import { isBetaTestingEnabledSelector } from "@store/app/app-selector";
import { sphereColors } from "@styles/common-colors";
import { useTrackEvent } from "@utils/track-event/use-track-event";
import { updateSidebar } from "@store/ui/ui-slice";
import { PointCloudSidePanel } from "@pages/project-details/project-data/point-clouds/sidepanel/point-cloud-sidepanel";

/** The columns to render in the points cloud table */
const COLUMNS: PointCloudsTableHeaders[] = [
  PointCloudsTableHeaders.name,
  PointCloudsTableHeaders.mergedPointCloudDetails,
  PointCloudsTableHeaders.creationDate,
  PointCloudsTableHeaders.sheet,
  PointCloudsTableHeaders.createdBy,
  PointCloudsTableHeaders.options,
];

interface Props extends BaseProjectIdProps {
  /** Array of PointCloud entities */
  pointClouds: PointCloud[];

  /** Whether the data for showing the point clouds table is loading */
  isLoading: boolean;

  /** List of company members */
  companyMembers: MemberTypes[];

  /** List of project members */
  projectMembers: SphereDashboardAPITypes.IProjectMemberBase[];
}

export function PointCloudsTable({
  projectId,
  pointClouds,
  isLoading,
  companyMembers,
  projectMembers,
}: Props): JSX.Element {
  const dispatch = useAppDispatch();
  const { trackEvent } = useTrackEvent();

  const [isDataSidePanelOpen, setIsDataSidePanelOpen] =
    useState<boolean>(false);
  const [pointCloudSelected, setPointCloudSelected] = useState<PointCloud>();

  const { isFullSize } = useAppSelector(sidebarSelector);
  const isBetaTestingEnabled = useAppSelector(isBetaTestingEnabledSelector);

  // Make sure the correct table type is set in the store and reset the bulk action state after unmounting
  useEffect(() => {
    dispatch(setTableType(TableType.pointClouds));

    return () => {
      dispatch(resetTableState());
    };
  }, [dispatch]);

  const bulkActions = PointCloudsBulkActions({ projectId });

  return (
    <>
      {!isLoading && (
        <Box>
          <Box
            component="span"
            sx={{
              fontWeight: "bold",
            }}
          >
            {"Registration - "}
          </Box>
          Select at least 2 point clouds: the reference point cloud first and
          the adjustable point cloud last.
        </Box>
      )}
      <FaroTable
        entities={pointClouds}
        uniqueIdKey="id"
        isLoading={isLoading}
        columns={COLUMNS.map(
          (column) =>
            getPointCloudsTableColumns({ companyMembers, projectMembers })[
              column
            ]
        )}
        rowHeight={54}
        bulkActionButtons={bulkActions}
        onRowClick={(id) => {
          if (isBetaTestingEnabled) {
            if (isFullSize) {
              dispatch(updateSidebar({ isFullSize: false }));
            }

            trackEvent({
              name: SidePanelEvents.openSidePanelData,
            });

            const pointCloudSelected = pointClouds.find(
              (pointCloud) => pointCloud.id === id
            );

            setPointCloudSelected(pointCloudSelected);
            setIsDataSidePanelOpen(!isDataSidePanelOpen);
          }
        }}
        rowClassName={(rowData) =>
          pointCloudSelected && rowData.id === pointCloudSelected.id
            ? "point-cloud-selected-side-panel"
            : ""
        }
        sx={{
          cursor: isBetaTestingEnabled ? "pointer" : "default",
          "& .point-cloud-selected-side-panel": {
            background: sphereColors.gray200,
          },
          // Change color of name and merged details cells for the hovered row
          // eslint-disable-next-line max-len
          "& .MuiDataGrid-cell:hover .point-cloud-table-name, .MuiDataGrid-cell:hover .point-cloud-table-merged-details":
            {
              color: sphereColors.blue500,
              fill: sphereColors.blue500,
            },
        }}
      />

      <PointCloudSidePanel
        isDataSidePanelOpen={isDataSidePanelOpen}
        pointCloudSelected={pointCloudSelected}
        setIsDataSidePanelOpen={setIsDataSidePanelOpen}
        setPointCloudSelected={setPointCloudSelected}
      />
    </>
  );
}
