import {
  CaptureTreeEntity,
  CaptureTreeEntityRevision,
  CaptureTreeEntityType,
} from "@faro-lotv/service-wires";

/** Base capture tree entity */
export type BaseCaptureTreeEntity =
  | CaptureTreeEntity
  | CaptureTreeEntityRevision;

/** A capture tree entity of type root */
export type RootEntity<T extends BaseCaptureTreeEntity> = T & {
  type: CaptureTreeEntityType.root;
};

/** A capture tree entity of type cluster */
export type ClusterEntity<T extends BaseCaptureTreeEntity> = T & {
  type: CaptureTreeEntityType.cluster;
};

/** Possible types of a capture tree scan entity */
export type ScanEntityType =
  | CaptureTreeEntityType.elsScan
  | CaptureTreeEntityType.focusScan
  | CaptureTreeEntityType.orbisScan
  | CaptureTreeEntityType.pCloudUploadScan;

/** List of possible types of a capture tree scan entity */
export const SCAN_ENTITY_TYPES: { [key in ScanEntityType]: key } = {
  [CaptureTreeEntityType.focusScan]: CaptureTreeEntityType.focusScan,
  [CaptureTreeEntityType.orbisScan]: CaptureTreeEntityType.orbisScan,
  [CaptureTreeEntityType.pCloudUploadScan]: CaptureTreeEntityType.pCloudUploadScan,
  [CaptureTreeEntityType.elsScan]: CaptureTreeEntityType.elsScan,
};

/** A capture tree entity of type scan */
export type ScanEntity<T extends BaseCaptureTreeEntity> = T & {
  type: ScanEntityType;
};
