import { FaroTextButton } from "@components/common/faro-text-button";
import { getInspectAndPublishToolUrl } from "@pages/project-details/project-data-management/data-management-utils";
import { APITypes } from "@stellar/api-logic";
import { colorConst } from "@styles/common-colors";
import { OPEN_PROJECT_TARGET_ATTRIBUTE } from "@utils/project-utils";
import { DataManagementEvents } from "@utils/track-event/track-event-list";
import { useTrackEvent } from "@utils/track-event/use-track-event";

interface Props {
  /** Project ID. */
  projectId: APITypes.ProjectId;
  /** ID of the registration revision. */
  registrationId: string;
  /** Flag to change the tooltip if the project is already published. */
  isPublished: boolean;
  /** True if the button is only shown because of dev mode. */
  isDevMode?: boolean;
}

export function InspectAndPublishButton({ projectId, registrationId, isPublished, isDevMode = false }: Props): JSX.Element {
  const { trackEvent } = useTrackEvent();
  const tooltip = isPublished ? "Inspect the registered and published scans." : "Inspect and publish the registered scans.";

  return (
    <FaroTextButton
      size="small"
      dataTestId="sa-inspect-button"
      sx={{ color: isDevMode ? colorConst.devFeature : undefined }}
      to={getInspectAndPublishToolUrl(projectId, registrationId)}
      target={OPEN_PROJECT_TARGET_ATTRIBUTE}
      tooltipText={tooltip}
      onClick={() => {
        trackEvent({
          name: DataManagementEvents.inspect,
          props: {
            projectId,
            registrationId,
          },
        });
      }}
    >
      Inspect{isDevMode ? " (dev)" : ""}
    </FaroTextButton>
  );
}
