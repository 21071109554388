import { APITypes, SphereDashboardAPITypes } from "@stellar/api-logic";
import { RequiredRoleBase } from "@utils/access-control/access-control-utils";

/** A single description for roles on the company level. */
interface RequiredRoleCompanyLevel extends RequiredRoleBase {
  /**
   * Defines extra rules for users that have the company manager role.
   */
  companyManager?: {
    /**
     * Allow if the user has the special permission to create groups.
     */
    shouldCreateGroups?: true;
  };

  /**
   * Apart from the defined roles, allows if the action to be performed is on the current user.
   * E.g. if the user wants to access their own member profile page.
   */
  shouldAllowCurrentUser?: boolean;

  /** List of subscription roles to check in the company context */
  companySubscriptionRoles?: APITypes.EUserSubscriptionRole[];
}

/**
 * Object that determines all different permission rules for users to get
 * access on the company level.
 */
export type RequiredRolesCompanyLevel<RoleNameT extends string> = {
  [key in RoleNameT]: RequiredRoleCompanyLevel;
};

/**
 * Signature for the function hasUserValidRoleCompanyLevel.
 */
export interface HasUserValidRoleCompanyLevelProps<RoleNameT extends string> {
  /**
   * The internal name used in the UI to determine certain permissions,
   * e.g. "viewAllCompanyUsers" is used to determine whether the user can open the
   * company members page.
   */
  roleName?: RoleNameT;

  /**
   * User to get its company access level.
   */
  currentUser: SphereDashboardAPITypes.ICompanyMemberBase | null;

  /**
   * You can optionally change the default required roles definition,
   * ! This is only used for unit tests,
   * therefore only use the default value.
   */
  defaultRequiredRolesCompanyLevel?: RequiredRolesCompanyLevel<RoleNameT>;

  /**
   *  Member ID for the member profile page that the user wants to access to
   */
  memberId?: APITypes.UserId;

  /** Company context for the selected company */
  companyContext?: SphereDashboardAPITypes.CompanyContext | null;
}

/** Names for all permissions or roles within the company level. */
export enum RequiredRoleCompanyLevelName {
  /** Whether the user has permission to access the members page and view all company users */
  canViewAllCompanyUsers = "canViewAllCompanyUsers",

  /** Whether the user has permission to access the member profile page */
  canViewCompanyMemberProfile = "canViewCompanyMemberProfile",

  /** Whether the user has permission to change the display name */
  canChangeDisplayName = "canChangeDisplayName",

  /** Whether the user has permission to create new projects */
  canCreateProjects = "canCreateProjects",

  /** Whether the user has permission to add members to the company */
  canInviteUsersToCompany = "canInviteUsersToCompany",

  /** Whether the user has permission to edit member */
  canEditMember = "canEditMember",

  /** Whether the user can delete a member from a company or not */
  canDeleteMemberFromCompany = "canDeleteMemberFromCompany",

  /** Whether the use has permission to access the groups page and view all company groups */
  canViewAllCompanyGroups = "canViewAllCompanyGroups",

  /** Whether the user has permission to create groups */
  canCreateGroups = "canCreateGroups",

  /** Whether the user has access to analytics page */
  canViewAnalytics = "canViewAnalytics",

  /** Whether the user has access to workspace settings page */
  canViewWorkspaceSettings = "canViewWorkspaceSettings",

  /** Whether the user has access to analytics page */
  canViewPremiumAnalytics = "canViewPremiumAnalytics",

  /** Whether the user has permission to edit the company default platform to open projects */
  canEditDefaultPlatform = "canEditDefaultPlatform",

  /** Whether the user has permission to create new team */
  canCreateNewTeam = "canCreateNewTeam",

  /** Whether the user has permission to remove members from the team */
  canDeleteMembersFromTeam = "canDeleteMembersFromTeam",

  /** Whether the user has permission to view teams */
  canViewTeams = "canViewTeams",

  /** Whether the user has permission to edit a team */
  canEditTeams = "canEditTeams",

  /** Whether the user has permission to delete a team */
  canDeleteTeams = "canDeleteTeams",

  /** Whether the user has permission to add members to the team */
  canInviteMembersToTeam = "canInviteMembersToTeam",

  /** Whether the user has permission to view the subscription details */
  canViewSubscriptionDetails = "canViewSubscriptionDetails",
}
