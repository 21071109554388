import {
  markupStatusColor,
  MarkupStatusColorReturn,
} from "@pages/project-details/project-markups/status/markup-status-types";
import { generateGUID } from "@faro-lotv/foundation";
import {
  AnnotationStatus,
  GUID,
  IElementDropDownMarkupField,
  IElementMarkup,
  IElementType,
  IElementTypeHint,
} from "@faro-lotv/ielement-types";
import {
  Mutation,
  createMutationAddMarkupField,
  createMutationDeleteElement,
  createMutationDropDownMarkupField,
  isAnnotationStatus,
} from "@faro-lotv/service-wires";
import { FaroStatusText } from "@faro-lotv/flat-ui";

/** Gets the markup status value from the markup status received from backend */
export function getMarkupStatusValue(value?: string | null): AnnotationStatus {
  return isAnnotationStatus(value) ? value : AnnotationStatus.Unclassified;
}

/** Returns corresponding label based on the status */
export function getMarkupStatusLabel(status?: string | null): string {
  return isAnnotationStatus(status)
    ? FaroStatusText[status]
    : FaroStatusText.unclassified;
}

/** Returns corresponding color based on the status */
export function getMarkupStatusColor(
  status?: string | null
): MarkupStatusColorReturn {
  return isAnnotationStatus(status)
    ? markupStatusColor[status]
    : markupStatusColor.unclassified;
}

/**
 * Create mutation for editing the markup status
 * TODO: Remove this function because it is copied from lotv-monorepo as it is not available in the public API.
 * https://faro01.atlassian.net/browse/ST-1787
 *
 * @param markupElement to edit
 * @param newStatus to apply
 * @param previousValueId if a value was already present
 * @param statusTemplateId to reference in the new UserDirectoryMarkupField node
 * @returns the proper mutation to update the Markup to the new value
 */
export function createEditStatusMutation(
  markupElement: IElementMarkup,
  newStatus: AnnotationStatus | undefined,
  previousValueId: GUID | undefined,
  statusTemplateId: GUID
): Mutation[] {
  if (newStatus) {
    // Edit previous value if it exists
    if (previousValueId) {
      return [createMutationDropDownMarkupField(previousValueId, newStatus)];
    }

    // Create a new node if it did not exists
    return [
      createMutationAddMarkupField<IElementDropDownMarkupField>(
        markupElement.id,
        {
          id: generateGUID(),
          childrenIds: null,
          name: "Status",
          parentId: markupElement.id,
          rootId: markupElement.rootId,
          templateId: statusTemplateId,
          type: IElementType.dropDownMarkupField,
          typeHint: IElementTypeHint.markupIssueStatus,
          value: newStatus,
        }
      ),
    ];
  } else if (previousValueId) {
    // Remove the value node if not needed anymore
    return [createMutationDeleteElement(previousValueId)];
  }
  return [];
}
