import { useAppParams } from "@router/router-helper";
import { NotFoundPage } from "@pages/not-found-page";
import { GeneralSettings } from "@pages/settings/general-settings/general-settings";
import { TabsWithRoutes } from "@components/common/tabs-with-routes";
import { CircularProgress } from "@faro-lotv/flat-ui";
import { useAppSelector } from "@store/store-helper";
import { SettingsTabs } from "@router/route-params";
import { AccessControlSettings } from "@pages/settings/access-control-settings/access-control-settings";
import { isBetaTestingEnabledSelector } from "@store/app/app-selector";
import {
  isFetchingSdbCompanySelector,
  selectedSdbCompanySelector,
  subscriptionSelector,
} from "@store/sdb-company/sdb-company-selector";
import { SPHERE_LEGACY_MIGRATED_TAG } from "@utils/sdb-company-utils";
import { OpenTabEvents } from "@utils/track-event/track-event-list";
import { SubscriptionSettings } from "@pages/settings/subscription-settings/subscription-settings";
import { useHasUserValidRoleCompanyLevel } from "@hooks/access-control/use-has-user-valid-role-company-level";

/** Content in the Workspace Settings page */
export function WorkspaceSettingsPage(): JSX.Element {
  const { settingsTab, companyId } = useAppParams();
  const isBetaTestingEnabled = useAppSelector(isBetaTestingEnabledSelector);
  const selectedSdbCompany = useAppSelector(selectedSdbCompanySelector);
  const { canViewSubscriptionDetails } = useHasUserValidRoleCompanyLevel();
  const subscription = useAppSelector(subscriptionSelector);
  const isLoadingSubscription = useAppSelector(isFetchingSdbCompanySelector);

  const shouldShowAccessControlTab =
    isBetaTestingEnabled &&
    !selectedSdbCompany?.tags.includes(SPHERE_LEGACY_MIGRATED_TAG);

  const shouldShowSubscriptionTab = canViewSubscriptionDetails && subscription;

  if (
    !companyId ||
    !settingsTab ||
    !Object.values(SettingsTabs).includes(settingsTab) ||
    (settingsTab === SettingsTabs.accessControl &&
      !shouldShowAccessControlTab) ||
    (settingsTab === SettingsTabs.subscription &&
      !isLoadingSubscription &&
      !shouldShowSubscriptionTab)
  ) {
    return <NotFoundPage />;
  }

  const tabs = [
    {
      label: "General",
      route: SettingsTabs.general,
      content: () => <GeneralSettings />,
      loadingContent: <CircularProgress />,
    },
  ];

  if (shouldShowAccessControlTab) {
    tabs.push({
      label: "Access Control",
      route: SettingsTabs.accessControl,
      content: () => <AccessControlSettings />,
      loadingContent: <CircularProgress />,
    });
  }

  if (shouldShowSubscriptionTab) {
    tabs.push({
      label: "Subscription",
      route: SettingsTabs.subscription,
      content: () => <SubscriptionSettings subscription={subscription} />,
      loadingContent: <CircularProgress />,
    });
  }

  return (
    <TabsWithRoutes
      dataTestId="WORKSPACE_SETTINGS_CONTAINER"
      selectedTab={settingsTab}
      requiredAttribute={false}
      trackingEventPageName={OpenTabEvents.openWorkspaceSettingsTab}
      tabs={tabs}
    />
  );
}
