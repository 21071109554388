import { useRouteMatch } from "@hooks/route-match/use-route-match";
import { TEAM_DISPLAY_NAME } from "@src/constants/team-constants";

/**
 * A hook that returns the search placeholder based on the route
 */
export function useSearchPlaceholder(): string {
  const routes = useRouteMatch();

  switch (true) {
    case routes.isProjectsRoute:
      return "Search for project";
    case routes.isMembersRoute:
      return "Search for member";
    case routes.isGroupsRoute:
      return "Search for group";
    case routes.isTeamsRoute:
      return `Search for ${TEAM_DISPLAY_NAME}`;

    default:
      return "Search";
  }
}
