import { FaroMenuItem } from "@components/common/faro-select/faro-menu-item";
import { PROJECT_ACCESS_LEVELS } from "@components/project/project-access-level-utils";
import { addTransparency } from "@utils/ui-utils";
import { colorConst, EDecimalToHex, sphereColors } from "@styles/common-colors";
import { Box } from "@mui/material";
import { DEFAULT_INPUT_FONT_SIZE } from "@styles/common-styles";
import GenericCheck from "@assets/icons/generic-check_l.svg?react";
import { SphereDashboardAPITypes } from "@stellar/api-logic";

interface Props {
  /** Current project access level */
  projectAccessLevel?: string;

  /** Callback to change the access level. It is optional as the logic can be in the parent component */
  onAccessLevelChange: (
    event: SphereDashboardAPITypes.EAccessLevel
  ) => Promise<void> | void;
}

/** Access level dropdown */
export function ProjectAccessLevelDropdown({
  projectAccessLevel,
  onAccessLevelChange,
}: Props): JSX.Element {
  return (
    <>
      {PROJECT_ACCESS_LEVELS.map((accessLevel) => (
        <FaroMenuItem
          data-my-value={accessLevel.value}
          key={accessLevel.value}
          value={accessLevel.value}
          onClick={(event) =>
            onAccessLevelChange(
              event.currentTarget.dataset
                .myValue as SphereDashboardAPITypes.EAccessLevel
            )
          }
          sx={{
            display: "block",
            padding: "12px",
            mb: "2px",
            whiteSpace: "normal",
            "&:hover": {
              backgroundColor: addTransparency({
                color: sphereColors.gray500,
                alpha: EDecimalToHex.twentySix,
              }),

              // Set menu item label color to blue500 on hover
              "& .projectAccessLevelItem-label": {
                color: sphereColors.blue500,
              },
            },
            "&.MuiButtonBase-root": {
              backgroundColor:
                accessLevel.value === projectAccessLevel
                  ? addTransparency({
                      color: sphereColors.gray500,
                      alpha: EDecimalToHex.thirtyEight,
                    })
                  : "transparent",

              "&:hover": {
                backgroundColor: addTransparency({
                  color: sphereColors.gray500,
                  alpha: EDecimalToHex.thirtyEight,
                }),
              },

              // Overrides default style for ".Mui-focusVisible" class
              "&.Mui-focusVisible": {
                backgroundColor: addTransparency({
                  color: sphereColors.gray500,
                  alpha: EDecimalToHex.thirtyEight,
                }),
              },
            },
          }}
        >
          {/* Title and icon */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: "9px",
            }}
          >
            <Box
              className="projectAccessLevelItem-label"
              sx={{
                color:
                  accessLevel.value === projectAccessLevel
                    ? sphereColors.blue500
                    : colorConst.normalFont,
                fontSize: DEFAULT_INPUT_FONT_SIZE,
                fontWeight: "600",
              }}
            >
              {accessLevel.label}
            </Box>

            {accessLevel.value === projectAccessLevel && (
              <GenericCheck
                width="21px"
                height="21px"
                fill={sphereColors.blue500}
              />
            )}
          </Box>

          {/* Description */}
          <Box
            sx={{
              color: sphereColors.gray600,
              fontSize: "12px",
            }}
          >
            {accessLevel.description}
          </Box>
        </FaroMenuItem>
      ))}
    </>
  );
}
